// @ts-nocheck
import { Box } from '@mui/material';
import { imageExistsInBucket } from '../../../../../utils/resourcePathHelper';
import * as PropTypes from 'prop-types';
import './ServiceCardImages.css';

const fallbackImage = '/img/TH-placeholder.png';

/**
 * A component that renders a list of images in a service card.
 *
 * @param {{ images: string[] }} props - The props object.
 * @param {string[]} props.images - A list of image URLs.
 *
 * @returns {React.ReactElement} The component.
 */
const ServiceCardImages = ({ images = [] }) => {
  if (!images?.length) return null;

  return (
    <Box className='service-view-card__image-container '>
      {images.map((image, index) => (
        <Box
          className='service-view-card__image-element'
          key={`service-card-image-${index}`}
        >
          <img
            src={image}
            alt={`service-card-image-${index}`}
            className='service-view-card__image'
            onError={async (evt) => {
              evt.target.src = fallbackImage;
              const check = await imageExistsInBucket(image, 3, 2000);

              if (check) {
                evt.target.src = image;
              }
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

ServiceCardImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};

export default ServiceCardImages;
