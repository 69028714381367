import request from '../request';

export const getServiceTypes = () =>
  request.get('servicetypes').then((res) => res);

/**
 * Save a service to a detailed itinerary day.
 * @param {number} tripid - The trip ID.
 * @param {number} tripdetaileddayid - The detailed day ID.
 * @param {Object} payload - The service data to save.
 * @returns {Promise<Object>} - The API response.
 */
export const createServiceType = async (tripid, tripdetaileddayid, payload) => {
  const url = `/trips/${tripid}/tripdetailedday/${tripdetaileddayid}/services`;
  return await request.post(url, payload);
};

/**
 * Delete a service from a detailed itinerary day.
 * @param {number} tripid - The trip ID.
 * @param {number} tripdetaileddayid - The detailed day ID.
 * @param {number} tripdetaileddayserviceid - The detailed service day ID.
 * @returns {Promise<Object>} - The API response.
 */
export const deleteServiceType = async (
  tripid,
  tripdetaileddayid,
  tripdetaileddayserviceid
) => {
  const url = `/trips/${tripid}/tripdetailedday/${tripdetaileddayid}/services/${tripdetaileddayserviceid}`;
  return await request.delete(url);
};

/**
 * Update a service to a detailed itinerary day.
 * @param {number} tripid - The trip ID.
 * @param {number} tripdetaileddayid - The detailed day ID.
 * @param {number} tripdetaileddayserviceid - The detailed service day ID.
 * @param {Object} payload - The service data to save.
 * @returns {Promise<Object>} - The API response.
 */
export const updateServiceType = async (
  tripid,
  tripdetaileddayid,
  tripdetaileddayserviceid,
  payload
) => {
  const url = `/trips/${tripid}/tripdetailedday/${tripdetaileddayid}/services/${tripdetaileddayserviceid}`;
  return await request.put(url, payload);
};
