// @ts-nocheck
import Editor from '../../../../Editor/Editor';
import ServiceCardImages from '../ServiceCardImages/ServiceCardImages';
import ServiceCardAttachments from '../ServiceCardAttachments/ServiceCardAttachments';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../../../../utils/dateUtility';
import * as PropTypes from 'prop-types';

/**
 * A service card component for flights.
 *
 * @param {Object} servicefields - the fields of the service
 * @param {string} tripdetaileddayserviceid - the id of the tripdetaileddayservice
 * @returns {JSX.Element}
 */
const FlightCard = ({ servicefields, tripdetaileddayserviceid }) => {
  const { t } = useTranslation(['services']);

  return (
    <Box id={`service-card-flights-${tripdetaileddayserviceid}`}>
      <Box className='service-view-card__content'>
        <Box className='service-view-card__content-row'>
          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('airline')}:</span> {servicefields.AIRLINE || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('flightNumber')}: </span>
              {servicefields.FLIGHTNUMBER || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('departingFrom')}:</span>{' '}
              {servicefields.DEPARTUREAIRPORT || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('arrivingTo')}:</span>{' '}
              {servicefields.ARRIVALAIRPORT || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <Box>
              <p className='service-view-card__label-value'>
                <span>{t('departureTime')}:</span>{' '}
                {formatTime(servicefields.DEPARTURETIME)}
              </p>
              <p className='service-view-card__label-value'>
                <span>{t('arrivalTime')}:</span>{' '}
                {formatTime(servicefields.ARRIVALTIME)}
              </p>
            </Box>
          </Box>
        </Box>

        <Box>
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-editor-flights-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-view-card__description'
            />
          )}

          <Box className='service-view-card__confirmation'>
            <p>
              <span>{t('confirmation')}:</span>{' '}
              {servicefields.CONFIRMATIONINFORMATION || ''}
            </p>
          </Box>

          {(servicefields.UPLOADIMAGES || servicefields.UPLOADATTACHMENTS) && (
            <Box className='service-view-card__files-section'>
              {servicefields?.UPLOADIMAGES && (
                <ServiceCardImages images={servicefields?.UPLOADIMAGES} />
              )}
              {servicefields?.UPLOADATTACHMENTS && (
                <ServiceCardAttachments
                  attachments={servicefields?.UPLOADATTACHMENTS}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

FlightCard.propTypes = {
  servicefields: PropTypes.object,
  servicetype: PropTypes.object,
  tripdetaileddayserviceid: PropTypes.number
};

export default FlightCard;
