// @ts-nocheck
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const IconSection = ({ icon, height }) => {
  if (!icon) return null;

  const timelineHeight = height * 0.5;

  return (
    <Box className='service-section__icon-container' height={timelineHeight}>
      <Box>{icon}</Box>

      <Box className='service-section__timeline'>
        <div className='service-section__timeline-dot service-section__timeline-dot__top'></div>
        <div className='service-section__timeline-dot service-section__timeline-dot__bottom'></div>
      </Box>
    </Box>
  );
};

IconSection.propTypes = {
  icon: PropTypes.element.isRequired,
  height: PropTypes.number
};
export default IconSection;
