// @ts-nocheck
import * as PhosphorIcons from '@phosphor-icons/react';
import PropTypes from 'prop-types';
import './ServiceSection.css';
import { TripDetailedDayPropTypes, TenantSettingsPropTypes } from '../types';
import Services from './Services';
import useImageRender from '../../../../../hooks/useImageRender';

const ServiceComponent = {
  Accommodations: Services.HotelService,
  Activity: Services.ActivityService,
  Car: Services.CarService,
  Flights: Services.FlightService,
  Restaurant: Services.RestaurantService,
  Train: Services.TrainService,
  Transfers: Services.TransferService
};

const ServiceSection = ({
  servicetype,
  tripdetaileddayserviceid,
  servicefields,
  tenantsettings,
  tripid,
  tenantid
}) => {
  const { getImageUrl } = useImageRender({
    pathReference: 'detailedDayService',
    tripId: tripid,
    tenantId: tenantid
  });
  const iconref = servicetype?.iconref;

  const iconExists = (iconName) => {
    return typeof PhosphorIcons[iconName] === 'object';
  };
  const fields = servicefields.reduce((acc, field) => {
    let values = field.options.map((opt) => opt.value);

    // If only one option, store it as a single value instead of an array
    values = values.length > 1 ? values : values[0] || '';

    // Ensure 'MEALSINCLUDED' is always an array
    if (field.key === 'MEALSINCLUDED' && !Array.isArray(values)) {
      values = [values];
    }

    // Transform 'UPLOADIMAGES' field values to image URLs
    if (field.key === 'UPLOADIMAGES') {
      values = Array.isArray(values)
        ? values.map(getImageUrl).filter(Boolean)
        : values
          ? [getImageUrl(values)]
          : [];
    }

    acc[field.key] = values;
    return acc;
  }, {});

  const ServiceTypeIcon = iconExists(iconref) ? PhosphorIcons[iconref] : null;
  const icon = ServiceTypeIcon && (
    <div className={`service-section__icon service-section__icon-${iconref}`}>
      <ServiceTypeIcon size={32.3} />
    </div>
  );

  const Component = ServiceComponent[servicetype?.name];

  if (!Component) {
    console.error(
      `Service component not yet implemented for type: ${servicetype?.name}`
    );
    return null;
  }

  return (
    <Component
      key={tripdetaileddayserviceid}
      servicefields={fields}
      servicetype={servicetype}
      tripdetaileddayserviceid={tripdetaileddayserviceid}
      tenantsettings={tenantsettings}
      icon={icon}
    />
  );
};

ServiceSection.propTypes = {
  ...TripDetailedDayPropTypes,
  tenantsettings: TenantSettingsPropTypes,
  tripid: PropTypes.number,
  tenantid: PropTypes.number
};

export default ServiceSection;
