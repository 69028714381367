// @ts-nocheck
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import './Header.css';
import Branding from './Branding/Branding';
import { getLastListItem } from '../../../../../utils/common';
import { getOldCoverImageUrl } from '../../../../../utils/getCoverImageUrl';

const Header = ({ trip, id, tenantSettings, tenant }) => {
  if (!trip || !tenantSettings) return null;

  const coverImage = getLastListItem(trip.tripimage);
  const { bodyfont, headingfont } = tenantSettings;

  return (
    <Box className='header' sx={{ fontFamily: bodyfont }} id={id}>
      <Branding
        id={'header-branding'}
        tenantSettings={tenantSettings}
        trip={trip}
        tenant={tenant}
      />

      <Box className='header__text'>
        <Typography
          variant='h3'
          fontWeight='bold'
          className='header__title'
          fontFamily={headingfont}
          fontSize={'1.875rem'}
        >
          {trip.tripname}
        </Typography>
      </Box>

      <Typography
        variant='body1'
        className='header__description'
        fontFamily={bodyfont}
        fontSize={'0.625rem'}
      >
        {trip.description}
      </Typography>

      {coverImage && (
        <img
          src={getOldCoverImageUrl(coverImage.imageurl)}
          alt={trip.tripname}
          className='header__image'
        />
      )}
    </Box>
  );
};

Header.propTypes = {
  id: PropTypes.string.isRequired,
  trip: PropTypes.shape({
    startdate: PropTypes.string,
    duration: PropTypes.number,
    tripname: PropTypes.string,
    tripimage: PropTypes.arrayOf(
      PropTypes.shape({
        tripimageid: PropTypes.number,
        seq: PropTypes.number,
        imageurl: PropTypes.string
      })
    ),
    description: PropTypes.string,
    createdbyuser: PropTypes.shape({
      login: PropTypes.string
    })
  }).isRequired,
  tenant: PropTypes.shape({
    tenantlogo: PropTypes.string,
    tenantphone: PropTypes.string,
    tenantname: PropTypes.string,
    tenanturl: PropTypes.string
  }),
  tenantSettings: PropTypes.shape({
    bodyfont: PropTypes.string,
    headingfont: PropTypes.string
  })
};

export default Header;
