//@ts-nocheck
import { useEffect, useState, useCallback } from 'react';
import { imageSize } from '../constants/files';
import {
  getBucketImageUrl,
  getBestSizePrefix
} from '../utils/resourcePathHelper';

/**
 * A hook that returns an object with two properties: sizePrefix and getImageUrl.
 * sizePrefix is the current best image size prefix based on the window width.
 * getImageUrl is a memoized function that takes a fileName and returns the
 * corresponding image url from the bucket.
 *
 * The hook also sets up a window resize event to update sizePrefix whenever
 * the window is resized.
 *
 * @param {Object} props
 * @param {string} props.pathReference - The path reference for the image (e.g. "trip").
 * @param {number} [props.tripId] - The trip ID (if applicable).
 * @param {number} [props.tenantId] - The tenant ID (if applicable).
 *
 * @returns {Object}
 * @returns {string} sizePrefix - The current best image size prefix.
 * @returns {Function} getImageUrl - A memoized function to get an image url.
 */
const useImageRender = ({ pathReference, tripId, tenantId }) => {
  const [sizePrefix, setSizePrefix] = useState(imageSize.medium.prefix);

  const getImageUrl = useCallback(
    (fileName = '') =>
      getBucketImageUrl({
        fileName,
        pathReference,
        tenantId,
        tripId,
        sizePrefix
      }),
    [pathReference, tripId, tenantId, sizePrefix]
  );

  useEffect(() => {
    const handleResize = () => {
      const newSizePrefix =
        getBestSizePrefix(window.innerWidth) || imageSize.medium.prefix;
      setSizePrefix(newSizePrefix);
    };
    handleResize(); // Set initial size

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { sizePrefix, getImageUrl };
};

export default useImageRender;
