// @ts-nocheck
import { Box } from '@mui/material';
import { Notebook, MapPin, CheckCircle } from '@phosphor-icons/react';
import { ServicePropTypes } from '../../types';
import { useTranslation } from 'react-i18next';
import Editor from '../../../../../Editor/Editor';
import {
  formatTime,
  formatDurationFromDate
} from '../../../../../../utils/dateUtility';
import ImageSection from '../Components/ImageSection';
import AttachmentNotice from '../Components/AttachmentNotice';
import IconSection from '../Components/IconSection';
import useComponentHeight from '../../../../../../hooks/useComponentHeight';
import { ensureHttps } from '../../../../../../utils/urlHelper';

const ActivityService = ({
  servicetype,
  servicefields,
  tripdetaileddayserviceid,
  tenantsettings,
  icon
}) => {
  const { ref, height } = useComponentHeight();
  const { t } = useTranslation();
  const columnFields = [
    'PROVIDERNAME',
    'STARTTIME',
    'DURATION',
    'LINK',
    'ADDRESS',
    'PHONE'
  ];
  const hasColumnFieldData = columnFields.some(
    (field) => !!servicefields[field]
  );

  return (
    <Box
      className='service-section'
      id={`service-section-activity-${tripdetaileddayserviceid}`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
      ref={ref}
    >
      <Box className='service-section__icon-name'>
        <IconSection icon={icon} height={height} />
        <h3
          className='service-section__name'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {servicetype?.name || t('Activity')}
        </h3>
      </Box>

      <Box className='service-section__content'>
        {servicefields.TITLE && (
          <h5
            className='service-section__title'
            style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
          >
            {servicefields?.TITLE}
          </h5>
        )}

        <ImageSection images={servicefields?.UPLOADIMAGES} />

        <Box className='service-section___column-info'>
          {(servicefields?.PROVIDERNAME || servicefields?.PHONENUMBER) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Notebook />
              </Box>
              <Box>
                {servicefields?.PROVIDERNAME && (
                  <p className='service-section__label-value'>
                    <span>{t('Provider')}:</span> {servicefields.PROVIDERNAME}
                  </p>
                )}
                {servicefields?.PHONENUMBER && (
                  <p className='service-section__label-value'>
                    <span>{t('Contact Tel')}:</span> {servicefields.PHONENUMBER}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields?.ADDRESS || servicefields?.LINK) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                {servicefields?.ADDRESS && (
                  <p className='service-section__label-value'>
                    <span>{t('Address')}:</span> {servicefields.ADDRESS}
                  </p>
                )}
                {servicefields.LINK && (
                  <p className='service-section__label-value'>
                    <a
                      href={ensureHttps(servicefields.LINK)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {t('Website')}
                    </a>
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields?.STARTTIME || servicefields?.DURATION) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                {servicefields?.STARTTIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Time')}:</span>{' '}
                    {formatTime(servicefields.STARTTIME)}
                  </p>
                )}
                {servicefields?.DURATION && (
                  <p className='service-section__label-value'>
                    <span>{t('Duration')}:</span>{' '}
                    {formatDurationFromDate(servicefields.DURATION)}
                  </p>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          className={`service-section__description-notes ${hasColumnFieldData ? 'spacing' : ''}`}
        >
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-section-restaurant-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-section__description'
              style={{
                fontFamily: tenantsettings?.bodyfont || 'inherit'
              }}
            />
          )}

          {servicefields?.CONFIRMATIONINFORMATION && (
            <Box className='service-section__confirmation'>
              <p>
                <span>{t('Confirmation #')}:</span>{' '}
                {servicefields.CONFIRMATIONINFORMATION}
              </p>
            </Box>
          )}

          <AttachmentNotice attachments={servicefields?.UPLOADATTACHMENTS} />

          {servicefields?.MEALSINCLUDED && (
            <Box className='service-section__include-values'>
              {servicefields?.MEALSINCLUDED?.map((meal, index) => (
                <Box
                  className='service-section__include-values__item'
                  key={`meals-included-${meal.toLowerCase()}-${index}`}
                >
                  <CheckCircle color='rgba(56, 211, 133, 1)' />
                  <p>{meal}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ActivityService.propTypes = ServicePropTypes;
export default ActivityService;
