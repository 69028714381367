import PropTypes from 'prop-types';

export const OptionType = PropTypes.shape({
  tripdetaileddayservicevalueid: PropTypes.number,
  servicefieldoptionid: PropTypes.number,
  value: PropTypes.string
});

export const ServiceFieldType = PropTypes.shape({
  servicefieldid: PropTypes.number,
  isvisible: PropTypes.bool,
  options: PropTypes.arrayOf(OptionType)
});

export const ServiceType = PropTypes.shape({
  servicetypeid: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  code: PropTypes.string,
  sequence: PropTypes.number,
  iconref: PropTypes.string
});

export const TripDetailedDayServiceType = PropTypes.shape({
  tripdetaileddayserviceid: PropTypes.number,
  servicetype: ServiceType,
  servicefields: PropTypes.arrayOf(ServiceFieldType)
});

export const TripDetailedDayPropTypes = PropTypes.shape({
  tripdetaileddayid: PropTypes.number,
  tripdayid: PropTypes.number,
  daynumber: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  note: PropTypes.string,
  tripdetaileddayimage: PropTypes.arrayOf(PropTypes.string),
  tripdetaileddayservice: PropTypes.arrayOf(TripDetailedDayServiceType)
});

export const TenantSettingsPropTypes = PropTypes.shape({
  bodyfont: PropTypes.string,
  color: PropTypes.string,
  headingfont: PropTypes.string,
  name: PropTypes.string
});

export const ServicePropTypes = PropTypes.shape({
  ...TripDetailedDayServiceType,
  ...TenantSettingsPropTypes,
  icon: PropTypes.element
});
