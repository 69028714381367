// @ts-nocheck
import PassengersRadioForm from '../../../Booking/BookingPassengers/PassengersRadioForm/PassengersRadioForm';
import PassengersCheckBoxForm from '../../../Booking/BookingPassengers/PassengersCheckBoxForm/PassengersCheckBoxForm';
import { isEmailValid } from '../../../../utils/emailValidator';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';
import { Box, Modal, InputLabel, FormHelperText } from '@mui/material';
import { XGButton, XGInput, XGDatePicker, XGTextarea } from '@xg-cl/xg-cl';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePassengerById } from '../../../../redux/slices/passengers/passengers';
import { updateBookingPassenger } from '../../../../services/booking/booking';
import { ErrorToast } from '../../../../utils/alerts';
import {
  DATE,
  TEXTAREA,
  CHECKBOX,
  RADIOBUTTON,
  BOOLEAN,
  PHONE,
  EMAIL,
  NUMBER
} from '../../../../constants/passengers';
import * as PropTypes from 'prop-types';
import './PassengersUpdateModal.css';
import { toUTCDate } from '../../../../utils/dateUtility';

const PassengersUpdateModal = ({
  open,
  setOpen,
  bookingPassenger,
  rowPassengerFields,
  updatePassengerField,
  tripId
}) => {
  const { t } = useTranslation(['passengers', 'common', 'trip']);
  const [passengerFields, setPassengersFields] = useState(rowPassengerFields);
  const [inputChanged, setInputChanged] = useState(false);
  const dispatch = useDispatch();

  const handleChange = async (
    newValue,
    trippassengerfieldid,
    sendToServer = false
  ) => {
    const newPasangerFields = [...passengerFields].map((field) => {
      if (field.trippassengerfieldid === trippassengerfieldid) {
        return {
          ...field,
          value: newValue,
          error: null
        };
      }
      return field;
    });

    setPassengersFields(newPasangerFields);

    if (sendToServer) {
      await saveFields(newValue, trippassengerfieldid);
    } else {
      setInputChanged(true);
    }
  };

  const isValidField = (field) => {
    const { value, isrequired, fieldtype, title, trippassengerfieldid } = field;
    let error = '';

    // Check for required fields
    if (isrequired && (!value || value?.trim() === '')) {
      error = `${t('common:pleaseEnter')} ${title?.toLowerCase()}.`;
    }

    // Check for valid phone number
    if (
      (isrequired || value?.trim().split(' ')?.length > 1) &&
      fieldtype?.key === PHONE
    ) {
      const valid = matchIsValidTel(value?.trim());

      if (!valid) {
        error = t('trip:pleaseEnterValidPhoneNumber');
      }
    }

    // Check for valid email
    if (fieldtype?.key === EMAIL && value?.trim() !== '') {
      if (!isEmailValid(value)) {
        error = t('trip:pleaseEnterValidEmail');
      }
    }

    // Check for valid number
    if (fieldtype?.key === NUMBER && value?.trim() !== '') {
      if (isNaN(value?.trim())) {
        error = t('trip:pleaseEnterValidNumber');
      }
    }

    // Check for valid date
    if (fieldtype?.key === DATE && (isrequired || value?.trim() !== '')) {
      const parsedDate = Date.parse(value?.trim());

      if (isNaN(parsedDate)) {
        error = t('trip:pleaseEnterValidDate');
      }
    }

    if (error !== '') {
      const newPasangerFields = [...passengerFields].map((field) => {
        if (field.trippassengerfieldid === trippassengerfieldid) {
          return {
            ...field,
            error
          };
        }
        return field;
      });

      setPassengersFields(newPasangerFields);
    }

    return error === '';
  };

  const saveFields = async (newValue, trippassengerfieldid) => {
    try {
      const { bookingid, bookingpassengerid } = bookingPassenger;
      const foundField = passengerFields.find(
        (field) => field.trippassengerfieldid === trippassengerfieldid
      );

      if (foundField) {
        const isValidFieldData = isValidField(foundField);

        if (isValidFieldData) {
          const { fieldtype, trippassengerfieldoption } = foundField;
          const payload = {
            trippassengerfieldid,
            options: []
          };

          if (fieldtype && fieldtype?.allowoptions) {
            const splitedValue = newValue?.split(',');

            const foundOptions = trippassengerfieldoption?.filter((option) =>
              splitedValue?.includes(option.title)
            );

            foundOptions?.forEach((option) => {
              payload.options.push({
                trippassengerfieldoptionid: option?.trippassengerfieldoptionid,
                value: option?.title
              });
            });
          } else {
            payload.options.push({
              trippassengerfieldoptionid: null,
              value: newValue
            });
          }

          const { data, error } = await updateBookingPassenger(
            tripId,
            bookingid,
            bookingpassengerid,
            payload
          );

          if (error || !data) {
            ErrorToast(t('errors:passengerCouldNotBeUpdated'));
          } else {
            const { id, key: column } = foundField;
            const newRecordValue = { id, column, value: newValue };

            dispatch(updatePassengerById({ ...newRecordValue }));
            updatePassengerField({ ...data, bookingid, bookingpassengerid });

            setInputChanged(false);
          }
        }
      }
    } catch (err) {
      ErrorToast(t('errors:passengerCouldNotBeUpdated'));
    }
  };

  const handleBlur = async (newValue, trippassengerfieldid) => {
    if (inputChanged) {
      await saveFields(newValue, trippassengerfieldid);
    }
  };

  const handleClose = async () => {
    setOpen(false);
  };

  useEffect(() => {
    setPassengersFields(rowPassengerFields);
  }, [rowPassengerFields]);

  return (
    <Modal open={open} onClose={setOpen}>
      <Box className='passenger-modal-box'>
        <div className='passenger-modal-box__header'>
          <h3>{t('passengers:editPassenger')}</h3>
        </div>
        <div className='passenger-fields-container'>
          <div key={'bookingid'} className='w-100 passenger-field'>
            <XGInput
              id={`passenger-bookingid-input`}
              className='passenger-update-modal-input'
              name={'bookingid'}
              type='text'
              value={bookingPassenger?.bookingid ?? ''}
              disabled={true}
              label={t('passengers:bookingId')}
            />
          </div>
          {passengerFields && passengerFields.length ? (
            <>
              {passengerFields.map((field, index) => {
                const { fieldtype, trippassengerfieldid } = field;

                return fieldtype?.key === RADIOBUTTON ||
                  fieldtype?.key === BOOLEAN ? (
                  <Box className='w-100 passenger-field' key={index}>
                    <PassengersRadioForm
                      formId={'1'}
                      field={field}
                      handleChange={(newValue) => {
                        handleChange(newValue, trippassengerfieldid, true);
                      }}
                    />
                  </Box>
                ) : fieldtype?.key === PHONE ? (
                  <Box className='w-100 passenger-field' key={index}>
                    <InputLabel
                      className='xg-input-label phone-input-label'
                      htmlFor={`passenger-${field?.key}-input-${index}`}
                    >
                      {`${field?.title}${field?.isrequired ? '*' : ''}`}
                    </InputLabel>
                    <MuiTelInput
                      className={`phone-input ${field?.error ? ' passenger-form__phone-input-error ' : ''}`}
                      value={field?.value}
                      name={field?.key}
                      id={`passenger-${field?.key}-input-${index}`}
                      onChange={(newValue) => {
                        handleChange(newValue, trippassengerfieldid);
                      }}
                      onBlur={(evt) => {
                        handleBlur(evt.target.value, trippassengerfieldid);
                      }}
                      defaultCountry='US'
                      inputProps={{
                        autoComplete: 'off',
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }}
                    />
                    {field?.error && (
                      <FormHelperText className='input-form-helper-text--error'>
                        {field?.error}
                      </FormHelperText>
                    )}
                  </Box>
                ) : fieldtype?.key === CHECKBOX ? (
                  <Box className='w-100 passenger-field' key={index}>
                    <PassengersCheckBoxForm
                      id={`passenger-${field?.key}-input-${index}`}
                      field={field}
                      selectedOptions={
                        field?.value ? field?.value?.split(',') : []
                      }
                      formId={'1'}
                      handleOnChange={(...args) => {
                        handleChange(args[2], trippassengerfieldid, true);
                      }}
                    />
                  </Box>
                ) : fieldtype?.key === TEXTAREA ? (
                  <Box className='w-100 passenger-field' key={index}>
                    <XGTextarea
                      placeholder={`${t('common:enter')} ${field?.title?.toLowerCase()}`}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}`}
                      id={`passenger-${field?.key}-input-${index}`}
                      type='text'
                      name={field?.key}
                      value={field?.value}
                      onChange={(evt) => {
                        handleChange(evt.target?.value, trippassengerfieldid);
                      }}
                      onBlur={(evt) => {
                        handleBlur(evt.target.value, trippassengerfieldid);
                      }}
                      inputProps={{
                        maxLength: 1000
                      }}
                      error={field?.error ? true : false}
                      helpertext={field?.error}
                      minRows={3}
                    />
                    {field?.error && (
                      <FormHelperText className='input-form-helper-text--error'>
                        {field?.error}
                      </FormHelperText>
                    )}
                  </Box>
                ) : fieldtype?.key === DATE ? (
                  <Box className='w-100 passenger-field' key={index}>
                    <XGDatePicker
                      className={`${field?.error ? ' passenger-form__input-error ' : ''}`}
                      style={{ width: '100%' }}
                      format={`${t('common:dateFormat')}`}
                      id={`passenger-${field?.key}-input-${index}`}
                      name={field?.key}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}`}
                      value={field?.value ? new Date(field?.value) : null}
                      onChange={(selectedDate) => {
                        handleChange(
                          toUTCDate(selectedDate).toISOString(),
                          trippassengerfieldid,
                          true
                        );
                      }}
                      error={field?.error ? true : false}
                      hint={field?.error}
                    />
                  </Box>
                ) : (
                  <Box className='w-100 passenger-field' key={index}>
                    <XGInput
                      className='passenger-update-modal-input'
                      error={field?.error ? true : false}
                      hint={field?.error}
                      label={`${field?.title}${field?.isrequired ? '*' : ''}`}
                      name={field?.key}
                      placeholder={`${t('common:enter')} ${field?.title?.toLowerCase()}`}
                      id={`passenger-${field?.key}-input-${index}`}
                      type={'text'}
                      value={field?.value}
                      onChange={(evt) => {
                        handleChange(evt.target?.value, trippassengerfieldid);
                      }}
                      onBlur={(evt) => {
                        handleBlur(evt.target.value, trippassengerfieldid);
                      }}
                      inputProps={{
                        maxLength: 250
                      }}
                    />
                  </Box>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='share-dialog-close-button'>
          <XGButton
            id='dialog-close-button'
            text={t('common:close')}
            type='secondary'
            size='large'
            variant='outlined'
            onClick={handleClose}
          />
        </div>
      </Box>
    </Modal>
  );
};

PassengersUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  bookingPassenger: PropTypes.object,
  rowPassengerFields: PropTypes.array,
  tripId: PropTypes.string,
  setRowPassengerFields: PropTypes.func,
  updatePassengerField: PropTypes.func
};

export default PassengersUpdateModal;
