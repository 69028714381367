// @ts-nocheck
import IconButton from '@mui/material/IconButton';
import { DotsSixVertical, Trash } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import '../../PassengerFieldsPanel/PassengersFieldsTable/PassengersFieldsTable.css';
import '../AmenitiesTable/AmenitiesTable.css';
import * as PropTypes from 'prop-types';
import { Checkbox, Switch } from '@mui/material';
import { useState } from 'react';
import AddAmenityRow from '../AddAmenityRow';

/**
 * Render the amenity table row
 *
 * @param {object} amenity - Amenity data object
 * @param {function} updateAmenityStatus - Callback function for update action
 * @param {function} handleDelete - Callback function for delete action
 * @param {function} setAmenitiesList - Function to update the list of amenities
 * @return {JSX.Element} The amenity table row component
 */
const AmenityRow = ({
  amenity,
  updateAmenity,
  handleDelete,
  setAmenitiesList
}) => {
  const { isdefault, isincluded } = amenity;
  const { t } = useTranslation(['settings', 'common']);
  const [isEditing, setIsEditing] = useState(false);

  const handleChangeSelect = (e) => {
    const { name, checked } = e.target;

    let newIsIncluded = amenity.isincluded;

    if (name === 'isincluded') {
      // If isincluded is checked, set isincluded to true
      newIsIncluded = checked ? true : null;
    } else if (name === 'isexcluded') {
      // If isexcluded is checked, set isincluded to false
      newIsIncluded = checked ? false : null;
    }

    const newValues = {
      isincluded: newIsIncluded
    };

    updateAmenity({ ...amenity, ...newValues });
  };

  const handleDefaultChange = (e) => {
    const { checked } = e.target;
    updateAmenity({ ...amenity, isdefault: checked });
  };

  // Handle row click to enter edit mode
  const handleRowClick = (e) => {
    // Don't trigger edit mode when clicking on checkboxes, switches or delete button
    if (
      e.target.type === 'checkbox' ||
      e.target.tagName === 'INPUT' ||
      e.target.closest('button') ||
      e.target.closest('svg')
    ) {
      return;
    }
    setIsEditing(true);
  };

  // Toggle visibility of edit form
  const toggleEditVisibility = (visible) => {
    setIsEditing(visible);
  };

  // Determine the status based on isdefault and isincluded
  const isDefault = isdefault === true;
  const isIncluded = isincluded === true;
  const isExcluded = isincluded === false;

  // If in editing mode, render the AddAmenityRow component with the current amenity data
  if (isEditing) {
    return (
      <AddAmenityRow
        isVisible={true}
        setAmenitiesList={setAmenitiesList}
        toggleVisibility={toggleEditVisibility}
        amenityToEdit={amenity}
      />
    );
  }

  return (
    <table className='amenities-table-columns'>
      <tbody>
        <tr onClick={handleRowClick} style={{ cursor: 'pointer' }}>
          <td>
            <div className='cell-content'>
              <DotsSixVertical
                className='passengersfields_icon'
                color='#344054'
                size={22}
                style={{ marginRight: '8px' }}
              />
              <Checkbox
                id='amenity-default-checkbox'
                name='debounce'
                checked={isDefault}
                onChange={handleDefaultChange}
                color='primary'
                sx={{ padding: '0px' }}
              />
            </div>
          </td>
          <td>
            {amenity?.name || ''}
            {amenity?.description && (
              <div
                style={{
                  fontSize: '0.8rem',
                  color: '#667085',
                  marginTop: '4px'
                }}
              >
                {amenity.description}
              </div>
            )}
          </td>
          <td>
            <Switch
              id='add-amenity-isincluded-checkbox'
              name='isincluded'
              checked={isIncluded}
              onChange={handleChangeSelect}
              color='primary'
            />
          </td>
          <td>
            <Switch
              id='add-amenity-include-checkbox'
              name='isexcluded'
              checked={isExcluded}
              onChange={handleChangeSelect}
              color='primary'
            />
          </td>
          <td>
            <IconButton
              aria-label={t('common:delete')}
              onClick={() => {
                handleDelete(amenity?.amenityid);
              }}
            >
              <Trash size={24} color='#1361D8' />
            </IconButton>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

AmenityRow.propTypes = {
  amenity: PropTypes.object,
  updateAmenity: PropTypes.func,
  handleDelete: PropTypes.func,
  setAmenitiesList: PropTypes.func
};

export default AmenityRow;
