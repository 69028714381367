//@ts-nocheck
import { parseISO } from 'date-fns';

export const isValidDate = (date) => {
  if (!date) return false;

  const parsedDate = date instanceof Date ? date : new Date(date);

  return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
};

export const getDateWithoutOffset = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

export const parseISODateWithoutOffset = (date) => {
  return date && typeof date === 'string' ? parseISO(date.split('T')[0]) : date;
};

export const getDayOfWeekName = (date) => {
  if (!date) {
    return '';
  }

  return date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
};

export const toUTCDate = (date) => {
  if (!date) {
    return date;
  }
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
  );
  return utcDate;
};

export const formatDateWithAddedDay = (
  date,
  daysToAdd = 0,
  locale = 'en-US',
  options = { month: 'long', year: 'numeric' }
) => {
  const dateWithDay = new Date(date);
  if (daysToAdd > 0) {
    dateWithDay.setDate(dateWithDay.getDate() + daysToAdd);
  }

  const formatter = new Intl.DateTimeFormat(locale, options);
  const formatDay = (date) => String(date.getDate()).padStart(2, '0');

  return `${formatDay(dateWithDay)} ${formatter.format(dateWithDay)}`;
};

export const formatDateRange = (
  startDate,
  endDate,
  locale = 'en-US',
  options = { month: 'long', year: 'numeric' }
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (!startDate || !endDate) return '';

  return `${formatDateWithAddedDay(start, 0, locale, options)} - ${formatDateWithAddedDay(end, 0, locale, options)}`;
};

export const formatDateWithAddedDays = (
  startDate,
  daysToAdd,
  locale,
  options
) => {
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + daysToAdd);
  return formatDateRange(startDate, endDate, { locale, options });
};

export const formatDateWithLocale = (
  date,
  locale = 'en-US',
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
) => {
  if (!date || Number.isNaN(Date.parse(date))) {
    return date;
  }

  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(new Date(date));
};

export const formatDate = (dateString, format) => {
  // Ensure a value is provided
  if (!isValidDate(dateString)) return '';

  const date = new Date(dateString);

  const pad = (num, size = 2) => num.toString().padStart(size, '0');

  const formatMap = {
    YYYY: date.getUTCFullYear().toString(),
    MM: pad(date.getUTCMonth() + 1),
    DD: pad(date.getUTCDate()),
    HH: pad(date.getUTCHours()),
    mm: pad(date.getUTCMinutes()),
    ss: pad(date.getUTCSeconds())
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (match) => formatMap[match]);
};

export const formatDurationFromDate = (dateString) => {
  // Ensure a value is provided
  if (!isValidDate(dateString)) return '';

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return '';
  }

  const hours = date.getHours();
  const minutes = date.getMinutes();

  if (hours === 0) {
    return `${minutes}MIN`;
  }

  return minutes > 0 ? `${hours}HRS ${minutes}MIN` : `${hours}HRS`;
};

export const formatFullDate = (
  date,
  language = 'en-US',
  timeZone = 'Europe/London'
) => {
  // Convert to the specified timezone
  const options = { timeZone, hour12: true };
  const localDate = new Date(date.toLocaleString(language, options));

  // Extract date parts manually
  const day = localDate.getDate();
  const month = localDate.toLocaleString(language, { month: 'short' });
  const year = localDate.getFullYear();
  const time = formatTime(localDate);

  const tz =
    new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: 'short' })
      .formatToParts(localDate)
      .find((part) => part.type === 'timeZoneName')?.value || '';

  return `${day} ${month} ${year} ${time} ${tz}`;
};

export const formatDateWithTimezone = (
  dateValue,
  timeZone = 'Europe/London'
) => {
  // Ensure a value is provided
  if (!isValidDate(dateValue)) return '';

  // Convert both date and time to the correct timezone
  const options = { timeZone, hour12: true };
  const localDate = new Date(dateValue.toLocaleString('en-US', options));

  // Extract date parts
  const day = localDate.getDate();
  const month = localDate.toLocaleString('en-US', { month: 'short' });
  const year = localDate.getFullYear();

  return `${day} ${month} ${year} ${formatTimeWithTimezone(dateValue, timeZone)}`;
};

export const formatDateTimeWithTimezone = (
  dateValue,
  timeValue,
  timeZone = 'Europe/London'
) => {
  // Ensure a value is provided
  if (!isValidDate(dateValue))
    return formatTimeWithTimezone(timeValue, timeZone);

  // Convert both date and time to the correct timezone
  const options = { timeZone, hour12: true };
  const localDate = new Date(dateValue.toLocaleString('en-US', options));

  // Extract date parts
  const day = localDate.getDate();
  const month = localDate.toLocaleString('en-US', { month: 'short' });
  const year = localDate.getFullYear();

  return `${day} ${month} ${year} ${formatTimeWithTimezone(timeValue, timeZone)}`;
};

export const formatTimeWithTimezone = (
  timeValue,
  timeZone = 'Europe/London'
) => {
  // Ensure a value is provided
  if (!isValidDate(timeValue)) return '';

  const options = { timeZone, hour12: true };
  const localTime = new Date(timeValue.toLocaleString('en-US', options));
  const time = formatTime(timeValue);

  const tz =
    new Intl.DateTimeFormat('en-US', { timeZone, timeZoneName: 'short' })
      .formatToParts(localTime)
      .find((part) => part.type === 'timeZoneName')?.value || '';

  return `${time} ${tz}`;
};

export const formatDateTime = (dateValue, timeValue) => {
  const options = { hour12: true };
  const localTime = new Date(timeValue.toLocaleString('en-US', options));
  const localDate = new Date(dateValue.toLocaleString('en-US', options));
  const day = localDate.getDate();
  const month = localDate.toLocaleString('en-US', { month: 'short' });
  const year = localDate.getFullYear();

  return `${day} ${month} ${year} ${formatTime(localTime)}`;
};

export const formatTime = (timeValue) => {
  const localTime = new Date(timeValue.toLocaleString('en-US', {}));
  let hours = localTime.getHours();
  let minutes = localTime.getMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  const minutesStr =
    minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';

  return `${hours}${minutesStr}${amPm}`;
};
