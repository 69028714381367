import HotelService from './HotelService';
import RestaurantService from './RestaurantService';
import ActivityService from './ActivityService';
import FlightService from './FlightService';
import TrainService from './TrainService';
import CarService from './CarService';
import TransferService from './TransferService';

export default {
  ActivityService,
  CarService,
  FlightService,
  HotelService,
  RestaurantService,
  TrainService,
  TransferService
};
