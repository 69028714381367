// @ts-nocheck
import Editor from '../../../Editor/Editor';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  IconButton,
  Modal
} from '@mui/material';
import { useState, forwardRef, useEffect } from 'react';
import { XGInput, XGRadio, XGDatePicker, XGButton } from '@xg-cl/xg-cl';
import { Trash } from '@phosphor-icons/react';
import { preventBubbling } from '../../../../utils/eventHelper';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  removeTripPlan,
  updateTripPlan
} from '../../../../redux/slices/trippricing/trippricing';
import {
  parseISODateWithoutOffset,
  toUTCDate
} from '../../../../utils/dateUtility';
import * as PropTypes from 'prop-types';
import './PlanForm.css';
import CloseIcon from '../../../../assets/images/CloseIcon';
import {
  NEW,
  TO_BE_DELETED,
  UPDATED
} from '../../../../redux/slices/trippricing/trippricingStatuses';

/**
 * Functional React component for rendering a custom  plan form.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the plan form component.
 * @param {Object} [props.data] - Object containing the data to fill the plan form.
 * @param {Object} [props.data.title] - The title of the plan.
 * @param {Object} [props.data.description] - The description of the plan.
 * @param {Object} [props.data.priceperperson] - The price per person of the plan.
 * @param {Object} [props.data.occupancy] - The occupancy of the plan.
 * @param {Object} [props.data.maxavailable] - The max available of the plan.
 * @param {Object} [props.data.depositamount] - The deposit amount of the plan.
 * @param {Object} [props.data.depositduedate] - The deposit due date of the plan.
 * @param {Object} [props.data.lastbookdate] - The last book date of the plan.
 * @param {Object} [props.data.finalpaymentduedate] - The last book date of the plan.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the plan form.
 */

const PlanForm = forwardRef(({ id, data, ...rest }, ref) => {
  const { t } = useTranslation(['trip', 'common']);
  const dispatch = useDispatch();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { selectedradio, tripplanid, description, status } = data;

  // eslint-disable-next-line no-unused-vars
  const [planDescription, setPlanDescription] = useState(description);

  const [formData, setFormData] = useState({ ...data });

  const formError = {
    title: '',
    priceperperson: '',
    occupancy: '',
    maxavailable: '',
    depositamount: '',
    lastbookdate: '',
    finalpaymentduedate: '',
    ...data?.error
  };

  const toggleConfirmationDialog = () => {
    setOpenConfirmationDialog(!openConfirmationDialog);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleBlur = () => {
    if (status === NEW) {
      dispatch(
        updateTripPlan({
          tripplanid,
          ...formData
        })
      );
    } else {
      dispatch(
        updateTripPlan({
          tripplanid,
          ...formData,
          status: UPDATED
        })
      );
    }
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    if (status === NEW) {
      dispatch(
        updateTripPlan({
          tripplanid,
          ...formData,
          [name]: value,
          maxavailable: value === 'unlimited' ? null : formData.maxavailable
        })
      );
    } else {
      dispatch(
        updateTripPlan({
          tripplanid,
          ...formData,
          [name]: value,
          maxavailable: value === 'unlimited' ? null : formData.maxavailable,
          status: UPDATED
        })
      );
    }
  };

  const handleOnChangeInteger = (evt) => {
    const { name, value } = evt.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim()
    }));
  };

  const handleOnBlurInteger = (evt) => {
    const { tagName, value } = evt.target;

    if (tagName.toLowerCase() === 'input') {
      if (value.trim() === '') {
        if (status === NEW) {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData
            })
          );
        } else {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData,
              status: UPDATED
            })
          );
        }
      }

      if (value.trim() != '' && !isNaN(parseInt(value))) {
        if (status === NEW) {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData
            })
          );
        } else {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData,
              status: UPDATED
            })
          );
        }
      }
    }
  };

  const handleOnChangeNumber = (evt) => {
    const { name, value } = evt.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim()
    }));
  };

  const handleOnBlurNumber = (evt) => {
    const { tagName, value } = evt.target;

    if (tagName.toLowerCase() === 'input') {
      if (value.trim() === '') {
        if (status === NEW) {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData
            })
          );
        } else {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData,
              status: UPDATED
            })
          );
        }
      }

      if (value.trim() != '' && !isNaN(Number(value.trim()))) {
        if (status === NEW) {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData
            })
          );
        } else {
          dispatch(
            updateTripPlan({
              tripplanid,
              ...formData,
              status: UPDATED
            })
          );
        }
      }
    }
  };

  const handleDeletePlan = async (event) => {
    preventBubbling(event);
    toggleConfirmationDialog();

    if (status === NEW) {
      dispatch(
        removeTripPlan({
          tripplanid
        })
      );
    } else {
      dispatch(
        updateTripPlan({
          tripplanid,
          status: TO_BE_DELETED
        })
      );
    }
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <Box
      ref={ref}
      id={id}
      className='trip__pricing-plan__form'
      data-testid='trip-pricing-plan-form'
      {...rest}
    >
      <Modal open={openConfirmationDialog} onClose={toggleConfirmationDialog}>
        <Box className='trip__pricing-plan__confirmation-container'>
          <div className='trip__pricing-plan__confirmation-header'>
            <h2 className='h2-semibold'>Remove plan</h2>
            <div
              onClick={toggleConfirmationDialog}
              style={{ cursor: 'pointer', width: '24', height: '24px' }}
            >
              <CloseIcon id='trip-pricing-close-confirmation-modal' />
            </div>
          </div>
          <span className='text-sm-regular'>
            Are you sure you want to remove this plan?
          </span>
          <div className='trip__pricing-plan__confirmation-buttons-container'>
            <XGButton
              id='trip-pricing-remove-btn'
              size='large'
              variant='contained'
              onClick={handleDeletePlan}
              text='Yes remove'
              type='secondary'
              className='trip__pricing-plan__confirmation-button trip__pricing-plan__confirmation-button--remove'
            />
            <XGButton
              id='trip-pricing-keep-btn'
              size='large'
              variant='contained'
              onClick={toggleConfirmationDialog}
              text="Don't remove"
              type='primary'
              className='trip__pricing-plan__confirmation-button trip__pricing-plan__confirmation-button--keep'
            />
          </div>
        </Box>
      </Modal>
      <Accordion style={{ width: '100%' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='pricing-form-panel-content'
          id='pricing-form-panel-header'
          className='trip__pricing-plan__accordion-summary'
        >
          <span className='text-lg-semibold'>{formData.title}</span>
          <div className='trip__pricing-plan__form__delete'>
            <IconButton onClick={toggleConfirmationDialog}>
              <Trash size={20} />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails className='trip__pricing-plan__accordion-details'>
          <Grid container style={{ position: 'relative' }}>
            <XGInput
              error={formError.title !== ''}
              hint={formError.title}
              label={`${t('title')}*`}
              name='title'
              placeholder={t('planTitle')}
              id={`plan-title-input-${tripplanid}`}
              type='text'
              value={formData.title}
              onChange={handleChange}
              inputProps={{
                maxLength: 40
              }}
              onBlur={handleBlur}
            />
            <span className='title-length-hint length-hint text-sm-medium'>
              {formData.title.length}/40
            </span>
          </Grid>

          <Grid container>
            <Editor
              editable={true}
              content={planDescription || ''}
              id={`plan-description-editor-${tripplanid}`}
              setDescription={() => {}}
              setMarkdown={(value) => {
                if (status === NEW) {
                  dispatch(
                    updateTripPlan({
                      tripplanid,
                      ...formData,
                      'description': value
                    })
                  );
                } else {
                  dispatch(
                    updateTripPlan({
                      tripplanid,
                      ...formData,
                      status: UPDATED,
                      'description': value
                    })
                  );
                }
              }}
              placeholder={`${t('enterDescription')}`}
              label={`${t('description')}`}
            />
          </Grid>

          <Grid container spacing={'16px'}>
            <Grid item xs={6}>
              <XGInput
                error={formError.priceperperson !== ''}
                hint={formError.priceperperson}
                label={`${t('pricePerPerson')}*`}
                name='priceperperson'
                placeholder={t('enterPricePerPerson')}
                id={`plan-priceperperson-input-${tripplanid}`}
                type='text'
                value={formData.priceperperson || ''}
                onChange={handleOnChangeNumber}
                inputProps={{
                  maxLength: 10
                }}
                onBlur={handleOnBlurNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <XGInput
                error={formError.occupancy !== ''}
                hint={formError.occupancy}
                label={`${t('participantsPerBooking')}`}
                name='occupancy'
                placeholder={t('enterParticipantsPerBooking')}
                id={`plan-occupancy-input-${tripplanid}`}
                type='text'
                value={formData?.occupancy || ''}
                onChange={handleOnChangeInteger}
                inputProps={{
                  maxLength: 5
                }}
                onBlur={handleOnBlurInteger}
              />
            </Grid>
          </Grid>

          <Grid container spacing={'16px'}>
            <Grid item xs={12}>
              <span className=' text-md-semibold'>{t('availability')}</span>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'column'
                }}
              >
                <XGRadio
                  id='availability-radio-unlimited'
                  name='selectedradio'
                  label={t('unlimited')}
                  size='small'
                  value='unlimited'
                  checked={selectedradio === 'unlimited'}
                  onChange={handleRadioChange}
                />
                <XGRadio
                  id='availability-radio-limited'
                  name='selectedradio'
                  label={t('limited')}
                  size='small'
                  value='limited'
                  checked={selectedradio === 'limited'}
                  onChange={handleRadioChange}
                />
              </Box>
            </Grid>

            {selectedradio == 'limited' && (
              <Grid item xs={6}>
                <XGInput
                  error={formError.maxavailable !== ''}
                  hint={formError.maxavailable}
                  label={`${t('quantity')}`}
                  name='maxavailable'
                  placeholder={t('enterQuantity')}
                  id={`pricing-maxavailable-input-${tripplanid}`}
                  type='text'
                  value={formData?.maxavailable || ''}
                  onInput={handleOnChangeInteger}
                  inputProps={{
                    maxLength: 5
                  }}
                  onBlur={handleOnBlurInteger}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={'16px'}>
            <Grid item xs={12}>
              <span className=' text-md-semibold'>{t('depositAmountDue')}</span>
            </Grid>
            <Grid item xs={6}>
              <XGInput
                error={formError.depositamount !== ''}
                hint={formError.depositamount}
                name='depositamount'
                placeholder={t('enterDepositAmountDue')}
                id={`pricing-depositamount-input-${tripplanid}`}
                type='text'
                value={formData?.depositamount || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container spacing={'16px'}>
            <Grid item xs={12}>
              <span className=' text-md-semibold'>{t('bookindDeadline')}</span>
            </Grid>
            <Grid item xs={6}>
              <XGDatePicker
                error={formError.lastbookdate !== ''}
                hint={formError.lastbookdate}
                format={`${t('common:dateFormat')}`}
                id={`pricing-bookingDeadLine-${tripplanid}`}
                name='lastbookdate'
                value={
                  parseISODateWithoutOffset(formData?.lastbookdate) || null
                }
                onChange={(selectedDate) => {
                  if (status === NEW) {
                    dispatch(
                      updateTripPlan({
                        tripplanid,
                        ...formData,
                        lastbookdate: toUTCDate(selectedDate).toISOString()
                      })
                    );
                  } else {
                    dispatch(
                      updateTripPlan({
                        tripplanid,
                        ...formData,
                        status: UPDATED,
                        lastbookdate: toUTCDate(selectedDate).toISOString()
                      })
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={'16px'}>
            <Grid item xs={12}>
              <span className=' text-md-semibold'>
                {t('finalPaymentDueDate')}
              </span>
            </Grid>
            <Grid item xs={6}>
              <XGDatePicker
                error={formError.finalpaymentduedate !== ''}
                hint={formError.finalpaymentduedate}
                format={`${t('common:dateFormat')}`}
                id={`pricing-finalPaymentDueDate-${tripplanid}`}
                name='finalpaymentduedate'
                value={
                  parseISODateWithoutOffset(formData?.finalpaymentduedate) ||
                  null
                }
                onChange={(selectedDate) => {
                  if (status === NEW) {
                    dispatch(
                      updateTripPlan({
                        tripplanid,
                        ...formData,
                        finalpaymentduedate:
                          toUTCDate(selectedDate).toISOString()
                      })
                    );
                  } else {
                    dispatch(
                      updateTripPlan({
                        tripplanid,
                        ...formData,
                        status: UPDATED,
                        finalpaymentduedate:
                          toUTCDate(selectedDate).toISOString()
                      })
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

PlanForm.propTypes = {
  data: PropTypes.shape({
    tripplanid: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    priceperperson: PropTypes.string,
    occupancy: PropTypes.string,
    maxavailable: PropTypes.string,
    depositamount: PropTypes.string,
    depositduedate: PropTypes.string,
    lastbookdate: PropTypes.string,
    finalpaymentduedate: PropTypes.string,
    selectedradio: PropTypes.string,
    error: PropTypes.object,
    status: PropTypes.string
  }),
  id: PropTypes.string.isRequired
};

PlanForm.displayName = 'PlanForm';

export default PlanForm;
