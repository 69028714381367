// @ts-nocheck
import Editor from '../../../../Editor/Editor';
import ServiceCardImages from '../ServiceCardImages/ServiceCardImages';
import ServiceCardAttachments from '../ServiceCardAttachments/ServiceCardAttachments';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

/**
 * A service card component for restaurants.
 *
 * @param {Object} servicefields - the fields of the service
 * @param {string} tripdetaileddayserviceid - the id of the tripdetaileddayservice
 * @returns {JSX.Element}
 */
const RestaurantCard = ({ servicefields, tripdetaileddayserviceid }) => {
  const { t } = useTranslation(['services']);

  return (
    <Box id={`service-card-activity-${tripdetaileddayserviceid}`}>
      <Box className='service-view-card__content'>
        <Box className='service-view-card__content-row'>
          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('restaurantName')}:</span>{' '}
              {servicefields.RESTAURANTNAME || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('contactTel')}:</span> {servicefields.PHONENUMBER || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('address')}:</span> {servicefields.ADDRESS || ''}
            </p>
            <p className='service-view-card__label-value'>
              <a href={servicefields.LINK || ''}>{t('website')}</a>
            </p>
          </Box>
        </Box>

        <Box>
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-editor-restaurant-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-view-card__description'
            />
          )}

          <Box className='service-view-card__confirmation'>
            <p>
              <span>{t('confirmation')}:</span>{' '}
              {servicefields.CONFIRMATIONINFORMATION || ''}
            </p>
          </Box>
        </Box>

        {(servicefields.UPLOADIMAGES || servicefields.UPLOADATTACHMENTS) && (
          <Box className='service-view-card__files-section'>
            {servicefields?.UPLOADIMAGES && (
              <ServiceCardImages images={servicefields?.UPLOADIMAGES} />
            )}
            {servicefields?.UPLOADATTACHMENTS && (
              <ServiceCardAttachments
                attachments={servicefields?.UPLOADATTACHMENTS}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

RestaurantCard.propTypes = {
  servicefields: PropTypes.object,
  servicetype: PropTypes.object,
  tripdetaileddayserviceid: PropTypes.number
};

export default RestaurantCard;
