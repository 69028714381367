// @ts-nocheck
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const fallbackImage = '/img/TH-placeholder.png';

const ImageSection = ({ images }) => {
  if (!images || images.length < 1) return null;

  return (
    <Box className='service-section__images'>
      {images.map(
        (image) =>
          image && (
            <img
              src={image}
              alt='Uploaded'
              style={{ display: 'block' }}
              key={image}
              onError={(evt) => {
                evt.target.src = fallbackImage;
              }}
            />
          )
      )}
    </Box>
  );
};

ImageSection.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
};
export default ImageSection;
