/**
 * Ensures the given URL starts with "https://".
 * If it already has "http://" or "https://", it remains unchanged.
 *
 * @param {string} url - The URL to check.
 * @returns {string} - The formatted URL with "https://" if missing.
 */
export const ensureHttps = (url) => {
  return /^(https?:\/\/)/.test(url) ? url : `https://${url}`;
};
