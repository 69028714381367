// @ts-nocheck
import { Box } from '@mui/material';
import { Notebook, MapPin, CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ServicePropTypes } from '../../types';
import Editor from '../../../../../Editor/Editor';
import ImageSection from '../Components/ImageSection';
import AttachmentNotice from '../Components/AttachmentNotice';
import IconSection from '../Components/IconSection';
import useComponentHeight from '../../../../../../hooks/useComponentHeight';
import { ensureHttps } from '../../../../../../utils/urlHelper';

const RestaurantService = ({
  servicetype,
  servicefields,
  tripdetaileddayserviceid,
  tenantsettings,
  icon
}) => {
  const { ref, height } = useComponentHeight();
  const { t } = useTranslation();
  const columnFields = ['ADDRESS', 'LINK', 'RESTAURANTNAME', 'PHONENUMBER'];
  const hasColumnFieldData = columnFields.some(
    (field) => !!servicefields[field]
  );

  return (
    <Box
      className='service-section'
      id={`service-section-restaurant-${tripdetaileddayserviceid}`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
      ref={ref}
    >
      <Box className='service-section__icon-name'>
        <IconSection icon={icon} height={height} />
        <h3
          className='service-section__name'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {servicetype?.name || 'Restaurant'}
        </h3>
      </Box>

      <Box className='service-section__content'>
        {servicefields.TITLE && (
          <h5
            className='service-section__title'
            style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
          >
            {servicefields?.TITLE}
          </h5>
        )}

        <ImageSection images={servicefields?.UPLOADIMAGES} />

        <Box
          className='service-section___column-info'
          style={{ fontFamily: tenantsettings.bodyfont }}
        >
          {(servicefields?.RESTAURANTNAME || servicefields?.PHONENUMBER) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Notebook />
              </Box>
              <Box>
                {servicefields?.RESTAURANTNAME && (
                  <p className='service-section__label-value'>
                    <span>{t('Restaurant Name')}:</span>{' '}
                    {servicefields.RESTAURANTNAME}
                  </p>
                )}
                {servicefields?.PHONENUMBER && (
                  <p className='service-section__label-value'>
                    <span>{t('Contact Tel')}:</span> {servicefields.PHONENUMBER}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields?.ADDRESS || servicefields?.LINK) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                {servicefields?.ADDRESS && (
                  <p className='service-section__label-value'>
                    <span>{t('Address')}:</span> {servicefields.ADDRESS}
                  </p>
                )}
                {servicefields.LINK && (
                  <p className='service-section__label-value'>
                    <a
                      href={ensureHttps(servicefields.LINK)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {t('Website')}
                    </a>
                  </p>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          className={`service-section__description-notes ${hasColumnFieldData ? 'spacing' : ''}`}
        >
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-section-restaurant-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-section__description'
              style={{
                fontFamily: tenantsettings?.bodyfont || 'inherit'
              }}
            />
          )}

          {servicefields?.CONFIRMATIONINFORMATION && (
            <Box className='service-section__confirmation'>
              <p>
                <span>{t('Confirmation #')}:</span>{' '}
                {servicefields.CONFIRMATIONINFORMATION}
              </p>
            </Box>
          )}

          <AttachmentNotice attachments={servicefields?.UPLOADATTACHMENTS} />

          {servicefields?.MEALSINCLUDED && (
            <Box className='service-section__include-values'>
              {servicefields?.MEALSINCLUDED?.map((meal, index) => (
                <Box
                  className='service-section__include-values__item'
                  key={`meals-included-${meal.toLowerCase()}-${index}`}
                >
                  <CheckCircle color='rgba(56, 211, 133, 1)' />
                  <p>{meal}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

RestaurantService.propTypes = ServicePropTypes;

export default RestaurantService;
