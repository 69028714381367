// @ts-nocheck
import { useState } from 'react';
import './CustomTabs.css';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering custom tabs
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed view.
 */

const CustomTabs = ({ children, handleTabChange }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    handleTabChange(index);
  };

  return (
    <div>
      <div className='tab-buttons'>
        {children.map(
          (tab, index) =>
            tab && (
              <button
                key={index}
                className={activeTab === index ? 'active' : ''}
                onClick={() => handleTabClick(index)}
              >
                {tab.props.label}
              </button>
            )
        )}
      </div>

      <div className='tab-content'>{children[activeTab]}</div>
    </div>
  );
};

CustomTabs.propTypes = {
  children: PropTypes.node,
  handleTabChange: PropTypes.func.isRequired
};

export default CustomTabs;
