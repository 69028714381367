// @ts-nocheck
import { Box } from '@mui/material';
import { Clock, MapPin, CheckCircle, Notebook } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ServicePropTypes } from '../../types';
import Editor from '../../../../../Editor/Editor';
import { formatDateTime } from '../../../../../../utils/dateUtility';
import ImageSection from '../Components/ImageSection';
import AttachmentNotice from '../Components/AttachmentNotice';
import IconSection from '../Components/IconSection';
import useComponentHeight from '../../../../../../hooks/useComponentHeight';

const CarService = ({
  servicetype,
  servicefields,
  tripdetaileddayserviceid,
  tenantsettings,
  icon
}) => {
  const { ref, height } = useComponentHeight();
  const { t } = useTranslation();
  const columnFields = [
    'RENTALCOMPANY',
    'CARCLASS',
    'PICKUPADDRESS',
    'PICKUPDATE',
    'PICKUPTIME',
    'DROPOFFADDRESS',
    'DROPOFFDATE',
    'DROPOFFTIME'
  ];
  const hasColumnFieldData = columnFields.some(
    (field) => !!servicefields[field]
  );

  return (
    <Box
      className='service-section'
      id={`service-section-car-${tripdetaileddayserviceid}`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
      ref={ref}
    >
      <Box className='service-section__icon-name'>
        <IconSection icon={icon} height={height} />
        <h3
          className='service-section__name'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {servicetype.name}
        </h3>
      </Box>

      <Box className='service-section__content'>
        {servicefields.TITLE && (
          <h5
            className='service-section__title'
            style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
          >
            {servicefields.TITLE}
          </h5>
        )}

        <ImageSection images={servicefields?.UPLOADIMAGES} />

        <Box className='service-section___column-info'>
          {(servicefields.RENTALCOMPANY || servicefields.CARCLASS) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Notebook />
              </Box>
              <Box>
                {servicefields.RENTALCOMPANY && (
                  <p className='service-section__label-value'>
                    <span>{t('Company')}:</span> {servicefields.RENTALCOMPANY}
                  </p>
                )}
                {servicefields.CARCLASS && (
                  <p className='service-section__label-value'>
                    <span>{t('Car Class')}:</span> {servicefields.CARCLASS}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields.PICKUPADDRESS || servicefields.DROPOFFADDRESS) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                {servicefields.PICKUPADDRESS && (
                  <p className='service-section__label-value'>
                    <span>{t('Pick Up Location')}:</span>{' '}
                    {servicefields.PICKUPADDRESS}
                  </p>
                )}
                {servicefields.DROPOFFADDRESS && (
                  <p className='service-section__label-value'>
                    <span>{t('Drop Off Location')}:</span>{' '}
                    {servicefields.DROPOFFADDRESS}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields.PICKUPDATE ||
            servicefields.PICKUPTIME ||
            servicefields.DROPOFFDATE ||
            servicefields.DROPOFFTIME) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Clock />
              </Box>
              <Box>
                {servicefields.PICKUPDATE && servicefields.PICKUPTIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Pick Up Details')}:</span>{' '}
                    {formatDateTime(
                      servicefields.PICKUPDATE,
                      servicefields.PICKUPTIME
                    )}
                  </p>
                )}
                {servicefields.DROPOFFDATE && servicefields.DROPOFFTIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Drop Off Details')}:</span>{' '}
                    {formatDateTime(
                      servicefields.DROPOFFDATE,
                      servicefields.DROPOFFTIME
                    )}
                  </p>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          className={`service-section__description-notes ${hasColumnFieldData ? 'spacing' : ''}`}
        >
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-section-car-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-section__description'
              style={{
                fontFamily: tenantsettings?.bodyfont || 'inherit'
              }}
            />
          )}

          {servicefields?.CONFIRMATIONINFORMATION && (
            <Box className='service-section__confirmation'>
              <p>
                <span>{t('Confirmation #')}:</span>{' '}
                {servicefields.CONFIRMATIONINFORMATION}
              </p>
            </Box>
          )}

          <AttachmentNotice attachments={servicefields?.UPLOADATTACHMENTS} />

          {servicefields?.MEALSINCLUDED && (
            <Box className='service-section__include-values'>
              {servicefields?.MEALSINCLUDED?.map((meal, index) => (
                <Box
                  className='service-section__include-values__item'
                  key={`meals-included-${meal.toLowerCase()}-${index}`}
                >
                  <CheckCircle color='rgba(56, 211, 133, 1)' />
                  <p>{meal}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CarService.propTypes = ServicePropTypes;

export default CarService;
