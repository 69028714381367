// @ts-nocheck
import Editor from '../../../../Editor/Editor';
import ServiceCardImages from '../ServiceCardImages/ServiceCardImages';
import ServiceCardAttachments from '../ServiceCardAttachments/ServiceCardAttachments';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../../utils/dateUtility';
import * as PropTypes from 'prop-types';

/**
 * A service card component for car rentals.
 *
 * @param {Object} servicefields - the fields of the service
 * @param {string} tripdetaileddayserviceid - the id of the tripdetaileddayservice
 * @returns {JSX.Element}
 */
const CarCard = ({ servicefields, tripdetaileddayserviceid }) => {
  const { t } = useTranslation(['services']);

  return (
    <Box id={`service-card-car-${tripdetaileddayserviceid}`}>
      <Box className='service-view-card__content'>
        <Box className='service-view-card__content-row'>
          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('company')}:</span> {servicefields.RENTALCOMPANY || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('carClass')}:</span> {servicefields.CARCLASS || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('pickUpLocation')}:</span>{' '}
              {servicefields.PICKUPADDRESS || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('dropOffLocation')}:</span>{' '}
              {servicefields.DROPOFFADDRESS || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('pickUpDateTime')}:</span>{' '}
              {formatDateTime(
                servicefields.PICKUPDATE,
                servicefields.PICKUPTIME
              )}
            </p>

            <p className='service-view-card__label-value'>
              <span>{t('dropOffDateTime')}:</span>{' '}
              {formatDateTime(
                servicefields.DROPOFFDATE,
                servicefields.DROPOFFTIME
              )}
            </p>
          </Box>
        </Box>

        <Box>
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-editor-car-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-view-card__description'
            />
          )}

          <Box className='service-view-card__confirmation'>
            <p>
              <span>{t('confirmation')}:</span>{' '}
              {servicefields.CONFIRMATIONINFORMATION || ''}
            </p>
          </Box>
        </Box>

        {(servicefields.UPLOADIMAGES || servicefields.UPLOADATTACHMENTS) && (
          <Box className='service-view-card__files-section'>
            {servicefields?.UPLOADIMAGES && (
              <ServiceCardImages images={servicefields?.UPLOADIMAGES} />
            )}
            {servicefields?.UPLOADATTACHMENTS && (
              <ServiceCardAttachments
                attachments={servicefields?.UPLOADATTACHMENTS}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

CarCard.propTypes = {
  servicefields: PropTypes.object,
  servicetype: PropTypes.object,
  tripdetaileddayserviceid: PropTypes.number
};

export default CarCard;
