// @ts-nocheck
import { Box } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableList from '../../../Draggable/DraggableList/DraggableList';
import { reorderItems } from '../../../../utils/common';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AmenityRow from '../AmenityRow/AmenityRow';
import './AmenitiesTable.css';
import AddAmenityRow from '../AddAmenityRow';

const AmenitiesTable = ({
  amenitiesList,
  handleUpdateSequence,
  updateAmenity,
  handleDelete,
  // eslint-disable-next-line no-unused-vars
  isDropDisabled = false,
  isAddAmenityFormVisible,
  setAmenitiesList,
  toggleVisibility
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [amenityRows, setAmenityRows] = useState([]);
  const { t } = useTranslation(['settings', 'common']);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const foundSource = amenitiesList[result.source.index];
    const foundDestination = amenitiesList[result.destination.index];

    const reorderedFields = reorderItems(
      amenitiesList,
      result.source.index,
      result.destination.index
    );

    setAmenityRows(reorderedFields);

    handleUpdateSequence(foundSource, foundDestination);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const setupAmenities = () => {
      if (amenitiesList && Object.keys(amenitiesList).length > 0) {
        const amenityRowsList = [];
        amenitiesList.map((amenity, index) => {
          const newAmenity = {
            id: index,
            amenityid: amenity?.amenityid,
            sequence: amenity?.sequence || index + 1,
            content: (
              <AmenityRow
                amenity={amenity}
                updateAmenity={updateAmenity}
                handleDelete={handleDelete}
                setAmenitiesList={setAmenitiesList}
              />
            )
          };
          amenityRowsList.push(newAmenity);
        });
        setAmenityRows(amenityRowsList);
      }
    };

    setupAmenities();
    // eslint-disable-next-line
  }, [amenitiesList]);
  return (
    <Box>
      <table className='amenities-table-columns'>
        <thead>
          <tr>
            <th>{t('settings:default')}</th>
            <th id='add-amenity-name-column'></th>
            <th>{t('settings:included')}</th>
            <th>{t('settings:excluded')}</th>
            <th id='add-amenity-actions-column'></th>
          </tr>
        </thead>
      </table>
      {isAddAmenityFormVisible && (
        <AddAmenityRow
          isVisible={isAddAmenityFormVisible}
          setAmenitiesList={setAmenitiesList}
          toggleVisibility={toggleVisibility}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        {isMounted && amenityRows.length > 0 && amenityRows && (
          <Droppable
            droppableId='amenities-field-droppablex'
            isDropDisabled={true} // TODO: Remove this once the drag and drop is implemented
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='passengers-fields-drag-and-drop'
              >
                <DraggableList items={amenityRows} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </Box>
  );
};

AmenitiesTable.propTypes = {
  amenitiesList: PropTypes.array,
  handleUpdateSequence: PropTypes.func,
  updateAmenity: PropTypes.func,
  handleDelete: PropTypes.func,
  isDropDisabled: PropTypes.bool,
  isAddAmenityFormVisible: PropTypes.bool,
  setAmenitiesList: PropTypes.func,
  toggleVisibility: PropTypes.func
};

export default AmenitiesTable;
