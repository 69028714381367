// @ts-nocheck
import AIGenerateButton from '../../../AIGenerateButton/AIGenerateButton';
import Editor from '../../../Editor/Editor';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MuiTelInput } from 'mui-tel-input';
import {
  XGInput,
  XGDatePicker,
  XGTimePicker,
  XGTextarea,
  XGSelect,
  XGAttachments,
  XGImages
} from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import {
  generateServiceTypeFieldId,
  generateServiceTypeFieldKey,
  generateCheckBoxOptions,
  getValueFromServiceField
} from '../../../../utils/serviceTypes/serviceTypes';
import CheckBoxGroup from '../../../CheckBoxGroup/CheckBoxGroup';
import { Box, Typography, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  fileType,
  pathReference,
  maxImageSize,
  maxFileSize,
  maxImages,
  maxAttachments
} from '../../../../constants/files';
import { getTripDescription } from '../../../../services/ai/ai';
import { bytesToMiB } from '../../../../utils/fileHelper';

const RenderField = ({
  field,
  onChange,
  onError,
  formData,
  setFormFieldRef,
  disabled
}) => {
  const {
    title,
    isrequired,
    description,
    servicefieldtype,
    servicefieldoption,
    servicefieldconfig,
    servicefieldid: id
  } = field;
  const key = generateServiceTypeFieldKey(field);
  const fieldId = generateServiceTypeFieldId(field);
  const label = `${title}${isrequired ? '*' : ''}`;
  const error = formData[id]?.error || '';
  const { t } = useTranslation(['common']);

  const handleChange = (event) => {
    const { value } = event.target;
    if (
      servicefieldtype?.key === 'number' &&
      (Number.isNaN(value) || value < 0)
    ) {
      return;
    }
    onChange(formData[id], event.target.value);
  };

  const handleCustomChange = (value) => {
    onChange(formData[id], value);
  };

  const handleCheckboxChange = (fieldId, optionId, updatedOptions) => {
    onChange(formData[id], updatedOptions);
  };

  const { maxcharcount } = servicefieldconfig || {};

  const getRemainingCharsHint = (value = '') => {
    if (maxcharcount) {
      return maxcharcount
        ? `${value?.trim().length ?? 0}/${maxcharcount} ${t('charactersRemaing')}`
        : '';
    }
    return '';
  };

  const isNewFile = (options = [], fileName = '') => {
    return (
      options?.filter((option) => option?.value === fileName)?.length === 0
    );
  };

  const getFieldlFiles = (options = []) => {
    return (
      options
        ?.filter((option) => !!option?.file)
        ?.map((option) => option?.file) ?? []
    );
  };

  const getFileOptions = (options = [], files = [], presignType = '') => {
    return files?.map((file, index) => {
      const foundOption = options.find((option) => option.value === file.name);
      const servicefieldoptionid = foundOption?.servicefieldoptionid ?? null;
      const tripdetaileddayservicevalueid =
        foundOption?.tripdetaileddayservicevalueid ?? null;

      return {
        optionId: servicefieldoptionid ?? index + 1,
        sequence: index + 1,
        servicefieldoptionid: servicefieldoptionid,
        tripdetaileddayservicevalueid: tripdetaileddayservicevalueid,
        value: file.name,
        file,
        path: pathReference.DETAILED_DAY_SERVICE,
        type: file?.type?.split('/')?.pop(),
        presignType: presignType,
        isNew: isNewFile(options, file?.name)
      };
    });
  };

  const generateFilesData = (files = [], filesData = [], type = '') => {
    const names = files?.map((file) => file?.name);

    const searchNames = names.filter(
      (name) => !filesData.some((item) => item.name === name)
    );

    const missingFiles = files.filter((item) =>
      searchNames?.includes(item.name)
    );

    return [
      ...filesData,
      ...(missingFiles?.map((item) => {
        return {
          tripdetaileddayservicevalueid:
            item?.tripdetaileddayservicevalueid ?? null,
          tripdetaileddayservicevaluefileid:
            item?.tripdetaileddayservicevaluefileid ?? null,
          originalname: item?.name,
          name: item?.name,
          type: type,
          extension: item?.type?.split('/')?.pop(),
          size: item?.size ?? 0,
          isNew: true
        };
      }) ?? [])
    ];
  };

  const handleImagesSelected = async (files = [], data = {}) => {
    let filesData = formData[id]?.files ?? [];
    const value = getFileOptions(formData[id]?.options, files, fileType?.IMAGE);

    if (data.action === 'remove') {
      filesData = formData[id]?.files?.filter(
        (file) => file?.name !== data?.file?.name
      );
    }

    if (data.action === 'add') {
      filesData = generateFilesData(files, filesData, 'image');
    }

    onChange(formData[id], value, { files: filesData });
  };

  const handleFilesSelected = async (files = [], data = {}) => {
    let filesData = formData[id]?.files ?? [];
    const value = getFileOptions(formData[id]?.options, files, fileType?.FILE);

    if (data.action === 'remove') {
      filesData = formData[id]?.files?.filter(
        (file) => file?.name !== data?.file?.name
      );
    }
    if (data.action === 'add') {
      filesData = generateFilesData(files, filesData, 'file');
    }

    onChange(formData[id], value, { files: filesData });
  };

  switch (servicefieldtype?.key) {
    case 'textbox':
    case 'number':
      return (
        <Box style={{ position: 'relative' }} ref={setFormFieldRef(fieldId)}>
          <XGInput
            name={fieldId}
            id={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={description}
            type={servicefieldtype.key}
            onChange={handleChange}
            value={getValueFromServiceField(formData[id]) ?? ''}
            inputProps={{
              maxLength: maxcharcount ?? null,
              min: servicefieldtype?.key === 'number' ? 0 : null
            }}
            error={error !== ''}
            hint={formData[id]?.error}
            disabled={disabled}
          />
          {maxcharcount && (
            <span className='title-length-hint length-hint text-sm-medium'>
              {getRemainingCharsHint(formData[id]?.options[0]?.value)}
            </span>
          )}
        </Box>
      );
    case 'phone':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <Typography
            fontSize={14}
            fontWeight={500}
            fontFamily={'Inter Variable'}
            className='xg-input-label'
          >
            {label}
          </Typography>
          <MuiTelInput
            name={fieldId}
            id={fieldId}
            key={key}
            defaultCountry='US'
            disabled={disabled}
            inputProps={{
              autoComplete: 'off',
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            onChange={(value) => {
              handleCustomChange(value);
            }}
            value={getValueFromServiceField(formData[id])}
          />
          {error !== '' && (
            <FormHelperText className='input-form-helper-text--error'>
              {formData[id]?.error}
            </FormHelperText>
          )}
        </Box>
      );
    case 'url':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGInput
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={description}
            type={servicefieldtype.key}
            pattern='https?://.*'
            onChange={handleChange}
            value={getValueFromServiceField(formData[id]) ?? ''}
            inputProps={{
              maxLength: maxcharcount ?? null
            }}
            error={error != ''}
            hint={formData[id]?.error}
            disabled={disabled}
          />
        </Box>
      );
    case 'date':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGDatePicker
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={description}
            type={servicefieldtype.key}
            format={`${t('common:dateFormat')}`}
            value={getValueFromServiceField(formData[id])}
            onChange={(selectedDate) => {
              handleCustomChange(selectedDate);
            }}
            error={error != ''}
            helperText={formData[id]?.error}
            disabled={disabled}
          />
        </Box>
      );
    case 'time':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGTimePicker
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={description}
            type={servicefieldtype.key}
            format={'HH:mm'}
            ampm={false}
            onChange={(selectedTime) => {
              handleCustomChange(selectedTime.toString());
            }}
            value={getValueFromServiceField(formData[id])}
            error={error != ''}
            hint={formData[id]?.error}
            disabled={disabled}
          />
        </Box>
      );
    case 'textarea':
      if (field?.key === 'DESCRIPTION') {
        return (
          <Box style={{ marginTop: '16px' }}>
            <Editor
              label={label}
              content={getValueFromServiceField(formData[id]) ?? ''}
              placeholder={description}
              editable={true}
              isPreviewing={false}
              id={`editor-tab-${fieldId}`}
              setMarkdown={handleCustomChange}
              setDescription={() => {}}
              isEditorDisabled={disabled}
              generatePrompt={
                <div>
                  <AIGenerateButton
                    id='ai-generate-trip-description-button'
                    setMarkdown={handleCustomChange}
                    setTripDescription={() => {}}
                    editorContent={getValueFromServiceField(formData[id]) ?? ''}
                    getAIDescription={getTripDescription}
                    setIsEditorDisabled={() => {}}
                    disableGenerate={true}
                  />
                </div>
              }
            />
          </Box>
        );
      } else if (field?.key === 'INTERNALNOTE') {
        return (
          <Accordion
            className='service-form-accordion'
            sx={{ margin: '0px!important' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel-content'
              id='panel-header'
            >
              <Typography
                component='label'
                className='xg-input-label accordion-label '
              >
                {label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box ref={setFormFieldRef(fieldId)}>
                <XGTextarea
                  id={fieldId}
                  name={fieldId}
                  key={key}
                  data-testid={fieldId}
                  placeholder={description}
                  type={servicefieldtype.key}
                  onChange={handleChange}
                  value={getValueFromServiceField(formData[id]) ?? ''}
                  inputProps={{
                    maxLength: maxcharcount ?? null
                  }}
                  error={error !== ''}
                  disabled={disabled}
                  minRows={3}
                />
                {error !== '' && (
                  <FormHelperText className='input-form-helper-text--error'>
                    {formData[id]?.error}
                  </FormHelperText>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      } else {
        return (
          <Box ref={setFormFieldRef(fieldId)}>
            <XGTextarea
              id={fieldId}
              name={fieldId}
              key={key}
              data-testid={fieldId}
              label={label}
              placeholder={description}
              type={servicefieldtype.key}
              onChange={handleChange}
              value={getValueFromServiceField(formData[id]) ?? ''}
              inputProps={{
                maxLength: maxcharcount ?? null
              }}
              error={error !== ''}
              disabled={disabled}
              minRows={3}
            />
            {error !== '' && (
              <FormHelperText className='input-form-helper-text--error'>
                {formData[id]?.error}
              </FormHelperText>
            )}
          </Box>
        );
      }

    case 'select':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGSelect
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={description}
            type={servicefieldtype.key}
            options={servicefieldoption}
            onChange={handleChange}
            value={getValueFromServiceField(formData[id])}
            error={error != ''}
            helperText={formData[id]?.error}
            disabled={disabled}
          />
        </Box>
      );
    case 'checkbox':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <CheckBoxGroup
            id={fieldId}
            key={key}
            groupKey={key}
            data-testid={fieldId}
            description={description}
            options={generateCheckBoxOptions(servicefieldoption ?? [])}
            selectedOptions={generateCheckBoxOptions(
              formData[id]?.options || []
            )}
            handleOnChange={handleCheckboxChange}
            fieldId={id}
            label={label}
            error={formData[id]?.error}
            disabled={disabled}
          />
        </Box>
      );
    case 'file':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGAttachments
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={title}
            type={servicefieldtype.key}
            maxFileSize={maxFileSize}
            onChange={handleChange}
            onError={(error) => onError(id, error)}
            error={error}
            onFilesSelected={handleFilesSelected}
            files={getFieldlFiles(formData[id]?.options)}
            displayAddButton={true}
            disabled={disabled}
            maxFiles={maxAttachments}
            alignment='right'
            hint={t('maxFileSize', { maxFileSize: bytesToMiB(maxFileSize) })}
          />
        </Box>
      );
    case 'image':
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGImages
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={title}
            type={servicefieldtype.key}
            maxFileSize={maxImageSize}
            onError={(error) => onError(id, error)}
            error={error}
            onImagesSelected={handleImagesSelected}
            maxFiles={maxImages}
            files={getFieldlFiles(formData[id]?.options)}
            displayAddButton={true}
            disabled={disabled}
            alignment='left'
            hint={t('maxImageSize', { maxImageSize: bytesToMiB(maxImageSize) })}
          />
        </Box>
      );
    default:
      return (
        <Box ref={setFormFieldRef(fieldId)}>
          <XGInput
            id={fieldId}
            name={fieldId}
            key={key}
            data-testid={fieldId}
            label={label}
            placeholder={description}
            onChange={handleChange}
            value={getValueFromServiceField(formData[id]) ?? ''}
            inputProps={{
              maxLength: maxcharcount ?? null
            }}
            error={error !== ''}
            hint={formData[id]?.error}
            disabled={disabled}
          />
        </Box>
      );
  }
};

RenderField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  formData: PropTypes.object,
  setFormFieldRef: PropTypes.func,
  disabled: PropTypes.bool
};

export default RenderField;
