//@ts-nocheck
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import globalConfig from '../../../config/globalConfig';
import { XGButton } from '@xg-cl/xg-cl';
import { PencilSimple } from '@phosphor-icons/react';
import { useRef, useState, useEffect } from 'react';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { ErrorToast } from '../../../utils/alerts';
import {
  getPresignedUrl,
  uploadToBucket
} from '../../../services/upload/upload';
import * as PropTypes from 'prop-types';
import './ImagePicker.css';

/**
 * Functional React component for rendering a custom image picker section.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.callback] - The callback function to be called when an image is selected.
 * @param {string} [props.id] - The ID for the login header element.
 * @param {string} [props.title] - The title for the image picker.
 * @param {string} [props.url] - The URL for the image picker.
 * @param {string} [props.fileType] - File type to process, it can be "image" or "file".
 * @param {Object} [props.rest] - Additional props to be spread onto the image picker element.
 *
 * @returns {JSX.Element} React element representing the image picker.
 */

const ImagePicker = ({
  callback,
  handleUploading,
  id,
  title,
  url,
  fileType,
  pathReference,
  tripId,
  useVersion = false,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common']);
  const inputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(url);
  const [localBlob, setLocalBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const placeHolder = '/img/trip-placeholder-image.png';
  const filetype = fileType;

  const handleChange = async (event) => {
    const file = event.target.files[0];

    // Validate file type
    if (!file.type.startsWith('image/')) {
      ErrorToast(t('common:onlyImagesAllowed'));
      return;
    }

    // Check for max size
    if (file.size > globalConfig.maxImageSize) {
      ErrorToast(t('common:imageFileTooBig'));
      return;
    }
    //Set local blob in case of error
    setLocalBlob(URL.createObjectURL(file));

    //Set image url before upload to prevent flickering
    setImageUrl(URL.createObjectURL(file));

    const replaceImageUrl = (path) => {
      const localImageUrl = new URL(path);
      const currentUrl = window.location.origin;
      const localUrl = `${currentUrl}/images${localImageUrl.pathname}`;
      return localUrl;
    };

    try {
      setIsLoading(true);
      updateUploading(true);

      const presignedImage = await getPresignedImage({
        fileType: filetype,
        files: [
          {
            name: file.name,
            size: file.size,
            type: file.type,
            pathReference,
            tripId
          }
        ]
      });

      if (presignedImage) {
        const uploadedImage = await uploadImageToBucket(presignedImage, file);

        if (uploadedImage && uploadedImage?.status === 200) {
          const imageUrl = !globalConfig.isProduction
            ? presignedImage.data.presignedUrl
                .split('?')[0]
                .replace(/(.*\/)([^/]*)$/, `$1${'images/'}$2`)
                .replace('preprossesing-', '')
            : replaceImageUrl(presignedImage.data.presignedUrl.split('?')[0]);

          callback(imageUrl)
            .then(() => {
              setImageUrl(URL.createObjectURL(file));
            })
            .catch(() => {
              setImageUrl(null);
              throw new Error(t('common:imageUploadFailed'));
            });
        } else {
          throw new Error('Error uploading image');
        }
      } else {
        throw new Error('Error uploading image');
      }
    } catch (err) {
      ErrorToast(t('common:imageUploadFailed'));
    } finally {
      updateUploading(false);
      setIsLoading(false);
    }
  };

  const updateUploading = (value = false) => {
    if (typeof handleUploading === 'function') {
      handleUploading(value);
    }
  };

  const getPresignedImage = async (fileType, images = []) => {
    const { data } = await getPresignedUrl(fileType, images);

    const presignedImage = data && data.length ? data[0] : null;

    return presignedImage;
  };

  const uploadImageToBucket = async (presignedImage, file) => {
    const result = await uploadToBucket(presignedImage, file);

    return result;
  };

  //Sync image url from url prop to imageUrl state
  useEffect(() => {
    if (url) {
      setImageUrl(url);
    } else {
      setImageUrl(placeHolder);
    }
  }, [url]);

  const pickerUrl = useVersion
    ? `${imageUrl && imageUrl?.indexOf('blob') === -1 ? imageUrl + '?v=' + new Date().getTime() : ''}`
    : imageUrl;

  return (
    <Box
      {...rest}
      className={rest.className}
      data-testid='image-picker'
      id={id}
    >
      <div className='image-picker__cover'>
        <img
          src={pickerUrl}
          key={useVersion ? new Date().getTime() : null}
          alt=''
          draggable='false'
          onError={(evt) => {
            // Set local blob or placeholder if error
            evt.target.src = localBlob ? localBlob : placeHolder;
          }}
        />

        <div
          className='image-picker__content'
          style={{
            justifyContent: `${title ? 'space-between' : 'end'}`
          }}
        >
          {title && (
            <h2
              className={`h2-semibold ${
                imageUrl ? 'image-picker__content__title' : ''
              } `}
            >
              {title}
            </h2>
          )}

          <div className='image-picker__button'>
            <XGButton
              className='image-picker__button__upload'
              disabled={isLoading}
              startIcon={<PencilSimple width={16} color='#344054' />}
              id={`image-picker-upload-file`}
              text={imageUrl ? t('changeCover') : t('addCover')}
              type='secondary'
              size='large'
              variant='outlined'
              onClick={() => {
                inputRef.current.click();
              }}
            />
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: blue[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )}
          </div>
        </div>
      </div>
      <input
        type='file'
        ref={inputRef}
        accept='image/*'
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </Box>
  );
};

/**
 * Prop type definitions for AuthHeader.
 */
ImagePicker.propTypes = {
  callback: PropTypes.func.isRequired,
  className: PropTypes.string,
  handleUploading: PropTypes.func,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
  fileType: PropTypes.string,
  pathReference: PropTypes.string,
  tripId: PropTypes.number,
  useVersion: PropTypes.bool
};

export default ImagePicker;
