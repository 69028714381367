// @ts-nocheck
import ServiceCards from './index';
import { Box } from '@mui/material';
import {
  getBucketFileUrl,
  getBucketImageUrl
} from '../../../../../utils/resourcePathHelper';
import { pathReference, imageSize } from '../../../../../constants/files';
import * as P from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './ServiceCard.css';

// Map service types to their respective card components
const ServiceComponent = {
  Accommodations: ServiceCards.HotelCard,
  Activity: ServiceCards.ActivityCard,
  Car: ServiceCards.CarCard,
  Flights: ServiceCards.FlightCard,
  Restaurant: ServiceCards.RestaurantCard,
  Train: ServiceCards.TrainCard,
  Transfers: ServiceCards.TransferCard
};

/**
 * ServiceCard component renders a detailed view of a service within a trip itinerary.
 *
 * @param {Object} service - The service data containing details about the trip.
 * @param {number} tripid - The ID of the trip.
 * @param {number} tenantid - The ID of the tenant.
 * @param {function} onSelectService - Callback function to be called when a service is selected.
 *
 * @returns {JSX.Element} A JSX element representing the service card with appropriate details and actions.
 */

const ServiceCard = ({ service = {}, tripid, tenantid, onSelectService }) => {
  const { tripdetaileddayserviceid, servicefields, servicetype = {} } = service;
  const { iconref } = servicetype;

  const getAttachementUrl = (fileName = '') => {
    return getBucketFileUrl({
      fileName,
      pathReference: pathReference.DETAILED_DAY_SERVICE,
      tripId: tripid,
      tenantId: tenantid
    });
  };

  const getImageUrl = (fileName = '') => {
    return getBucketImageUrl({
      fileName,
      pathReference: pathReference.DETAILED_DAY_SERVICE,
      tripId: tripid,
      tenantId: tenantid,
      sizePrefix: imageSize.thumbnail.prefix
    });
  };

  const fields = servicefields?.reduce((acc, field) => {
    let values = field.options.map((opt) => opt?.value);

    // If only one option, store it as a single value instead of an array
    values = values.length > 1 ? values : values[0] || '';

    // Ensure 'MEALSINCLUDED' is always an array
    if (field.key === 'MEALSINCLUDED' && !Array.isArray(values)) {
      values = values ? [values] : [];
    }

    // Transform 'UPLOADIMAGES' field values to image URLs
    if (field.key === 'UPLOADIMAGES') {
      values = Array.isArray(values)
        ? values.map((value) => getImageUrl(value)).filter(Boolean)
        : values
          ? [getImageUrl(values)]
          : [];
    }

    // Transform 'UPLOADATTACHMENTS' field values to URLs
    if (field.key === 'UPLOADATTACHMENTS') {
      values = Array.isArray(values)
        ? values.map((fileName) => getAttachementUrl(fileName)).filter(Boolean)
        : values
          ? [getAttachementUrl(values)]
          : [];
    }

    acc[field.key] = values;
    return acc;
  }, {});

  const iconExists = (iconName) => {
    return typeof P[iconName] === 'object';
  };

  const ServiceTypeIcon = iconExists(iconref) ? P[iconref] : null;

  const CardComponent = ServiceComponent[servicetype?.name] ?? null;

  return (
    <Box
      className='service-view-card'
      id={`service-view-card-${tripdetaileddayserviceid}`}
      data-testid={`service-view-card-${tripdetaileddayserviceid}`}
      onClick={() => {
        onSelectService(service);
      }}
    >
      <Box className='service-view-card__header'>
        <Box className='service-view-card__header__servicetype'>
          {ServiceTypeIcon && (
            <div
              className={`service-view-card__header-icon service-view-card__header-icon-${iconref}`}
            >
              <ServiceTypeIcon size={21.33} />
            </div>
          )}
          <h3 className='service-view-card__header-servicetype-name'>
            {servicetype?.name || ''}
          </h3>
        </Box>
        <h5 className='service-view-card__header-service-name '>
          {fields?.TITLE || ''}
        </h5>
      </Box>

      {CardComponent && (
        <CardComponent
          servicefields={fields}
          servicetype={servicetype}
          tripdetaileddayserviceid={tripdetaileddayserviceid}
          tripid={tripid}
          tenantid={tenantid}
        />
      )}
    </Box>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.object,
  tripid: PropTypes.number,
  tenantid: PropTypes.number,
  onSelectService: PropTypes.func
};

export default ServiceCard;
