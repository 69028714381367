// @ts-nocheck
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const ServiceTypesButtonSkeleton = ({ id, ...rest }) => {
  return (
    <Box
      id={id}
      data-testid='trip-service-types-skeleton'
      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      {...rest}
    >
      <Skeleton
        key={'trip-service-types-skeleton-button'}
        variant='rectangular'
        width={170}
        height={42}
        sx={{
          borderBottom: '1px solid #EAECF0',
          backgroundColor: 'rgba(19, 97, 216, 0.6)',
          borderRadius: '8px'
        }}
      />
    </Box>
  );
};

ServiceTypesButtonSkeleton.propTypes = {
  id: PropTypes.string
};

export default ServiceTypesButtonSkeleton;
