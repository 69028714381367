// @ts-nocheck
import { Box, Typography } from '@mui/material';
import './DaySection.css';
import { TripDetailedDayPropTypes } from '../types';
import PropTypes from 'prop-types';
import { formatDateWithAddedDay } from '../../../../../utils/dateUtility';
import { useTranslation } from 'react-i18next';
import Editor from '../../../../Editor/Editor';
import { getLastListItem } from '../../../../../utils/common';
import { getOldCoverImageUrl } from '../../../../../utils/getCoverImageUrl';

const DaySection = ({
  date,
  daynumber,
  title,
  description,
  note,
  tenantSettings,
  children,
  ...data
}) => {
  const { i18n } = useTranslation();
  const dayDate = formatDateWithAddedDay(date, daynumber - 1, i18n.language);
  const coverImage = getLastListItem(data.tripdetaileddayimage);
  const { bodyfont, headingfont, color: highlightColor } = tenantSettings;
  const displayNote = false;
  return (
    <Box className='day-section' fontFamily={bodyfont}>
      <Box className='day-section__title-content'>
        <Box className='day-section__date-badge'>
          <label
            className='day-section__badge'
            style={{
              backgroundColor: highlightColor
            }}
          >{`Day ${daynumber}`}</label>
          <Typography
            className='day-section__date'
            style={{
              fontFamily: headingfont
            }}
          >
            {dayDate}
          </Typography>
        </Box>
        <Box className='day-section__content'>
          {title && (
            <Typography
              variant='h4'
              fontWeight='bold'
              className='day-section__title'
              style={{
                fontFamily: headingfont
              }}
            >
              {title}
            </Typography>
          )}
          {coverImage && (
            <img
              height='300'
              src={getOldCoverImageUrl(coverImage.imageurl)}
              alt={data.tripname}
              className='header__image'
            />
          )}
          {description && (
            <Editor
              id={`day-section__description-${daynumber}-${date}`}
              content={description}
              editable={false}
              isPreviewing={true}
              className='day-section__description'
              style={{
                fontFamily: bodyfont,
                fontSize: '0.75rem'
              }}
            />
          )}
          {note && displayNote && (
            <Editor
              id={`day-section__note-${daynumber}-${date}`}
              content={note}
              editable={false}
              isPreviewing={true}
              className='day-section__note'
              style={{
                fontFamily: bodyfont,
                fontSize: '0.75rem'
              }}
            />
          )}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

DaySection.propTypes = {
  ...TripDetailedDayPropTypes,
  date: PropTypes.any.isRequired,
  tenant: PropTypes.shape({
    tenantlogo: PropTypes.string,
    tenantphone: PropTypes.string,
    tenantname: PropTypes.string,
    tenanturl: PropTypes.string
  }),
  tenantSettings: PropTypes.shape({
    bodyfont: PropTypes.string,
    color: PropTypes.string,
    headingfont: PropTypes.string,
    name: PropTypes.string
  })
};

export default DaySection;
