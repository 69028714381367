// @ts-nocheck
import Header from '../../components/MainHeader/Header/Header';
import NavMenuBar from '../../components/NavMenu/NavMenuBar/NavMenuBar';
import MainHeaderSkeleton from '../../components/Skeleton/MainHeaderSkeleton/MainHeaderSkeleton';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../../redux/slices/user/user.js';
import { updateTenant } from '../../redux/slices/tenant/tenant.js';
import * as PropTypes from 'prop-types';
import './ShellLayout.css';
import { CalendarCheck, Books } from '@phosphor-icons/react';

const navItems = [
  {
    id: 3,
    title: 'Trips',
    icon: <CalendarCheck />,
    route: '/trips'
  },
  {
    id: 4,
    title: 'Library',
    icon: <Books />,
    route: '/library'
  }
];

/**
 * This is a reusable main layout component for the shell distribution.
 *
 * @namespace Layouts
 */

const ShellLayout = ({ id, children, headerData, isLoadingContent }) => {
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const sidebarRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const tenant = useSelector((state) => state.tenant);

  const handleResize = useCallback(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const serializedSessionData = localStorage.getItem('userSession');
    const serializedTenantData = localStorage.getItem('tenantData');
    const sessionData = JSON.parse(serializedSessionData) ?? {};
    const tenantData = JSON.parse(serializedTenantData) ?? {};

    const setupUserSessionData = () => {
      dispatch(updateUser(sessionData));
    };

    const setupUserTenantData = () => {
      dispatch(updateTenant(tenantData));
    };

    setupUserSessionData();
    setupUserTenantData();
  }, [dispatch]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (sidebarRef.current && typeof ResizeObserver === 'function') {
      resizeObserver.observe(sidebarRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [sidebarRef, handleResize]);

  return (
    <div id={id} className='shell-wrapper' data-testid='shell-wrapper'>
      <div className='shell-ribbon' data-testid='shell-ribbon' />
      <div
        className='shell-sidebar-wrapper'
        data-testid='shell-sidebar-wrapper'
        ref={sidebarRef}
      >
        <NavMenuBar
          id='navigation-menu'
          navItems={navItems}
          data={{
            logo: tenant.logo,
            tenantName: tenant.name,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email
          }}
        />
      </div>
      <main
        className='shell-content main$$'
        data-testid='shell-content'
        style={{
          marginLeft: sidebarWidth,
          width: `calc(100vw - ${sidebarWidth}px)`
        }}
      >
        <div
          className='shell-content-wrapper'
          data-testid='shell-content-wrapper'
        >
          {headerData &&
            (isLoadingContent ? (
              <MainHeaderSkeleton id='main-header-skeleton' />
            ) : (
              <>
                <Header data={headerData} id='main-header' />
              </>
            ))}
          <div
            className='shell-scrollable-content'
            data-testid='shell-scrollable-content'
          >
            {children}
          </div>
        </div>
      </main>
      {/* <div className='shell-footer-wrapper' data-testid='shell-footer-wrapper'>
        <Footer id='footer' />
      </div> */}
    </div>
  );
};

/**
 * Prop type definitions for ShellLayout.
 */
ShellLayout.propTypes = {
  children: PropTypes.node,
  headerData: PropTypes.object,
  id: PropTypes.string.isRequired,
  isLoadingContent: PropTypes.bool
};

export default ShellLayout;
