//@ts-nocheck
const mode = import.meta.env.MODE;
const env = import.meta.env;

//Global object for mapping global settings
const globalConfig = {
  isProduction: mode === 'production',
  isDevelop: mode === 'development',
  mode: mode,
  axios: {
    apiUrl: env.VITE_API_URL,
    requestTimeout: env.VITE_REQUEST_TIMEOUT
  },
  socket: {
    apiUrl: env.VITE_SOCKET_API_URL
  },
  maxImageSize: env.VITE_MAX_IMAGE_SIZE,
  maxFileSize: env.VITE_MAX_FILE_SIZE,
  maxPasswordLength: env.VITE_MAX_PASSWORD_LENGTH,
  muiLicense: env.VITE_MUI_LICENSE,
  launchDarklyKey: env.VITE_LAUNCH_DARKLY_SDK_KEY,
  eSignatureHost: env.VITE_ESIGNATURE_HOST,
  localBucket: env.VITE_LOCAL_BUCKET,
  reactScanIsEnabled: env.VITE_REACT_SCAN || false
};

export default globalConfig;
