// @ts-nocheck
import DeleteModal from '../../DeleteModal/DeleteModal';
import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorToast, SuccessToast } from '../../../../utils/alerts';
import { preventBubbling } from '../../../../utils/eventHelper';
import { deleteServiceType } from '../../../../services/servicetype/servicetype';
import * as PropTypes from 'prop-types';

/**
 * Functional React component for rendering a delete day service component.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Object} [props.data] - The data for the service type day.
 * @param {string} [props.id] - The ID for the delete day service component.
 * @param {bool} [props.isOpenModal] - Boolean value to indicate whether modal is open.
 * @param {Function} [props.updateIsOpen] - Function to update the open state.
 * @param {Function} [props.onDelete] - Function to remove the service from state.
 * @param {...any} props.rest - Additional props to be spread on the parent element.
 *
 * @returns {JSX.Element} React element representing the delete day service component.
 */
const DeleteDayService = ({
  data,
  id,
  isOpenModal,
  updateIsOpen,
  onDelete,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnSubmit = useCallback(
    async (event) => {
      preventBubbling(event);
      setIsSubmitting(true);
      if (!data) return;

      try {
        const response = await deleteServiceType(
          data.tripid,
          data.tripdetaileddayid,
          data.tripdetaileddayserviceid
        );

        if (response.error) {
          ErrorToast(t('errors:serviceTypeCouldNotBeDeleted'));
        } else {
          SuccessToast(t('trip:serviceTypeDeletedSuccessfully'));
          onDelete(data.tripdetaileddayserviceid); // Remove service from state
        }
      } catch (error) {
        ErrorToast(t('errors:serviceTypeCouldNotBeDeleted'));
      } finally {
        setIsSubmitting(false);
        updateIsOpen(false);
      }
    },
    [data, onDelete, t, updateIsOpen]
  );

  return (
    <Box id={id} data-testid='delete-service-type-day-component' {...rest}>
      <DeleteModal
        id='delete-service-type-day-modal-component'
        data-testid='delete-service-type-day-modal-component'
        isOpenModal={isOpenModal}
        title={t('deleteServiceDay')}
        onConfirm={handleOnSubmit}
        onCancel={() => {
          updateIsOpen(false);
        }}
        description={`${t('serviceDayDeletionConfirmation')} ${t(
          'common:thisActionCannotBeUndone'
        )}`}
        isSubmitting={isSubmitting}
      />
    </Box>
  );
};

DeleteDayService.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool,
  updateIsOpen: PropTypes.func
};

export default DeleteDayService;
