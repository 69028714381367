//@ts-nocheck

import { useEffect } from 'react';
import { socket } from '../socket';

/**
 * Custom hook to manage document socket connections
 * @param {string|null} documentId - The ID of the document being signed
 * @param {Function} onEventUpdate - Callback function to handle event updates
 * @returns {Object} socket - The socket instance
 */
export const useDocumentSocket = (documentId, onEventUpdate) => {
  useEffect(() => {
    if (!documentId) return;

    // Join document-specific room
    socket.emit('join_document', documentId);

    // Listen for document status updates
    socket.on(`document_event_${documentId}`, (event) => {
      onEventUpdate(event);
    });

    // Cleanup
    return () => {
      socket.off(`document_event_${documentId}`);
      socket.emit('leave_document', documentId);
    };
  }, [documentId, onEventUpdate]);

  return socket;
};
