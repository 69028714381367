// @ts-nocheck
import { CircularProgress, Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { XGButton } from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './DeleteActions.css';

/**
 * Render delete actions for the modal footer component.
 *
 * @param {function} onCancel - Callback function for cancel action
 * @param {function} onSubmit - Callback function for submit action
 * @param {boolean} isSubmitting - Flag indicating if form is currently submitting
 * @return {JSX.Element} The delete actions component
 */
const DeleteActions = ({ onCancel, onSubmit, isSubmitting }) => {
  const { t } = useTranslation(['common']);

  return (
    <Box
      className='delete-action-modal__footer'
      data-testid='delete-modal-actions'
    >
      <Box sx={{ position: 'relative', width: '50%' }}>
        <XGButton
          style={{
            width: '100%'
          }}
          data-testid='delete-action-cancel-button'
          id='delete-action-cancel-button'
          text={t('confirmDeleting')}
          type='error'
          size='large'
          variant='outlined'
          onClick={onSubmit}
          disabled={isSubmitting}
        />
        {isSubmitting && (
          <CircularProgress
            id='delete-actions-loader'
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Box>
      <XGButton
        data-testid='delete-action-confirm-button'
        id='delete-action-confirm-button'
        text={t('cancelDeleting')}
        type='primary'
        size='large'
        variant='contained'
        onClick={onCancel}
        disabled={isSubmitting}
      />
    </Box>
  );
};

DeleteActions.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
};

export default DeleteActions;
