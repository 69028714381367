// @ts-nocheck
import { Box } from '@mui/material';
import { Notebook, MapPin, Info, Clock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ServicePropTypes } from '../../types';
import Editor from '../../../../../Editor/Editor';
import { formatDate } from '../../../../../../utils/dateUtility';
import ImageSection from '../Components/ImageSection';
import AttachmentNotice from '../Components/AttachmentNotice';
import useComponentHeight from '../../../../../../hooks/useComponentHeight';
import IconSection from '../Components/IconSection';
import { ensureHttps } from '../../../../../../utils/urlHelper';

const HotelService = ({
  servicetype,
  servicefields,
  tripdetaileddayserviceid,
  tenantsettings,
  icon
}) => {
  const { ref, height } = useComponentHeight();
  const { t } = useTranslation();
  const columnFields = [
    'ADDRESS',
    'ROOMTYPE',
    'LINK',
    'HOTELNAME',
    'PHONENUMBER'
  ];
  const hasColumnFieldData = columnFields.some(
    (field) => !!servicefields[field]
  );

  return (
    <Box
      className='service-section'
      id={`service-section-hotel-${tripdetaileddayserviceid}`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
      ref={ref}
    >
      <Box className='service-section__icon-name'>
        <IconSection icon={icon} height={height} />
        <h3
          className='service-section__name'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {servicetype.name}
        </h3>
      </Box>

      <Box className='service-section__content'>
        {servicefields.TITLE && (
          <h5
            className='service-section__title'
            style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
          >
            {servicefields.TITLE}
          </h5>
        )}

        <Box className='service-section__dates'>
          {servicefields.CHECKINDATE && (
            <>
              <Clock />
              <p className='service-section__label-value'>
                <span>{t('Check In Date')}:</span>{' '}
                {formatDate(servicefields.CHECKINDATE, 'YYYY-MM-DD')}
              </p>
            </>
          )}
          {servicefields.CHECKOUTDATE && (
            <p className='service-section__label-value'>
              <span>{t('Check Out Date')}:</span>{' '}
              {formatDate(servicefields.CHECKOUTDATE, 'YYYY-MM-DD')}
            </p>
          )}
          {servicefields.NUMBEROFNIGHTS && (
            <p className='service-section__label-value'>
              <span>{t('Number of Nights')}:</span>{' '}
              {servicefields.NUMBEROFNIGHTS}
            </p>
          )}
        </Box>

        <ImageSection images={servicefields?.UPLOADIMAGES} />

        <Box className='service-section___column-info'>
          {(servicefields.HOTELNAME || servicefields.PHONE) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Notebook />
              </Box>
              <Box>
                {servicefields.HOTELNAME && (
                  <p className='service-section__label-value'>
                    <span>{t('Hotel Name')}:</span> {servicefields.HOTELNAME}
                  </p>
                )}
                {servicefields.PHONENUMBER && (
                  <p className='service-section__label-value'>
                    <span>{t('Contact Tel')}:</span> {servicefields.PHONENUMBER}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {servicefields.ADDRESS && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                <p className='service-section__label-value'>
                  <span>{t('Address')}:</span> {servicefields.ADDRESS}
                </p>
              </Box>
            </Box>
          )}

          {(servicefields.ROOMTYPE || servicefields.LINK) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Info />
              </Box>
              <Box>
                {servicefields.ROOMTYPE && (
                  <p className='service-section__label-value'>
                    <span>{t('Room Type')}:</span> {servicefields.ROOMTYPE}
                  </p>
                )}
                {servicefields.LINK && (
                  <p className='service-section__label-value'>
                    <a
                      href={ensureHttps(servicefields.LINK)}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {t('Website')}
                    </a>
                  </p>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          className={`service-section__description-notes ${hasColumnFieldData ? 'spacing' : ''}`}
        >
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-section-hotel-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-section__description'
              style={{
                fontFamily: tenantsettings?.bodyfont || 'inherit'
              }}
            />
          )}

          {servicefields?.CONFIRMATIONINFORMATION && (
            <Box className='service-section__confirmation'>
              <p>
                <span>{t('Confirmation #')}:</span>{' '}
                {servicefields.CONFIRMATIONINFORMATION}
              </p>
            </Box>
          )}

          <AttachmentNotice attachments={servicefields?.UPLOADATTACHMENTS} />
        </Box>
      </Box>
    </Box>
  );
};

HotelService.propTypes = ServicePropTypes;

export default HotelService;
