// @ts-nocheck
import globalConfig from '../config/globalConfig';
import { imageSize, imageRoute } from '../constants/files';

const isDevelop = globalConfig?.isDevelop ?? false;
const localBucket = globalConfig?.localBucket ?? '';

//Replace params to build files path
export const replaceParams = (params = {}) => {
  const { pathReference, tenantId, tripId } = params;

  return (
    imageRoute?.[pathReference]?.route
      ?.replaceAll('{tenantid}', tenantId)
      ?.replaceAll('{tripid}', tripId) ?? null
  );
};

//Generate image url from bucket based on image size prefixes(BL, TH, SM, MD, LD, etc).
export const getBucketImageUrl = (params = {}) => {
  const { fileName, sizePrefix } = params;
  const route = replaceParams(params);

  return `${isDevelop ? localBucket : ''}/${route}/${sizePrefix || imageSize.medium.prefix}-${fileName}`;
};

//Generate files url from bucket.
export const getBucketFileUrl = (params = {}) => {
  const { fileName } = params;
  const route = replaceParams(params);

  return `${isDevelop ? localBucket : ''}/${route}/${fileName}`;
};

//Generate a blob image from url
export const getFileFromUrl = async (url, filename, mimeType) => {
  try {
    let response = await fetch(url);

    if (!response.ok) {
      // Fallback image
      response = await fetch('/img/TH-placeholder.png');
    }

    if (!response.ok) return null;

    const bufferPromise = response.arrayBuffer();
    const contentType = response.headers.get('content-type') || mimeType;

    return new File([await bufferPromise], filename, { type: contentType });
  } catch (error) {
    return null;
  }
};

//Get best image size prefix
export const getBestSizePrefix = (width = 0) => {
  if (width < imageSize.small.size) {
    return 'TH';
  } else if (width < imageSize.medium.size) {
    return 'SM';
  } else if (width < imageSize.large.size) {
    return 'MD';
  } else {
    return 'MD'; // Default
  }
};

//Check if image is available
export const imageExistsInBucket = async (url, retries = 10, delay = 2000) => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url, {
        method: 'HEAD',
        cache: 'no-cache'
      });

      if (response.ok) return true;
    } catch (error) {
      console.error('Error on Image reintent ', error);
    }
    await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
  }
  return false;
};
