// @ts-nocheck

import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { XGButton } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { PlusCircle } from '@phosphor-icons/react';
import './AmenitiesPanel.css';
import { ErrorToast } from '../../../../utils/alerts';
import {
  deleteAmenity,
  getAmenities,
  updateAmenity
} from '../../../../services/amenity/amenity';
import AmenitiesTable from '../AmenitiesTable/AmenitiesTable';
import DeleteModal from '../../../Trip/DeleteModal/DeleteModal';

/**
 * Functional React component for rendering a trip additional content component
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {...any} props.rest - Additional props to be spread on the element
 *
 * @returns {JSX.Element} React element representing the trip additional content component
 */
const Amenities = () => {
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddAmenityFormVisible, setIsAddAmenityFormVisible] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteAmenityId, setDeleteAmenityId] = useState(null);

  const handleAddAmenityClick = () => {
    setIsAddAmenityFormVisible(true);
  };

  const handleDeleteAmenityConfirmation = async () => {
    try {
      await deleteAmenity(deleteAmenityId);
      setAmenitiesList((prev) =>
        prev.filter((amenity) => amenity.amenityid !== deleteAmenityId)
      );
    } catch (error) {
      ErrorToast(
        t('errors:amenityCouldNotBeDeleted', { reason: error.message })
      );
    } finally {
      setIsDeleteModalOpen(false);
      setDeleteAmenityId(null);
    }
  };

  const handleUpdateAmenity = async (amenity) => {
    try {
      setIsSubmitting(true);

      await updateAmenity(amenity.amenityid, amenity);

      // Update the amenities list with the updated amenity
      setAmenitiesList((prevList) =>
        prevList.map((item) =>
          item.amenityid === amenity.amenityid ? amenity : item
        )
      );
    } catch (error) {
      ErrorToast(
        t('errors:amenityCouldNotBeUpdated', { reason: error.message })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchAmenities = async () => {
      const amenities = await getAmenities();
      const list = (amenities?.data ?? []).sort(
        (a, b) => a?.amenityid - b?.amenityid
      );
      setAmenitiesList(list);
    };

    fetchAmenities();
  }, []);

  const handleUpdateSequence = (sourceAmenity, destinationAmenity) => {
    console.log('sourceAmenity', sourceAmenity);
    console.log('destinationAmenity', destinationAmenity);
  };

  const handleDeleteAmenity = async (amenityId) => {
    setIsDeleteModalOpen(true);
    setDeleteAmenityId(amenityId);
  };

  return (
    <>
      <Box className='global-amenities-box-container'>
        <Box style={{ paddingBottom: '24px' }}>
          <XGButton
            autoFocus
            id='button-add-amenity'
            text={t('addAmenity')}
            type='primary'
            size='large'
            variant='contained'
            onClick={handleAddAmenityClick}
            startIcon={<PlusCircle size={20} />}
          />
        </Box>
        <Box>
          <AmenitiesTable
            amenitiesList={amenitiesList}
            handleUpdateSequence={handleUpdateSequence}
            updateAmenity={handleUpdateAmenity}
            handleDelete={handleDeleteAmenity}
            isDropDisabled={isSubmitting}
            isAddAmenityFormVisible={isAddAmenityFormVisible}
            setAmenitiesList={setAmenitiesList}
            toggleVisibility={() => setIsAddAmenityFormVisible(false)}
          />
        </Box>
      </Box>
      <DeleteModal
        isOpenModal={isDeleteModalOpen}
        title={t('settings:deleteAmenity')}
        onConfirm={handleDeleteAmenityConfirmation}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setDeleteAmenityId(null);
        }}
        description={`${t('settings:deleteAmenityConfirmation')} ${t(
          'common:thisActionCannotBeUndone'
        )}`}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default Amenities;
