//@ts-nocheck

//Components
import ShellLayout from '../../layouts/ShellLayout/ShellLayout';
import shellHeaderBuilder from '../../utils/shellHeaderBuilder';
import Itinerary from '../../components/Trip/Itinerary/Itinerary';
import ItinerarySkeleton from '../../components/Skeleton/ItinerarySkeleton/ItinerarySkeleton';
import TripHeader from '../../components/Trip/TripHeader/TripHeader';
import TripHeaderActions from '../../components/Trip/TripHeaderActions/TripHeaderActions';
import PricingPanel from '../../components/Trip/Pricing/PrincingPanel/PrincingPanel';
import BookingsPanel from '../../components/Trip/Bookings/BookingsPanel/BookingsPanel';
import PassengersPanel from '../../components/Trip/Passengers/PassengersPanel/PassengersPanel';

//Redux Slices
import { createTrip } from '../../redux/slices/trip/trip';
import { updateServices } from '../../redux/slices/services/services';
import { updateStatuses } from '../../redux/slices/tripstatuses/tripstatuses';
import { updateDestinations } from '../../redux/slices/destinations/destinations';

//Services
import { getTrip, deleteTrip } from '../../services/trip/trip';
import { getDestinations } from '../../services/destination/destination';
import { getServiceTypes } from '../../services/servicetype/servicetype';
import { getTripStatuses } from '../../services/tripstatus/tripstatus';
import { getContentTypes } from '../../services/contenttype/contenttype';
import { updateContentTypes } from '../../redux/slices/contenttypes/contenttypes';

// Other dependencies
import { ErrorToast } from '../../utils/alerts';
import { ListMagnifyingGlass, Trash } from '@phosphor-icons/react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { XGTabs, XGButton } from '@xg-cl/xg-cl';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import './Trip.css';

import GeneratePdfOptions from '../../components/Trip/TripHeaderActions/TripDownloadPdf';
/**
 * This is the specific trip page component.
 *
 * @namespace Pages
 */

const Trip = ({ id }) => {
  const { tripId } = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(['trip', 'common']);
  const [isTripLoading, setIsTripLoading] = useState(true);
  const [didTripFetchSucceed, setDidTripFetchSucceed] = useState(true);

  if (!didTripFetchSucceed) {
    navigate('/trips');
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tripPromise = getTrip(tripId);
        const destinationsPromise = getDestinations();
        const servicesPromise = getServiceTypes();
        const tripStatusesPromise = getTripStatuses();
        const libraryContentTypesPromise = getContentTypes();

        const [
          tripResult,
          destinationsResult,
          servicesResult,
          tripStatusesResult,
          libraryContentTypesResult
        ] = await Promise.allSettled([
          tripPromise,
          destinationsPromise,
          servicesPromise,
          tripStatusesPromise,
          libraryContentTypesPromise
        ]);

        if (
          tripResult.status === 'fulfilled' &&
          Object.keys(tripResult?.value?.data).length > 0
        ) {
          const tripdays = tripResult.value?.data?.tripday ?? [];
          const tripcontent = tripResult.value?.data?.tripcontent ?? [];

          if (Array.isArray(tripdays)) {
            const newTripdays = tripdays.map((day, index) => ({
              ...day,
              isSelected: index === 0
            }));
            const newTripcontent = tripcontent.map((item, index) => ({
              ...item,
              isSelected: index === 0
            }));

            tripResult.value.data.tripday = newTripdays;
            tripResult.value.data.tripcontent = newTripcontent;

            dispatch(createTrip(tripResult.value.data));
          } else {
            setDidTripFetchSucceed(false);
          }
        } else {
          setDidTripFetchSucceed(false);
        }

        if (destinationsResult.status === 'fulfilled') {
          dispatch(updateDestinations(destinationsResult?.value?.data || []));
        }

        if (servicesResult.status === 'fulfilled') {
          dispatch(updateServices(servicesResult?.value?.data || []));
        }

        if (tripStatusesResult.status === 'fulfilled') {
          dispatch(updateStatuses(tripStatusesResult?.value?.data || []));
        }

        if (libraryContentTypesResult.status === 'fulfilled') {
          dispatch(
            updateContentTypes(libraryContentTypesResult?.value?.data || [])
          );
        }
      } catch (error) {
        setDidTripFetchSucceed(false);
      } finally {
        setIsTripLoading(false);
      }
    };
    fetchData();
  }, [tripId, dispatch]);

  const openTab = () => {
    window.open(`/itineraries/${tripId}`, '_blank');
  };

  const handleDeleteTripClick = async () => {
    try {
      await deleteTrip(tripId);
      navigate('/trips');
    } catch (error) {
      ErrorToast(t('errors:tripCouldNotBeDeleted', { reason: error.message }));
    }
  };

  const HeaderActions = () => {
    return (
      <>
        <div className='trip-header-actions'>
          <GeneratePdfOptions tripId={tripId} />

          <XGButton
            startIcon={<Trash width={23} />}
            data-testid='itinerary-delete-button'
            id='itinerary-delete-button'
            text={t('common:delete')}
            type='secondary'
            size='large'
            variant='outlined'
            onClick={handleDeleteTripClick}
            style={{
              padding: '8px 14px'
            }}
          />

          <TripHeaderActions id='trip-header-actions' />

          <XGButton
            startIcon={<ListMagnifyingGlass width={23} color='#fff' />}
            data-testid='itinerary-preview-button'
            id='itinerary-preview-button'
            text={t('common:preview')}
            type='primary'
            size='large'
            variant='contained'
            onClick={openTab}
          />
        </div>
      </>
    );
  };

  const mainHeader = shellHeaderBuilder({
    title: <TripHeader id='trip-header' />,
    cta: <HeaderActions />
  });

  const tripTabs = [
    {
      title: t('itinerary'),
      content: <>{!isTripLoading && <Itinerary id='trip-itinerary' />}</>
    },
    {
      title: t('pricing'),
      content: <PricingPanel id='trip-pricing-panel' />
    },
    {
      title: t('passengers'),
      content: <PassengersPanel />
    },
    {
      title: t('bookings'),
      content: <BookingsPanel />
    }
  ];

  return (
    <div id={id} data-testid='trip-page'>
      <ShellLayout
        id='main-trip-header'
        data-testid='trip-shell'
        headerData={mainHeader}
        isLoadingContent={isTripLoading}
      >
        {!isTripLoading ? (
          <div className='trip-wrapper'>
            <XGTabs id='trip-tabs' tabs={tripTabs} className='trip-tab-panel' />
          </div>
        ) : (
          <ItinerarySkeleton id='itinerary-skeleton' />
        )}
      </ShellLayout>
    </div>
  );
};

Trip.propTypes = {
  id: PropTypes.string.isRequired
};

export default Trip;
