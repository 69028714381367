// @ts-nocheck
import AIGenerateButton from '../../AIGenerateButton/AIGenerateButton';
import CircularProgress from '@mui/material/CircularProgress';
import DayHeaderServiceType from '../DayHeaderServiceType/DayHeaderServiceType';
import Editor from '../../Editor/Editor';
import InlineEditor from '../../InlineEditor/InlineEditor';
import inputRules from '../../../constants/inputRules';
import ImagePicker from '../../../components/Upload/ImagePicker/ImagePicker';
import { fileType, pathReference } from '../../../constants/files';
import { getOldCoverImageUrl } from '../../../utils/getCoverImageUrl';
import UpdateDayContent from '../UpdateDayContent/UpdateDayContent';
import NotAllowedToDrop from '../../../components/Trip/Library/NotAllowedToDrop/NotAllowedToDrop';
import DetailedDayLineItems from '../DetailedDayLineItems/DetailedDayLineItems';
import { Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import { add, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { XGChip, XGButton } from '@xg-cl/xg-cl';
import { parseISODateWithoutOffset } from '../../../utils/dateUtility';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ErrorToast, SuccessToast } from '../../../utils/alerts';
import { Droppable } from 'react-beautiful-dnd';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import {
  cleanDraggableAction,
  addShouldLibraryReload
} from '../../../redux/slices/library/library';
import {
  countServiceTypes,
  getServiceTypeIcon
} from '../../../utils/serviceTypeHelper';
import { editDay, updateDayImage } from '../../../redux/slices/trip/trip';
import { updateTripDay, addTripDayImage } from '../../../services/trip/trip';
import { saveContentToLibrary } from '../../../services/library/content';
import { getLastListItem } from '../../../utils/common';
import {
  TRIP_DAY_CONTENT_DROPPABLE_ID,
  UPDATEDAYCONTENT
} from '../../../constants/dragAndDrop';
import * as PropTypes from 'prop-types';
import './DetailedDay.css';
import '../../Draggable/Draggable.css';

/**
 * Functional React component for rendering a custom trip detailed day content.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {String} [props.id] - The ID for the trip detailed day component.
 * @param {Object} [props.data] - Object containing the data to build the trip detailed day content.
 * @param {String} [props.hideSaveToLibrary] - Flag that if true hides save to library button
 * @param {Func} [props.updateDay] - The method to be used for updating days. Default will be updateTripDay
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed day.
 */

const DetailedDay = ({
  id,
  data,
  hideSaveToLibrary = false,
  updateDay = updateTripDay,
  ...rest
}) => {
  const { t } = useTranslation(['trip', 'common', 'library']);
  const {
    description,
    note,
    lineitem,
    title,
    daynumber,
    startdate,
    tripdayimage,
    tripdayid,
    tripid
  } = data || {};
  const tenant = useSelector((state) => state.tenant);
  const storedTripData = useSelector((state) => state.trip);
  const contentTypes = useSelector((state) => state.contenttypes);
  const dispatch = useDispatch();
  const selectedDay = storedTripData?.tripday.find((day) => day.isSelected);
  const ldClient = useLDClient();
  const { ShowLineItems } = useFlags();

  //Status definitions
  const [isUpdatingDay, setIsUpdatingDay] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isEditorPreviewing, setIsEditorPreviewing] = useState(true);
  const [isNoteEditorPreviewing, setIsNoteEditorPreviewing] = useState(true);
  const [isEditorDisabled, setIsEditorDisabled] = useState(false);
  const [isSubmittingToLibrary, setIsSubmittingToLibrary] = useState(false);
  const [tripDayDescription, setTripDayDescription] = useState(description);
  const [markdown, setMarkdown] = useState(description);
  const [isUpdatingDayModalOpen, setIsUpdatingDayModalOpen] = useState(false);
  const [actionDraggableId, setActionDraggableId] = useState(null);
  const [internalNote, setInternalNote] = useState(note);
  const [internalNoteMarkdown, setInternalNoteMarkdown] = useState(note);
  const libItems = useSelector((state) => state.library?.items ?? []);
  const dayImage = getLastListItem(tripdayimage);
  const isDragging = useSelector((state) => state.library?.isDragging ?? false);
  const isDayContentDraggableDisabled = useSelector(
    (state) => state.library?.itinerary?.isDayContentDraggableDisabled ?? false
  );

  const draggableAction = useSelector(
    (state) => state.library?.itinerary?.draggableAction ?? null
  );

  const servicesCount = countServiceTypes(lineitem);
  const incomingDate = startdate && parseISODateWithoutOffset(startdate);

  const date =
    startdate && add(new Date(incomingDate), { days: daynumber - 1 });

  const dayOfWeekName = date
    ? date
        .toLocaleDateString('en-US', {
          weekday: 'long'
        })
        .toLowerCase()
    : '';

  const serviceDate =
    date &&
    `${t(dayOfWeekName, { ns: 'common' })} · ${format(
      new Date(date),
      `${t('dateFormat', { ns: 'common' })}`
    )}`;

  //Cancels editor
  const onCancelEditor = () => {
    setMarkdown(description);
    setTripDayDescription(description);
  };

  //Cancels note editor
  const onCancelNoteEditor = () => {
    setInternalNoteMarkdown(note);
    setInternalNote(note);
  };

  //Saves editor content
  const onSaveEditor = async () => {
    try {
      const { data } = await updateDay(tripdayid, {
        description: `${markdown}`
      });

      if (data) {
        dispatch(editDay(data));
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  //Saves note content
  const onSaveNote = async () => {
    try {
      const { data } = await updateDay(tripdayid, {
        note: `${internalNoteMarkdown}`
      });

      if (data) {
        dispatch(editDay(data));
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  //Save trip day content to library
  const saveDayContentToLibrary = async () => {
    const { title, tripdayimage } = selectedDay;

    const dayContentType = contentTypes?.find(
      (contentType) => contentType?.code === 'day'
    );

    const contentData = {
      contenttypeid: parseInt(dayContentType?.contenttypeid ?? 0),
      title,
      description: `${markdown}`,
      note: internalNote?.trim(),
      images: tripdayimage
    };

    try {
      setIsSubmittingToLibrary(true);

      const { data } = await saveContentToLibrary(contentData);

      if (data) {
        dispatch(addShouldLibraryReload({ shouldLibraryReload: true }));
        SuccessToast(t('dayContentAddedToLibrary'));
      } else {
        ErrorToast(t('errors:dayContentCouldNotBeAddedToLibrary'));
      }
    } catch (err) {
      ErrorToast(t('errors:dayContentCouldNotBeAddedToLibrary'));
    } finally {
      setIsSubmittingToLibrary(false);
    }
  };

  //Updates trip day title
  const updateTripDayTitle = async (newTripDayTitle) => {
    try {
      if (newTripDayTitle?.trim() !== title) {
        const { data } = await updateDay(tripdayid, {
          title: newTripDayTitle?.trim()
        });

        if (data) {
          dispatch(editDay(data));
        }
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  //Updates trip day cover image
  const updateDayCoverImage = async (newImageUrl) => {
    const { data: dayData } = await addTripDayImage(tripdayid, {
      imageUrl: newImageUrl
    });

    if (dayData) {
      const { tripdayimageid, seq, imageurl } = dayData;

      dispatch(
        updateDayImage({
          tripdayid,
          tripdayimage: [
            {
              tripdayimageid,
              seq,
              imageurl
            }
          ]
        })
      );

      return { data: true };
    }

    return { data: false };
  };

  const updateTripDayContent = async (newData = {}) => {
    try {
      const { data } = await updateDay(tripdayid, {
        ...newData
      });

      if (data) {
        dispatch(editDay(data));
      }
    } catch (err) {
      ErrorToast(t('errors:tripCouldNotBeUpdated'));
    }
  };

  const handleUpdateDayContentSubmit = async () => {
    setIsUpdatingDay(true);

    if (actionDraggableId) {
      const foundLibItem = libItems.find(
        (item) => item?.contentid === parseInt(actionDraggableId)
      );

      if (foundLibItem) {
        await updateTripDayContent({
          title: foundLibItem?.title ?? '',
          description: foundLibItem?.description ?? '',
          note: foundLibItem?.note ?? ''
        });

        const newImages = foundLibItem?.contentimage ?? [];

        if (newImages && newImages.length && newImages[0]?.imageurl) {
          await updateDayCoverImage(newImages[0]?.imageurl);
        }
      }
    }
    dispatch(cleanDraggableAction());
    setIsUpdatingDayModalOpen(false);
    setIsUpdatingDay(false);
    setActionDraggableId(null);
  };

  const onPreviewDescription = (value = false) => {
    if (!value) {
      setIsEditorPreviewing(false);
      setIsNoteEditorPreviewing(true);
    } else {
      setIsEditorPreviewing(true);
    }
  };

  const onPreviewNote = (value = false) => {
    if (!value) {
      setIsEditorPreviewing(true);
      setIsNoteEditorPreviewing(false);
    } else {
      setIsNoteEditorPreviewing(true);
    }
  };

  //On react 18 we need to load Droppable element after the component is mounted otherwise it won't work
  //See: https://github.com/atlassian/react-beautiful-dnd/issues/2399
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (tripdayid) {
      setTripDayDescription(description || '');
      setMarkdown(description || '');
      setIsEditorPreviewing(true);
      setIsNoteEditorPreviewing(true);
      setInternalNoteMarkdown(note || '');
      setInternalNote(note || '');
    }
  }, [tripdayid, description, note]);

  useEffect(() => {
    if (tenant && ldClient) {
      ldClient?.identify({ key: `${tenant?.tenantid}` });
    }
  }, [ldClient, tenant]);

  //This effect acts as a listener, responding to actions dispatched by the DragDropContext to Redux.
  //This component is capable of identifying these actions and performing its own corresponding actions.
  useEffect(() => {
    const { type = null, draggableId = '' } = draggableAction ?? {};

    if (type === UPDATEDAYCONTENT) {
      const foundTripId = draggableId.split('.')[1] ?? '';
      setIsUpdatingDayModalOpen(true);
      setActionDraggableId(foundTripId);
    }
  }, [draggableAction]);

  useEffect(() => {
    setInternalNote(note ?? '');
  }, [note]);

  return (
    <>
      <Box
        id={id}
        className={`trip-detailed-day-wapper`}
        data-testid='trip-detailed-day'
        {...rest}
      >
        {isMounted ? (
          <Droppable
            droppableId={TRIP_DAY_CONTENT_DROPPABLE_ID}
            isDropDisabled={isDayContentDraggableDisabled}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`
                    tripday__droppable-container 
                    ${(snapshot.isDraggingOver && !isDayContentDraggableDisabled) || (isDragging && !isDayContentDraggableDisabled) ? 'tripday__droppable-container--dragging-over' : ''}`}
              >
                <div className='tripday__droppable-box' />

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          <></>
        )}
        {isDayContentDraggableDisabled && (
          <NotAllowedToDrop id={'detailed-day-not-allowed-to-drop'} />
        )}
        <Box
          id='trip-detailed-day-header'
          className={`trip-detailed-day-header droppable-padding `}
          data-testid='trip-detailed-day-header'
        >
          <div
            style={{
              width: '100%'
            }}
          >
            <ImagePicker
              callback={updateDayCoverImage}
              id={`image-picker-component`}
              url={getOldCoverImageUrl(dayImage?.imageurl)}
              tripId={tripid}
              pathReference={pathReference.DAY_ITENERARY}
              fileType={fileType.IMAGE}
            />
          </div>

          <Box className='trip-detailed-day-header-container'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='text-md-semibold service-content-title'>
                {`${t('day')} #${daynumber}${
                  title && title !== '' ? ` - ` : ''
                }`}
              </span>
              <InlineEditor
                action={updateTripDayTitle}
                className='text-md-semibold service-content-title'
                id='tripname-inline-editor'
                initialValue={title}
                isValueRequired={false}
                maxTextLength={inputRules.maxTitleLength}
                placeholder={`${t('enterTitle')}`}
                style={{ maxWidth: '750px' }}
              />

              {serviceDate && (
                <XGChip
                  id={`${id}-date-chip`}
                  data-testid='resevation-day-date-chip'
                  text={serviceDate}
                  type={'default'}
                  variant='outlined'
                  size='small'
                />
              )}
            </div>

            {ShowLineItems && (
              <div className='day-header-service-type-container'>
                {servicesCount &&
                  servicesCount.map((item) => {
                    const serviceType = {
                      ...item,
                      icon: getServiceTypeIcon(item?.code),
                      title:
                        t(`serviceType${item?.code}`) !==
                        `serviceType${item?.code}`
                          ? t(`serviceType${item?.code}`)
                          : item?.description
                    };

                    return (
                      <DayHeaderServiceType
                        key={serviceType.id}
                        id={`${serviceType.id}-service-cards`}
                        data={serviceType}
                      />
                    );
                  })}
              </div>
            )}
          </Box>

          <div className='detailed-day-editor-description__container'>
            {tripDayDescription && tripDayDescription !== '' && (
              <p className='MuiTypography-root MuiTypography-body2'>
                <span className='w-50'>{t('trip:description')} </span>
              </p>
            )}

            <Editor
              editable={true}
              isPreviewing={isEditorPreviewing}
              content={tripDayDescription}
              id={`detailed-day-editor-description-${tripdayid}`}
              setDescription={() => {}}
              setMarkdown={setMarkdown}
              setIsPreviewing={onPreviewDescription}
              showActions={true}
              onCancelAction={onCancelEditor}
              onSaveAction={onSaveEditor}
              placeholder={`${t('enterDescription')}`}
              generatePrompt={
                !isEditorPreviewing ? (
                  <div>
                    <AIGenerateButton
                      id='ai-generate-trip-description-button'
                      setMarkdown={setMarkdown}
                      setTripDescription={setTripDayDescription}
                      editorContent={markdown}
                      setIsEditorDisabled={setIsEditorDisabled}
                      disableGenerate={true}
                    />
                  </div>
                ) : null
              }
              isEditorDisabled={isEditorDisabled}
            />
          </div>

          <div
            className='detailed-day-editor-description__container'
            style={{ marginTop: '32px' }}
          >
            {internalNote && internalNote !== '' && (
              <p className='MuiTypography-root MuiTypography-body2'>
                <span className='w-50'>{t('trip:internalNotes')} </span>
              </p>
            )}

            <Editor
              editable={true}
              isPreviewing={isNoteEditorPreviewing}
              content={internalNote}
              id={`detailed-day-editor-note-${tripdayid}`}
              setDescription={() => {}}
              setMarkdown={setInternalNoteMarkdown}
              setIsPreviewing={onPreviewNote}
              showActions={true}
              onCancelAction={onCancelNoteEditor}
              onSaveAction={onSaveNote}
              placeholder={`${t('enterInternalNotes')}`}
              generatePrompt={null}
              isEditorDisabled={false}
            />
          </div>

          {!hideSaveToLibrary && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <XGButton
                  data-testid='save-to-library-button'
                  id='save-to-library-button'
                  text={t('library:saveToLibrary')}
                  type='primary'
                  size='medium'
                  variant='contained'
                  onClick={saveDayContentToLibrary}
                  disabled={isSubmittingToLibrary}
                />
                {isSubmittingToLibrary && (
                  <CircularProgress
                    id='save-to-library-loader'
                    size={24}
                    sx={{
                      color: blue[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {ShowLineItems && (
        <DetailedDayLineItems id='detailed-day-line-items' data={data} />
      )}

      {isUpdatingDayModalOpen && (
        <UpdateDayContent
          id='update-day-content-component'
          isOpenModal={isUpdatingDayModalOpen}
          updateIsOpen={(isOpenModal) => {
            setIsUpdatingDayModalOpen(isOpenModal);
            dispatch(cleanDraggableAction());
          }}
          onSubmit={handleUpdateDayContentSubmit}
          isSubmitting={isUpdatingDay}
        />
      )}
    </>
  );
};

DetailedDay.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  hideSaveToLibrary: PropTypes.bool,
  onAction: PropTypes.func,
  editingServiceId: PropTypes.number,
  updateDay: PropTypes.func
};

export default DetailedDay;
