import request from '../request';
import axios from 'axios';

export const getPresignedUrl = (data = {}) =>
  request.post('uploads/presign-url', data).then((res) => res);

// @ts-ignore
export const uploadToBucket = (presignedImage, file = {}) => {
  //This setting disables the browser from sending cookies and credentials with cross-site requests
  axios.defaults.withCredentials = false;

  return axios
    .put(presignedImage.data.presignedUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Cache-Control': 'no-cache'
      }
    })
    .then((res) => res);
};
