// @ts-nocheck
import { Box } from '@mui/material';
import { Clock, MapPin, CheckCircle, Info } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ServicePropTypes } from '../../types';
import Editor from '../../../../../Editor/Editor';
import { formatDateTime } from '../../../../../../utils/dateUtility';
import ImageSection from '../Components/ImageSection';
import AttachmentNotice from '../Components/AttachmentNotice';
import useComponentHeight from '../../../../../../hooks/useComponentHeight';
import IconSection from '../Components/IconSection';

const TrainService = ({
  servicetype,
  servicefields,
  tripdetaileddayserviceid,
  tenantsettings,
  icon
}) => {
  const { ref, height } = useComponentHeight();
  const { t } = useTranslation();
  const columnFields = [
    'TRAINCOMPANY',
    'TRAINNUMBER',
    'ARRIVALADDRESS',
    'DEPARTUREADDRESS',
    'ARRIVALDATE',
    'DEPARTUREDATE',
    'ARRIVALTIME',
    'DEPARTURETIME'
  ];
  const hasColumnFieldData = columnFields.some(
    (field) => !!servicefields[field]
  );

  return (
    <Box
      className='service-section'
      id={`service-section-train-${tripdetaileddayserviceid}`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
      ref={ref}
    >
      <Box className='service-section__icon-name'>
        <IconSection icon={icon} height={height} />
        <h3
          className='service-section__name'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {servicetype.name}
        </h3>
      </Box>

      <Box className='service-section__content'>
        {servicefields.TITLE && (
          <h5
            className='service-section__title'
            style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
          >
            {servicefields.TITLE}
          </h5>
        )}

        <ImageSection images={servicefields?.UPLOADIMAGES} />

        <Box className='service-section___column-info'>
          {(servicefields.TRAINCOMPANY || servicefields.TRAINNUMBER) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Info />
              </Box>
              <Box>
                {servicefields.TRAINCOMPANY && (
                  <p className='service-section__label-value'>
                    <span>{t('Train Company')}:</span>{' '}
                    {servicefields.TRAINCOMPANY}
                  </p>
                )}
                {servicefields.TRAINNUMBER && (
                  <p className='service-section__label-value'>
                    <span>{t('Train Number')}:</span>{' '}
                    {servicefields.TRAINNUMBER}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields.DEPARTUREADDRESS || servicefields.ARRIVALADDRESS) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                {servicefields.DEPARTUREADDRESS && (
                  <p className='service-section__label-value'>
                    <span>{t('Leaving From')}:</span>{' '}
                    {servicefields.DEPARTUREADDRESS}
                  </p>
                )}
                {servicefields.ARRIVALADDRESS && (
                  <p className='service-section__label-value'>
                    <span>{t('Arriving To')}:</span>{' '}
                    {servicefields.ARRIVALADDRESS}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields.DEPARTUREDATE ||
            servicefields.DEPARTURETIME ||
            servicefields.ARRIVALTIME ||
            servicefields.ARRIVALDATE) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Clock />
              </Box>
              <Box>
                {servicefields.DEPARTUREDATE && servicefields.DEPARTURETIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Departure Time')}:</span>{' '}
                    {formatDateTime(
                      servicefields.DEPARTUREDATE,
                      servicefields.DEPARTURETIME
                    )}
                  </p>
                )}
                {servicefields.ARRIVALDATE && servicefields.ARRIVALTIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Arrival Time')}:</span>{' '}
                    {formatDateTime(
                      servicefields.ARRIVALDATE,
                      servicefields.ARRIVALTIME
                    )}
                  </p>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          className={`service-section__description-notes ${hasColumnFieldData ? 'spacing' : ''}`}
        >
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-section-train-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-section__description'
              style={{
                fontFamily: tenantsettings?.bodyfont || 'inherit'
              }}
            />
          )}

          {servicefields?.CONFIRMATIONINFORMATION && (
            <Box className='service-section__confirmation'>
              <p>
                <span>{t('Confirmation #')}:</span>{' '}
                {servicefields.CONFIRMATIONINFORMATION}
              </p>
            </Box>
          )}

          <AttachmentNotice attachments={servicefields?.UPLOADATTACHMENTS} />

          {servicefields?.MEALSINCLUDED && (
            <Box className='service-section__include-values'>
              {servicefields?.MEALSINCLUDED?.map((meal, index) => (
                <Box
                  className='service-section__include-values__item'
                  key={`meals-included-${meal.toLowerCase()}-${index}`}
                >
                  <CheckCircle color='rgba(56, 211, 133, 1)' />
                  <p>{meal}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

TrainService.propTypes = ServicePropTypes;

export default TrainService;
