// @ts-nocheck
import { useState, useRef, useCallback } from 'react';
import { Box, Checkbox, IconButton, Switch } from '@mui/material';
import { XGTextarea, XGInput, XGButton } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { ErrorToast } from '../../../utils/alerts';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useClickOutside from '../../../hooks/useClickOutside';
import {
  createAmenity,
  updateAmenity
} from '../../../services/amenity/amenity';
import { DotsSixVertical, Trash } from '@phosphor-icons/react';
/**
 * Default state for a new amenity.
 */
const defaultAmenityState = {
  amenityid: null,
  name: '',
  description: '',
  isincluded: null,
  isdefault: false
};

/**
 * Component for adding a new amenity row.
 *
 *
 * @namespace Components
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isVisible - Determines if the row is visible.
 * @param {Function} props.setAmenitiesList - Function to update the list of amenities.
 * @param {Function} props.toggleVisibility - Function to toggle the visibility of the row.
 * @param {Object} props.amenityToEdit - Optional amenity object to edit instead of creating a new one.
 *
 * @returns {JSX.Element|null} A JSX element representing the amenity row or null if not visible.
 */
const AddAmenityRow = ({
  isVisible,
  setAmenitiesList,
  toggleVisibility,
  amenityToEdit
}) => {
  const { t } = useTranslation(['trip', 'common', 'settings', 'errors']);
  const componentRef = useRef(null);
  const [newAmenity, setNewAmenity] = useState(() => {
    if (amenityToEdit) {
      return {
        amenityid: amenityToEdit.amenityid,
        name: amenityToEdit.name || '',
        description: amenityToEdit.description || '',
        isincluded: amenityToEdit.isincluded,
        isdefault: amenityToEdit.isdefault || false
      };
    }
    return defaultAmenityState;
  });

  /**
   * Handles changes to the amenity input fields.
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event.
   */
  const handleNewAmenityChange = (e) => {
    const { name, value } = e.target;
    const newValues = {
      [name]: value
    };
    setNewAmenity((prev) => ({ ...prev, ...newValues }));
  };

  const handleChangeSelect = (e) => {
    const { name, checked } = e.target;

    let newIsIncluded = newAmenity.isincluded;

    if (name === 'isincluded') {
      // If isincluded is checked, set isincluded to true
      newIsIncluded = checked ? true : null;
    } else if (name === 'isexcluded') {
      // If isexcluded is checked, set isincluded to false
      newIsIncluded = checked ? false : null;
    }

    const newValues = {
      isincluded: newIsIncluded
    };

    setNewAmenity((prev) => ({ ...prev, ...newValues }));
  };

  const handleDefaultChange = (e) => {
    const { checked } = e.target;
    setNewAmenity((prev) => ({ ...prev, isdefault: checked }));
  };

  /**
   * Handles the debounced creation or update of an amenity.
   */
  const handleAddAmenity = useCallback(async () => {
    const isIncluded = newAmenity.isincluded === true;

    if (newAmenity.name?.trim() === '') {
      ErrorToast(t('errors:amenityNameIsRequired'));
      return;
    }

    try {
      if (newAmenity.amenityid) {
        // Update existing amenity
        await updateAmenity(newAmenity.amenityid, {
          name: newAmenity.name,
          description: newAmenity.description,
          isincluded: newAmenity.isincluded,
          isdefault: newAmenity.isdefault
        });

        // Update the amenities list with the updated amenity
        setAmenitiesList((prev) =>
          prev.map((item) =>
            item.amenityid === newAmenity.amenityid ? newAmenity : item
          )
        );

        toggleVisibility(false);
      } else {
        // Create new amenity
        const result = await createAmenity({
          name: newAmenity.name,
          description: newAmenity.description,
          isincluded: isIncluded,
          isdefault: newAmenity.isdefault
        });
        toggleVisibility(false);
        setAmenitiesList((prev) => [
          {
            ...newAmenity,
            amenityid: result.data.amenityid
          },
          ...prev
        ]);
        setNewAmenity(defaultAmenityState);
      }
    } catch (error) {
      ErrorToast(t('errors:amenityCouldNotBeSaved', { reason: error.message }));
    }
  }, [newAmenity, setNewAmenity, toggleVisibility, setAmenitiesList, t]);

  const hideForm = () => {
    setNewAmenity(defaultAmenityState);
    toggleVisibility(false);
  };

  // Hides form and reset state if user hits escape key
  useEscapeKey(hideForm);
  // Hides form and reset state if user clicks outside
  useClickOutside([componentRef], hideForm);

  if (!isVisible) return null;

  const isDefault = newAmenity.isdefault === true;
  const isIncluded = newAmenity.isincluded === true;
  const isExcluded = newAmenity.isincluded === false;

  return (
    <table className='amenities-table-columns'>
      <tbody>
        <tr ref={componentRef}>
          <td>
            <div className='cell-content'>
              <DotsSixVertical
                className='passengersfields_icon'
                color='#344054'
                size={22}
                style={{ marginRight: '8px' }}
              />
              <Checkbox
                id='add-amenity-default-checkbox'
                name='debounce'
                checked={isDefault}
                onChange={handleDefaultChange}
                color='primary'
              />
            </div>
          </td>
          <td>
            <XGInput
              id='add-amenity-name-input'
              name='name'
              placeholder={`${t('settings:enterTitle')}`}
              value={newAmenity.name}
              onChange={handleNewAmenityChange}
              className={'amenities-add-amenity-form__name'}
              autoFocus
              type='text'
              inputProps={{
                maxLength: 100
              }}
            />
            <div className='amenities-description__row-label'>
              <span className='text-sm-medium'>
                {newAmenity.name?.length || 0}/100
              </span>
            </div>
            <div className='amenities-description__row'>
              <div className='amenities-description__row-input'>
                <XGTextarea
                  id='add-amenity-description-input'
                  name='description'
                  placeholder={`${t('settings:enterDescription')}`}
                  value={newAmenity.description}
                  onChange={handleNewAmenityChange}
                  inputProps={{
                    maxLength: 200
                  }}
                  minRows={2}
                  maxRows={2}
                  fullWidth
                />
              </div>
              <div className='amenities-description__row-label'>
                <span className='text-sm-medium'>
                  {newAmenity.description?.length || 0}
                  /200
                </span>
              </div>
            </div>
            <Box className='amenities-table-add-actions'>
              <XGButton
                data-testid='action-cancel-button'
                id='action-cancel-button'
                text={t('common:cancel')}
                type='secondary'
                size='medium'
                variant='outlined'
                onClick={hideForm}
              />
              <XGButton
                data-testid='action-save-button'
                id='action-save-button'
                text={t('common:save')}
                type='primary'
                size='medium'
                variant='contained'
                onClick={handleAddAmenity}
              />
            </Box>
          </td>
          <td>
            <Switch
              id='add-amenity-include-checkbox'
              name='isincluded'
              checked={isIncluded}
              onChange={handleChangeSelect}
              color='primary'
            />
          </td>
          <td>
            <Switch
              id='add-amenity-exclude-checkbox'
              name='isexcluded'
              checked={isExcluded}
              onChange={handleChangeSelect}
              color='primary'
            />
          </td>
          <td>
            <IconButton aria-label={t('common:delete')} onClick={hideForm}>
              <Trash size={24} color='#1361D8' />
            </IconButton>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

AddAmenityRow.propTypes = {
  isVisible: PropTypes.bool,
  setAmenitiesList: PropTypes.func,
  toggleVisibility: PropTypes.func,
  amenityToEdit: PropTypes.object
};

export default AddAmenityRow;
