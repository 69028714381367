// @ts-nocheck
import { Box } from '@mui/material';
import {
  FilePdf,
  FilePng,
  FileJpg,
  FileSvg,
  FileCsv,
  FileDoc,
  FilePpt,
  FileXls
} from '@phosphor-icons/react';
import * as PropTypes from 'prop-types';
import './ServiceCardAttachments.css';

const iconStyle = {
  fill: '#5c687c',
  size: 24
};

const getIconByFileType = (url) => {
  const extension = url?.split('.')?.pop();

  switch (extension) {
    case 'pdf':
      return <FilePdf {...iconStyle} />;
    case 'jpeg':
    case 'jpg':
      return <FileJpg {...iconStyle} />;
    case 'png':
      return <FilePng {...iconStyle} />;
    case 'svg':
      return <FileSvg {...iconStyle} />;
    case 'txt':
    case 'doc':
    case 'docx':
      return <FileDoc {...iconStyle} />;
    case 'xls':
    case 'xlsx':
      return <FileXls {...iconStyle} />;
    case 'csv':
      return <FileCsv {...iconStyle} />;
    case 'ppt':
    case 'pptx':
      return <FilePpt {...iconStyle} />;
    default:
      return <FileDoc {...iconStyle} />;
  }
};

const trimFileName = (url, maxLength = 8) => {
  const fileName = url.split('/').pop().split('?')[0].split('#')[0];
  const lastDotIndex = fileName.lastIndexOf('.');

  if (lastDotIndex === -1) return fileName; // No extension found

  const namePart = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex);

  if (namePart.length > maxLength) {
    return namePart.substring(0, maxLength) + '...' + extension;
  }

  return fileName;
};

/**
 * A component that displays a list of attachments as icons and their names.
 *
 * If the list of attachments is empty, the component will not render anything.
 *
 * @param {array<string>} attachments A list of attachment URLs.
 * @returns {React.ReactElement}
 */
const ServiceCardAttachments = ({ attachments = [] }) => {
  if (!attachments?.length) return null;

  return (
    <Box className='service-view-card__attachment-container'>
      {attachments.map((attachment, index) => {
        const Icon = getIconByFileType(attachment);
        const fileName = trimFileName(attachment);
        return (
          <Box
            className='service-view-card__attachment-element'
            key={`service-card-attachment-${index}`}
          >
            {Icon ?? <></>}
            {fileName && (
              <span className='service-view-card__attachment-file-name '>
                {fileName}
              </span>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

ServiceCardAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.string)
};

export default ServiceCardAttachments;
