/* eslint-disable react/prop-types */
// @ts-nocheck
import { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ErrorToast } from '../../../utils/alerts';
import { CircularProgress } from '@mui/material';
import request from '../../../services/request';
import { useTranslation } from 'react-i18next';
import { FilePdf } from '@phosphor-icons/react';
import { preventBubbling } from '../../../utils/eventHelper';
import { XGButton } from '@xg-cl/xg-cl';
import globalConfig from '../../../config/globalConfig';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function GeneratePdfOptions({ ...props }) {
  const tripId = props.tripId;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const { t } = useTranslation(['trip', 'common']);
  const [pdfOptions, setPdfOptions] = useState([]);
  const { ShowServicesFunctionality } = useFlags();

  // how can I listen when the flag changes to update in realtime

  useEffect(() => {
    if (ShowServicesFunctionality) {
      setPdfOptions([
        { id: 1, name: 'exportPdfProposal', label: 'Export Proposal' },
        { id: 2, name: 'exportPdfTravelDoc', label: 'Export Travel Doc' },
        { id: 3, name: 'exportPdfAttachments', label: 'Export Attachments' }
      ]);
    } else {
      setPdfOptions([
        { id: 1, name: 'exportPdfProposal', label: 'Export Proposal' }
      ]);
    }
  }, [ShowServicesFunctionality]);

  const exportItineraryPDF = async () => {
    try {
      setPdfIsLoading(true);

      const response = await request.get(`/output/trip/${tripId}/pdf`, {
        timeout: 120000
      });

      if (response?.data && Object.keys(response?.data).length > 0) {
        const downloadPdfUrl = response?.data.files[0]?.url;
        window.open(downloadPdfUrl);
      } else {
        ErrorToast(t('errors:tripPdfCouldNotBeGenerated'));
      }
    } catch (error) {
      ErrorToast(t('errors:tripPdfCouldNotBeGenerated'));
    } finally {
      setPdfIsLoading(false);
    }
  };

  const exportTravelDocPDF = async () => {
    try {
      setPdfIsLoading(true);

      const response = await request.get(
        `/output/trip/${tripId}/travel-doc-pdf`,
        {
          timeout: 2400000
        }
      );

      if (response?.data && Object.keys(response?.data).length > 0) {
        const downloadPdfUrl = response?.data.file?.url;

        const downloadUrl = await fetch(downloadPdfUrl);

        // Convert response in a Blob
        const blob = await downloadUrl.blob();

        // Create an URL from the Blob
        const url = URL.createObjectURL(blob);

        // Create a temporal link and force forzar the download
        const a = document.createElement('a');
        a.href = url;
        // Name of the file
        a.download = globalConfig.isProduction
          ? downloadPdfUrl.split('?').shift().split('/').pop()
          : downloadPdfUrl.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release created URL
        URL.revokeObjectURL(url);
      } else {
        ErrorToast(t('errors:tripPdfCouldNotBeGenerated'));
      }
    } catch (error) {
      ErrorToast(t('errors:tripPdfCouldNotBeGenerated'));
    } finally {
      setPdfIsLoading(false);
    }
  };

  const exportPdfAttachments = async () => {
    try {
      setPdfIsLoading(true);

      const response = await request.get(
        `/output/trip/${tripId}/attachment-pdf`,
        {
          timeout: 120000
        }
      );

      if (response?.body && Object.keys(response?.body).length > 0) {
        const contentDisposition =
          response?.headers['Content-Disposition'] ||
          response?.headers['content-disposition'];
        if (!contentDisposition) return null;

        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        const newFileName = match ? match[1] : 'attachments.pdf';

        const uint8Array = new Uint8Array(response.body);

        const blob = new Blob([uint8Array], {
          type: 'application/pdf'
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = newFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release created URL
        URL.revokeObjectURL(url);
      } else {
        ErrorToast(t('errors:tripPdfCouldNotBeGeneratedAttachments'));
      }
    } catch (error) {
      ErrorToast(t('errors:tripPdfCouldNotBeGeneratedAttachments'));
    } finally {
      setPdfIsLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickAnchor = (event) => {
    preventBubbling(event);
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickOption = async (event, name) => {
    preventBubbling(event);
    try {
      if (name == 'exportPdfProposal') {
        exportItineraryPDF();
      } else if (name == 'exportPdfTravelDoc') {
        exportTravelDocPDF();
      } else if (name == 'exportPdfAttachments') {
        exportPdfAttachments();
      } else {
        ErrorToast('Export pdf error');
      }
    } catch (err) {
      ErrorToast('Export pdf error');
    } finally {
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <XGButton
        disabled={pdfIsLoading}
        startIcon={<FilePdf width={20} />}
        data-testid='itinerary-export-pdf-button'
        id='itinerary-export-pdf-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        type='secondary'
        size='large'
        variant='outlined'
        text={t('common:exportPdf')}
        onClick={handleOnClickAnchor}
        style={{
          border: 'none'
        }}
      >
        {pdfIsLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',

              zIndex: 1
            }}
          />
        )}
      </XGButton>

      <Menu
        data-testid='trip-header-dropdown-export-pdf'
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'itinerary-export-pdf-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {pdfOptions.length > 0 &&
          pdfOptions.map((pdfOption) => {
            return (
              <MenuItem
                key={pdfOption?.id}
                onClick={(event) => {
                  handleOnClickOption(event, pdfOption?.name);
                }}
                data-id={pdfOption?.id}
              >
                {pdfOption?.label}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
