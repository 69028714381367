// @ts-nocheck
import RenderField from './RenderField';
import DeleteDayService from './DeleteDayService';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { PlusCircle, Trash } from '@phosphor-icons/react';
import { XGDropdownMenu, XGButton } from '@xg-cl/xg-cl';
import {
  formatServiceTypeOptions,
  generateGridColumns,
  generateGridRows,
  validateFormData,
  generateServiceTypeFieldId
} from '../../../../utils/serviceTypes/serviceTypes';
import ServiceTypesButtonSkeleton from '../../Services/ServiceTypesButtonSkeleton/ServiceTypesButtonSkeleton';
import {
  createServiceType,
  updateServiceType
} from '../../../../services/servicetype/servicetype';
import { ErrorToast, SuccessToast } from '../../../../utils/alerts';
import { fileType } from '../../../../constants/files';
import {
  getPresignedUrl,
  uploadToBucket
} from '../../../../services/upload/upload';
import { blue } from '@mui/material/colors';
import * as PropTypes from 'prop-types';
import * as P from '@phosphor-icons/react';
import './ServiceTypesForm.css';

const ServiceTypesForm = ({
  id,
  tripid,
  tripdetaileddayid,
  serviceType,
  onChange,
  onError,
  formData,
  serviceTypes,
  isLoading,
  onCancel,
  onSave,
  onServiceTypeChange,
  updateFormData,
  canDelete,
  onDelete,
  isEditing,
  ...rest
}) => {
  const { servicetypefield = [] } = serviceType || {};
  const { t } = useTranslation(['common', 'trip', 'errors']);
  const iconref = serviceType?.iconref || serviceType?.servicetype?.iconref;
  const serviceTypeName = serviceType?.name || serviceType?.servicetype?.name;
  const formRefs = useRef({});
  const [isDeletingDayService, setIsDeletingDayService] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleOnChange = useCallback(
    (serviceFieldType, value, additionalData) => {
      if (onChange) {
        onChange(serviceFieldType, value, additionalData);
      }
    },
    [onChange]
  );

  const handleOnError = useCallback(
    (servicefieldid, errorMessage = '') => {
      if (onError) {
        onError(servicefieldid, errorMessage);
      }
    },
    [onError]
  );

  const setFormFieldRef = (fieldName) => (element) => {
    formRefs.current[fieldName] = element;
  };

  const iconExists = (iconName) => {
    return typeof P[iconName] === 'object';
  };

  const gridRows = generateGridRows(servicetypefield);
  const ServiceTypeIcon = iconExists(iconref) ? P[iconref] : null;
  const hasRows = gridRows && Object.keys(gridRows).length > 0;

  const renderFields = useCallback(() => {
    return (
      <Grid container spacing={2} sx={{ gap: '4px' }}>
        {Object.keys(gridRows).map((rowNumber) => {
          const gridRowColumns = generateGridColumns(gridRows[rowNumber]);
          const columnKeys = gridRowColumns?.map((column) => column?.key);
          const alignBaseLine = columnKeys?.some(
            (key) => key === 'UPLOADIMAGES' || key === 'UPLOADATTACHMENTS'
          );

          return (
            <Grid item xs={12} key={rowNumber}>
              <Grid
                container
                spacing={2}
                sx={{ alignItems: alignBaseLine ? 'baseline' : 'end' }}
              >
                {gridRowColumns?.map((field, index) => (
                  <Grid
                    item
                    xs={field?.colsize}
                    key={field?.servicetypefieldid ?? index}
                  >
                    {!field?.isEmptyColumn ? (
                      <RenderField
                        key={field?.servicetypefieldid}
                        field={field}
                        onChange={handleOnChange}
                        onError={handleOnError}
                        formData={formData}
                        setFormFieldRef={setFormFieldRef}
                        disabled={isSaving || isLoading}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }, [handleOnChange, handleOnError, isSaving, formData, isLoading, gridRows]);

  if (isLoading) {
    return <ServiceTypesButtonSkeleton id='service-types-button-skeleton' />;
  }

  const getFileName = (url = '') => {
    const urlObj = new URL(url);
    const pathname = urlObj?.pathname;
    const filename = pathname?.substring(pathname?.lastIndexOf('/') + 1);

    return filename;
  };

  const handleSave = async () => {
    const errorText = isEditing
      ? t('errors:serviceTypeCouldNotBeUpdated')
      : t('errors:serviceTypeCouldNotBeCreated');
    try {
      setIsSaving(true);

      let payload = {
        servicetypeid: serviceType.servicetypeid,
        servicefields: Object.keys(formData).reduce(
          (accumulator, fieldId, index) => {
            const { options: fieldOptions, files = [] } = formData[fieldId];
            const isFile = fieldOptions.some((option) => option?.file != null);

            accumulator.push({
              servicefieldid: parseInt(fieldId, 10),
              isvisible: true,
              isFile,
              fieldIndex: index,
              files,
              options: fieldOptions.map((option) => ({
                ...option,
                tripdetaileddayservicevalueid:
                  option.tripdetaileddayservicevalueid || null,
                isNew: !option?.tripdetaileddayservicevalueid ?? false
              }))
            });
            return accumulator;
          },
          []
        )
      };

      const { isValid, newFormData } = validateFormData(
        servicetypefield,
        formData,
        t
      );

      if (!isValid) {
        const formFields = servicetypefield?.sort((a, b) => a?.row - b?.row);
        let scrollToElement = null;

        for (let field of formFields) {
          const hasError = newFormData[field?.servicefieldid]?.error;

          if (hasError) {
            const fieldId = generateServiceTypeFieldId(field);
            scrollToElement = formRefs?.current[fieldId];
            break;
          }
        }

        if (scrollToElement) {
          //Scroll to first field with error
          scrollToElement.scrollIntoView({ behavior: 'smooth' });
          setTimeout(() => {
            updateFormData(newFormData);
          }, 200);
        } else {
          updateFormData(newFormData);
        }
      } else {
        const fileFields = payload?.servicefields?.filter(
          (field) => field?.isFile
        );

        const imagesToUpload = [];
        const attachmentsToUpload = [];

        fileFields?.forEach((field) => {
          field?.options?.forEach((option, optionIndex) => {
            const file = option?.file;
            if (file && option?.isNew) {
              if (option?.presignType === 'image') {
                imagesToUpload.push({
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  pathReference: option?.path,
                  tripId: tripid,
                  index: field?.fieldIndex,
                  optionIndex,
                  file,
                  servicefieldid: field?.servicefieldid
                });
              } else {
                attachmentsToUpload.push({
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  pathReference: option?.path,
                  tripId: tripid,
                  index: field?.fieldIndex,
                  optionIndex,
                  file,
                  servicefieldid: field?.servicefieldid
                });
              }
            }
          });
        });

        //upload Images
        if (imagesToUpload.length > 0) {
          const imagesPayload = {
            files: imagesToUpload,
            fileType: fileType.IMAGE
          };

          const { data, error } = await getPresignedUrl(imagesPayload);

          if (error || !data) {
            ErrorToast(errorText);
            return;
          }

          const imagesPromises = [];

          if (data?.length > 0) {
            data.forEach((image, index) => {
              imagesPromises.push(
                uploadToBucket(image, imagesToUpload[index].file).then(() => {
                  const newFileName = getFileName(image?.data?.presignedUrl);
                  const fieldIndex = imagesToUpload[index]?.index;
                  const optionIndex = imagesToUpload[index]?.optionIndex;
                  payload.servicefields[fieldIndex].options[optionIndex].value =
                    newFileName;
                })
              );
            });

            await Promise.all(imagesPromises);
          }
        }

        // upload Attachments
        if (attachmentsToUpload.length > 0) {
          const attachmentsPayload = {
            files: attachmentsToUpload,
            fileType: fileType.FILE
          };

          const { data, error } = await getPresignedUrl(attachmentsPayload);

          if (error || !data) {
            ErrorToast(errorText);
            return;
          }

          const attachmentsPromises = [];

          if (data?.length > 0) {
            data.forEach((image, index) => {
              attachmentsPromises.push(
                uploadToBucket(image, attachmentsToUpload[index].file).then(
                  () => {
                    const newFileName = getFileName(image?.data?.presignedUrl);
                    const fieldIndex = attachmentsToUpload[index]?.index;
                    const optionIndex = attachmentsToUpload[index]?.optionIndex;
                    payload.servicefields[fieldIndex].options[
                      optionIndex
                    ].value = newFileName;
                  }
                )
              );
            });

            await Promise.all(attachmentsPromises);
          }
        }

        const response = isEditing
          ? await updateServiceType(
              tripid,
              tripdetaileddayid,
              serviceType.tripdetaileddayserviceid,
              payload
            )
          : await createServiceType(tripid, tripdetaileddayid, payload);
        if (response.error) {
          ErrorToast(errorText);
        } else {
          SuccessToast(t('trip:serviceTypeSavedSuccessfully'));
          onSave(response.data); // Update parent state with saved data
        }
      }
    } catch (error) {
      ErrorToast(errorText);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Box
      className={`detailed-itinerary-service-types-form ${hasRows ? 'detailed-itinerary-service-types-form--open' : ''}`}
    >
      {!isLoading && !serviceType && (
        <Box className={'detailed-itinerary-service-types-form-buttons'}>
          <XGDropdownMenu
            isButton={true}
            btnType='primary'
            btnVariant='contained'
            btnSize='large'
            btnText={serviceType ? serviceType?.name : t('add')}
            btnStartIcon={<PlusCircle />}
            options={formatServiceTypeOptions(
              serviceTypes,
              onServiceTypeChange
            )}
            id='detailed-itinerary-service-types-dropdown-menu'
          />
        </Box>
      )}
      {serviceType && (
        <>
          <Box
            id={id}
            data-testid='detailed-itinerary-service-types-skeleton'
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '18px',
              padding: '24px',
              border: '1px solid rgba(0, 0, 0, 0.2)',
              borderRadius: '8px'
            }}
            {...rest}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {ServiceTypeIcon && (
                  <div
                    className={`service-type__circle service-type__circle-${iconref}`}
                  >
                    <ServiceTypeIcon size={23.33} />
                  </div>
                )}
                <span className='text-xl-semibold'>{serviceTypeName}</span>
              </Box>
              {canDelete && (
                <div
                  className='detailed-itinerary-service-types-delete-button'
                  onClick={() => {
                    setIsDeletingDayService(true);
                  }}
                >
                  <Trash size={20} color='#667085' />
                </div>
              )}
            </Box>
            {renderFields()}
            <Box
              className={'detailed-itinerary-service-types-form-fields-buttons'}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <XGButton
                data-testid='detailed-itinerary-service-types-form-cancel-button'
                id='detailed-itinerary-service-types-form-cancel-button'
                text={t('cancel')}
                type='secondary'
                size='medium'
                variant='outlined'
                onClick={onCancel}
                disabled={isSaving || isLoading}
              />
              <Box sx={{ position: 'relative' }}>
                <XGButton
                  data-testid='detailed-itinerary-service-types-form-save-button'
                  id='detailed-itinerary-service-types-form-save-button'
                  text={t('save')}
                  type='primary'
                  size='medium'
                  variant='contained'
                  onClick={handleSave}
                  disabled={isSaving || isLoading}
                />
                {isSaving && (
                  <CircularProgress
                    id='booking-modal-actions-loader'
                    size={24}
                    sx={{
                      color: blue[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          <DeleteDayService
            data={{
              tripid,
              tripdetaileddayid,
              tripdetaileddayserviceid: serviceType.tripdetaileddayserviceid
            }}
            id='delete-day-service-component'
            isOpenModal={isDeletingDayService}
            updateIsOpen={(isOpenModal) => {
              setIsDeletingDayService(isOpenModal);
            }}
            onDelete={onDelete}
          />
        </>
      )}
    </Box>
  );
};

ServiceTypesForm.propTypes = {
  id: PropTypes.string.isRequired,
  tripid: PropTypes.number.isRequired,
  tripdetaileddayid: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  onServiceTypeChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
  serviceType: PropTypes.object,
  isLoading: PropTypes.bool,
  canDelete: PropTypes.bool,
  isEditing: PropTypes.bool
};

export default ServiceTypesForm;
