// @ts-nocheck
import { Box } from '@mui/material';
import { Clock, MapPin, Info, CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ServicePropTypes } from '../../types';
import Editor from '../../../../../Editor/Editor';
import { formatTime } from '../../../../../../utils/dateUtility';
import ImageSection from '../Components/ImageSection';
import AttachmentNotice from '../Components/AttachmentNotice';
import useComponentHeight from '../../../../../../hooks/useComponentHeight';
import IconSection from '../Components/IconSection';

const FlightService = ({
  servicetype,
  servicefields,
  tripdetaileddayserviceid,
  tenantsettings,
  icon
}) => {
  const { ref, height } = useComponentHeight();
  const { t } = useTranslation();
  const columnFields = [
    'ARRIVALAIRPORT',
    'DEPARTUREAIRPORT',
    'DEPARTURETIME',
    'ARRIVALTIME'
  ];
  const hasColumnFieldData = columnFields.some(
    (field) => !!servicefields[field]
  );

  return (
    <Box
      className='service-section'
      id={`service-section-flights-${tripdetaileddayserviceid}`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
      ref={ref}
    >
      <Box className='service-section__icon-name'>
        <IconSection icon={icon} height={height} />
        <h3
          className='service-section__name'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {servicetype.name}
        </h3>
      </Box>

      <Box className='service-section__content'>
        {servicefields.TITLE && (
          <h5
            className='service-section__title'
            style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
          >
            {servicefields.TITLE}
          </h5>
        )}

        <Box className='service-section__info'>
          <Info />
          {servicefields.AIRLINE && (
            <p className='service-section__label-value'>
              <span>{t('Airline')}:</span> {servicefields.AIRLINE}
            </p>
          )}
          {servicefields.FLIGHTNUMBER && (
            <p className='service-section__label-value'>
              <span>{t('Flight Number')}:</span> {servicefields.FLIGHTNUMBER}
            </p>
          )}
        </Box>

        <ImageSection images={servicefields?.UPLOADIMAGES} />

        <Box className='service-section___column-info'>
          {(servicefields.ARRIVALAIRPORT || servicefields.DEPARTUREAIRPORT) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <MapPin />
              </Box>
              <Box>
                {servicefields.DEPARTUREAIRPORT && (
                  <p className='service-section__label-value'>
                    <span>{t('Leaving From')}:</span>{' '}
                    {servicefields.DEPARTUREAIRPORT}
                  </p>
                )}
                {servicefields.ARRIVALAIRPORT && (
                  <p className='service-section__label-value'>
                    <span>{t('Arriving To')}:</span>{' '}
                    {servicefields.ARRIVALAIRPORT}
                  </p>
                )}
              </Box>
            </Box>
          )}

          {(servicefields.DEPARTURETIME || servicefields.ARRIVALTIME) && (
            <Box className='service-section___column'>
              <Box className='service-section___column-info__icon'>
                <Clock />
              </Box>
              <Box>
                {servicefields.DEPARTURETIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Departure Time')}:</span>{' '}
                    {formatTime(servicefields.DEPARTURETIME)}
                  </p>
                )}
                {servicefields.ARRIVALTIME && (
                  <p className='service-section__label-value'>
                    <span>{t('Arrival Time')}:</span>{' '}
                    {formatTime(servicefields.ARRIVALTIME)}
                  </p>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          className={`service-section__description-notes ${hasColumnFieldData ? 'spacing' : ''}`}
        >
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-section-flights-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-section__description'
              style={{
                fontFamily: tenantsettings?.bodyfont || 'inherit'
              }}
            />
          )}

          {servicefields?.CONFIRMATIONINFORMATION && (
            <Box className='service-section__confirmation'>
              <p>
                <span>{t('Confirmation #')}:</span>{' '}
                {servicefields.CONFIRMATIONINFORMATION}
              </p>
            </Box>
          )}

          <AttachmentNotice attachments={servicefields?.UPLOADATTACHMENTS} />

          {servicefields?.MEALSINCLUDED && (
            <Box className='service-section__include-values'>
              {servicefields?.MEALSINCLUDED?.map((meal, index) => (
                <Box
                  className='service-section__include-values__item'
                  key={`meals-included-${meal.toLowerCase()}-${index}`}
                >
                  <CheckCircle color='rgba(56, 211, 133, 1)' />
                  <p>{meal}</p>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

FlightService.propTypes = ServicePropTypes;

export default FlightService;
