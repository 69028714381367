// @ts-nocheck
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

const DetailedItinerarySkeleton = ({ id, ...rest }) => {
  return (
    <Box
      id={id}
      data-testid='detailed-itinerary-skeleton'
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        width: '100%'
      }}
      {...rest}
    >
      {/* Left Menu Skeleton */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '25%',
          padding: '8px'
        }}
      >
        {Array.from({ length: 7 }).map((_, index) => (
          <Skeleton
            key={`menu-skeleton-${index}`}
            variant='rectangular'
            width={'100%'}
            height={76}
            sx={{
              borderRadius: '8px'
            }}
          />
        ))}
      </Box>

      {/* Main Content Skeleton */}
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '16px',
          backgroundColor: '#F9FAFB',
          borderRadius: '8px',
          border: '1px solid #EAECF0',
          width: '75%'
        }}
      >
        {/* Image Skeleton */}
        <Skeleton variant='rectangular' width='100%' height={160} />

        {/* Title & Date Skeleton */}
        <Box style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Skeleton variant='text' width='30%' height={32} />
          <Skeleton variant='text' width='40%' height={24} />
        </Box>

        {/* Description Skeleton */}
        <Skeleton variant='text' width='100%' height={20} />
        <Skeleton variant='text' width='90%' height={20} />
        <Skeleton variant='text' width='80%' height={20} />

        {/* Internal Notes Skeleton */}
        <Skeleton variant='text' width='100%' height={20} />
        <Skeleton variant='text' width='90%' height={20} />
      </Box>
    </Box>
  );
};

DetailedItinerarySkeleton.propTypes = {
  id: PropTypes.string
};

export default DetailedItinerarySkeleton;
