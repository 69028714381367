// @ts-nocheck
import DetailedItineraryDay from '../DetailedItineraryDay/DetailedItineraryDay';
import DetailedItinerarySkeleton from './DetailedItinerarySkeleton';
import ServiceTypesForm from './ServiceTypesForm/ServiceTypesForm';
import ServicesView from './ServicesView/ServicesView';
import DaysNavMenuItem from '../DaysNavMenuItem/DaysNavMenuItem';
import actionTypes from '../../../constants/actionTypes';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
  getTripDetailedDays,
  updateTripDetailedDay
} from '../../../services/trip/trip';
import { ErrorToast } from '../../../utils/alerts';
import { useTranslation } from 'react-i18next';
import { getServiceTypes } from '../../../services/servicetype/servicetype';
import {
  initServiceTypeDayFormData,
  getDefaultFieldOptions,
  setServiceTypeDayFormData
} from '../../../utils/serviceTypes/serviceTypes';
import { preventBubbling } from '../../../utils/eventHelper';
import * as PropTypes from 'prop-types';
import './DetailedItinerary.css';

/**
 * Functional React component for rendering a detailed view of a trip days.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip detailed view component.
 * @param {string} [props.tripid] - The ID of the trip.
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed view.
 */

const initialFetchData = {
  serviceTypes: [],
  detailedDays: []
};

const initialLoadingState = {
  serviceTypes: true,
  detailedDays: true
};

const initialDaysData = {};

const DetailedItinerary = ({ id, tripid, ...rest }) => {
  const [isLoading, setIsLoading] = useState(initialLoadingState);
  const [fetchData, setFetchData] = useState(initialFetchData);
  const [originalData, setOriginalData] = useState({});
  const [formData, setFormData] = useState(initialDaysData);
  const [showDelete, setShowDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const storedTripData = useSelector((state) => state.trip);
  const tenant = useSelector((state) => state.tenant);
  const { t } = useTranslation(['trip', 'common', 'library']);

  const fetchInitialData = useCallback(async () => {
    try {
      const [serviceTypes, detailedDays] = await Promise.all([
        getServiceTypes(),
        getTripDetailedDays(tripid)
      ]);

      const serviceTypesData = serviceTypes?.data ?? [];
      const detailedDaysData = detailedDays?.data ?? [];
      const copyOfDaysData = structuredClone(detailedDaysData);
      const copyOfServiceTypes = structuredClone(serviceTypesData);

      setFetchData((prevState) => ({
        ...prevState,
        serviceTypes: serviceTypesData,
        detailedDays: detailedDaysData
      }));
      setSelectedDay(detailedDaysData?.length ? detailedDaysData[0] : null);
      setOriginalData({
        days: copyOfDaysData || [],
        serviceTypes: copyOfServiceTypes || []
      });
    } catch (error) {
      setFetchData((prevState) => ({
        ...prevState,
        serviceTypes: [],
        detailedDays: []
      }));
    } finally {
      setIsLoading((prevState) => ({
        ...prevState,
        serviceTypes: false,
        detailedDays: false
      }));
    }
  }, [tripid]);

  const onSelectDay = (tripDayId) => {
    const foundDay = fetchData?.detailedDays?.find(
      (day) => day?.tripdayid === tripDayId
    );
    onCancelServiceType();
    setSelectedDay(foundDay);
    setShowDelete(false);
  };

  const handleOnDayAction = async (event, action, tripDayId) => {
    preventBubbling(event);

    switch (action) {
      case actionTypes.SELECT:
        onSelectDay(tripDayId);
        break;
      default:
        break;
    }
  };

  const updateTripDay = async (
    tripdetaileddayid,
    dataDay,
    isUploadingImage = false
  ) => {
    try {
      const { data, error } = await updateTripDetailedDay(
        tripid,
        tripdetaileddayid,
        dataDay
      );

      if (data && !error) {
        let foundDayIndex = 0;

        const newData = {
          tripdetaileddayimage: data?.tripdetaileddayimage || [],
          title: data?.title || '',
          description: data?.description || '',
          note: data?.note || ''
        };

        const newdetailedDays = fetchData.detailedDays.map((day, index) => {
          if (day?.tripdetaileddayid === data?.tripdetaileddayid) {
            foundDayIndex = index;
            return { ...day, ...newData };
          }
          return day;
        });

        setFetchData((prevState) => ({
          ...prevState,
          detailedDays: newdetailedDays
        }));
        setSelectedDay(newdetailedDays[foundDayIndex]);

        return data;
      } else {
        if (!isUploadingImage) {
          ErrorToast(t('errors:tripCouldNotBeUpdated'));
        }
      }

      return null;
    } catch (err) {
      if (!isUploadingImage) {
        ErrorToast(t('errors:tripCouldNotBeUpdated'));
      }
      return null;
    }
  };

  const onServiceTypeChange = (event, serviceType) => {
    setSelectedServiceType(serviceType);
    setFormData(initServiceTypeDayFormData(serviceType));
    setShowDelete(false);
    setIsEditing(false);
  };

  const onSaveServiceType = (savedService) => {
    const foundServiceType = originalData?.serviceTypes?.find(
      (serviceType) =>
        savedService?.servicetype?.servicetypeid === serviceType?.servicetypeid
    );

    const savedServiceData = {
      ...savedService,
      servicetype: { ...savedService?.servicetype, ...foundServiceType }
    };

    const updatedDays = fetchData.detailedDays.map((day) => {
      if (day.tripdetaileddayid === selectedDay.tripdetaileddayid) {
        const updatedTripDetailedDaysService = (
          day.tripdetaileddayservice || []
        ).map((service) =>
          service.tripdetaileddayserviceid ===
          savedServiceData.tripdetaileddayserviceid
            ? { ...service, ...savedServiceData }
            : service
        );
        const newServiceAdded = updatedTripDetailedDaysService.find(
          (service) =>
            service.tripdetaileddayserviceid ===
            savedServiceData.tripdetaileddayserviceid
        );
        if (!newServiceAdded) {
          updatedTripDetailedDaysService.push(savedServiceData);
        }

        return {
          ...day,
          tripdetaileddayservice: updatedTripDetailedDaysService
        };
      }
      return day;
    });

    setFetchData((prevState) => ({
      ...prevState,
      detailedDays: updatedDays
    }));

    setOriginalData((prevState) => ({
      ...prevState,
      days: updatedDays
    }));

    setSelectedDay((prevState) => ({
      ...prevState,
      tripdetaileddayservice: prevState.tripdetaileddayservice.some(
        (service) =>
          service.tripdetaileddayserviceid ===
          savedServiceData.tripdetaileddayserviceid
      )
        ? prevState.tripdetaileddayservice.map((service) =>
            service.tripdetaileddayserviceid ===
            savedServiceData.tripdetaileddayserviceid
              ? { ...service, ...savedServiceData } // Update existing service
              : service
          )
        : [...prevState.tripdetaileddayservice, savedServiceData] // Add new service
    }));

    setSelectedServiceType(null); // Close the form
  };

  const onDeleteServiceType = (deletedServiceId) => {
    const updatedDays = fetchData.detailedDays.map((day) => {
      if (day.tripdetaileddayid === selectedDay.tripdetaileddayid) {
        return {
          ...day,
          tripdetaileddayservice: day.tripdetaileddayservice.filter(
            (service) => service.tripdetaileddayserviceid !== deletedServiceId
          )
        };
      }
      return day;
    });

    onCancelServiceType();
    setFetchData((prevState) => ({
      ...prevState,
      detailedDays: updatedDays
    }));

    setOriginalData((prevState) => ({
      ...prevState,
      days: updatedDays
    }));

    setSelectedDay((prevState) => ({
      ...prevState,
      tripdetaileddayservice: prevState.tripdetaileddayservice.filter(
        (service) => service.tripdetaileddayserviceid !== deletedServiceId
      )
    }));
  };

  const onCancelServiceType = () => {
    const foundDay = originalData?.days?.find(
      (day) => day.tripdetaileddayid === selectedDay?.tripdetaileddayid
    );

    setSelectedServiceType(null);
    setFormData(initialDaysData);
    setSelectedDay(foundDay);
  };

  const onError = (servicefieldid, errorMessage = '') => {
    if (servicefieldid) {
      const field = formData[servicefieldid];
      field.error = errorMessage;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [servicefieldid]: {
          ...field
        }
      }));
    }
  };

  const onChange = (serviceFieldType, value, additionalData = {}) => {
    if (serviceFieldType?.servicefieldid) {
      const field = formData[serviceFieldType.servicefieldid];
      field.error = null;

      if (Array.isArray(value)) {
        field.options = value.filter((opt) => !!opt.optionId);
      } else {
        field.options[0].value = value;
      }
      const defaultOptions = getDefaultFieldOptions(
        selectedServiceType,
        serviceFieldType.servicefieldid
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        [serviceFieldType.servicefieldid]: {
          ...field,
          ...additionalData,
          options: field.options.map((option) => {
            return {
              ...option,
              ...defaultOptions[option.servicefieldoptionid]
            };
          })
        }
      }));
    }
  };

  const onSelectServiceType = async (serviceType) => {
    // If there is a selected serviceType we should update the form
    if (selectedServiceType) {
      return;
    }

    //Find original service type data
    const foundServiceType = originalData?.serviceTypes?.find(
      (service) =>
        service?.servicetypeid === serviceType?.servicetype?.servicetypeid
    );

    //Look for existing fields from setting that are not in the form
    const missingFields = foundServiceType?.servicetypefield?.filter(
      (fieldFromSetting) =>
        !serviceType.servicefields.some(
          (formField) =>
            formField.servicefieldid === fieldFromSetting.servicefieldid
        )
    );

    //Map missing fields
    const mappedMissingFields = missingFields?.map((field) => ({
      isvisible: true,
      options: [],
      servicefieldid: field?.servicefieldid
    }));

    // Map the service fields by their IDs for quick access
    const serviceFieldsMap = [
      ...serviceType.servicefields,
      ...mappedMissingFields
    ].reduce((acc, sField) => {
      acc[sField.servicefieldid] = sField;
      return acc;
    }, {});

    const fieldTypesMap = {};

    // Format service type fields by combining service fields with their respective types
    const formattedServiceTypeFields = originalData?.serviceTypes.reduce(
      (acc, sType) => {
        if (sType.servicetypeid === serviceType.servicetype.servicetypeid) {
          sType.servicetypefield.map((sTField) => {
            fieldTypesMap[sTField.servicefieldid] = sTField.servicefieldtype;
            acc.push({
              ...sTField,
              ...serviceFieldsMap[sType.servicefieldid]
            });
            return sTField;
          });
        }
        return acc;
      },
      []
    );

    // Format the selected service type with updated service fields
    const formattedServiceType = {
      ...{
        ...serviceType,
        servicefields: [...serviceType.servicefields, ...mappedMissingFields]
      },
      servicetypefield: formattedServiceTypeFields,
      servicetypeid: serviceType.servicetype.servicetypeid
    };

    // Update the state with the formatted service type and form data
    const newFromData = await setServiceTypeDayFormData(
      formattedServiceType,
      fieldTypesMap,
      { tripId: tripid, tenantId: tenant?.tenantid }
    );

    setSelectedServiceType(formattedServiceType);
    setFormData(newFromData);
    setShowDelete(true);
    setIsEditing(true);
  };

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  if (isLoading.detailedDays) {
    return (
      <Box
        id={id}
        className='detailed-itinerary-wapper'
        data-testid='trip-detailed-itinerary'
        {...rest}
      >
        <DetailedItinerarySkeleton id={`${id}-skeleton`} />
      </Box>
    );
  }

  return (
    <>
      <Box
        id={id}
        className='detailed-itinerary-wapper'
        data-testid='trip-detailed-itinerary'
        {...rest}
      >
        <Box id={id} className='detailed-view-nav-menu'>
          <Box className='detailed-itinerary-nav-menu__container'>
            <ul className='days-nav-menu-list'>
              {fetchData.detailedDays.map((day, index) => (
                <DaysNavMenuItem
                  key={`${day?.tripdayid}`}
                  id={`${day?.tripdayid}`}
                  item={{
                    ...day,
                    daynumber: index + 1
                  }}
                  active={selectedDay?.tripdayid == day?.tripdayid}
                  onAction={handleOnDayAction}
                  showDeleteButton={false}
                  allowDrag={false}
                />
              ))}
            </ul>
          </Box>
        </Box>

        {selectedDay && (
          <Box id={id} className='detailed-itinerary-day-content'>
            <DetailedItineraryDay
              data={{
                ...selectedDay,
                startdate: storedTripData?.startdate,
                enddate: storedTripData?.enddate,
                tripid
              }}
              id='detailed-itinerary-day'
              updateDay={updateTripDay}
            />

            <ServiceTypesForm
              id='detailed-itinerary-service-types'
              serviceType={selectedServiceType}
              onChange={onChange}
              formData={formData}
              serviceTypes={fetchData.serviceTypes}
              isLoading={isLoading.serviceTypes}
              onCancel={onCancelServiceType}
              onError={onError}
              onSave={onSaveServiceType}
              onDelete={onDeleteServiceType}
              onServiceTypeChange={onServiceTypeChange}
              updateFormData={setFormData}
              canDelete={showDelete}
              isEditing={isEditing}
              tripid={tripid}
              tripdetaileddayid={selectedDay.tripdetaileddayid}
            />
            <ServicesView
              id='detailed-itinerary-services-view'
              services={selectedDay?.tripdetaileddayservice ?? []}
              isLoading={isLoading?.detailedDays}
              error={null}
              onSelectService={onSelectServiceType}
              tripid={tripid}
              tenantid={tenant?.tenantid}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

DetailedItinerary.propTypes = {
  id: PropTypes.string.isRequired,
  tripid: PropTypes.number.isRequired
};

export default DetailedItinerary;
