import request from '../request';

export const getAmenities = () => request.get('/amenities').then((res) => res);

export const createAmenity = (data = {}) =>
  request.post('/amenities', data).then((res) => res);

export const updateAmenity = (id = '', data = {}) =>
  request.put(`/amenities/${id}`, data).then((res) => res);

export const deleteAmenity = (id = '') =>
  request.delete(`/amenities/${id}`).then((res) => res);
