//@ts-nocheck
import {
  Airplane,
  AirplaneTakeoff,
  Buildings,
  Boat,
  Bus,
  Car,
  Compass,
  Package,
  Parachute,
  Tram
} from '@phosphor-icons/react';
import {
  getBucketImageUrl,
  getBucketFileUrl,
  getFileFromUrl
} from '../resourcePathHelper';
import { imageSize, pathReference } from '../../constants/files';

/**
 * This array contains the templates icons for service types.
 */

export const serviceTypes = [
  {
    id: 1,
    icon: <Parachute size={17} />,
    code: 'MISC',
    name: 'Misc'
  },
  {
    id: 2,
    icon: <Buildings size={17} />,
    code: 'HTL',
    name: 'Hotel'
  },
  {
    id: 3,
    icon: <Boat size={17} />,
    code: 'CRU',
    name: 'Cruise'
  },
  {
    id: 3,
    icon: <Compass size={17} />,
    code: 'SST',
    name: 'Tour'
  },
  {
    id: 5,
    icon: <Bus size={17} />,
    code: 'TRF',
    name: 'Transfer'
  },
  {
    id: 6,
    icon: <AirplaneTakeoff size={17} />,
    code: 'FLT',
    name: 'Flight'
  },
  {
    id: 7,
    icon: <Car size={17} />,
    code: 'CAR',
    name: 'Car Rental'
  },
  {
    id: 8,
    icon: <Tram size={17} />,
    code: 'TRN',
    name: 'Train'
  },
  {
    id: 9,
    icon: <Package size={17} />,
    code: 'PKG',
    name: 'Package'
  },
  {
    id: 10,
    icon: <Airplane size={17} />,
    code: 'AIRF',
    name: 'Airfare'
  }
];

export const formatServiceTypeOptions = (serviceTypes, onAction) =>
  serviceTypes.map((sType) => ({
    id: `service-types-option-${sType.code}`,
    name: sType.name,
    onClick: (evt) => onAction(evt, sType),
    icon: null
  }));

export const generateServiceTypeFieldKey = (sTypeField) =>
  sTypeField?.title?.toLowerCase()?.split()?.join('-')?.replaceAll(' ', '');

export const generateServiceTypeFieldId = (sTypeField) =>
  `${sTypeField?.servicefieldtype?.key}-${generateServiceTypeFieldKey(sTypeField)}`;

export const generateGridRows = (rowFields = []) => {
  const rows = {};

  rowFields?.forEach((field) => {
    if (!rows[field?.row]) {
      rows[field?.row] = [];
    }
    rows[field?.row].push(field);
  });

  return rows;
};

export const generateGridColumns = (rowFields = []) => {
  const sortedFields = [...rowFields]?.sort((a, b) => a?.col - b?.col);
  const columnsList = [];
  let currentColumn = 1;

  for (const field of sortedFields) {
    const diff = field?.col - currentColumn;
    if (diff > 0) {
      columnsList.push({
        colsize: diff,
        col: currentColumn,
        isEmptyColumn: true
      });
    }
    columnsList.push(field);
    currentColumn = field?.col + field?.colsize;
  }

  // Handle trailing empty columns (only if needed)
  if (currentColumn <= 12) {
    columnsList.push({
      colsize: 12 - currentColumn + 1,
      col: currentColumn,
      isEmptyColumn: true
    });
  }

  return columnsList;
};

export const generateCheckBoxOptions = (options = []) => {
  return options?.map((option, index) => {
    return {
      tripdetaileddayservicevalueid: option?.tripdetaileddayservicevalueid,
      optionId: option?.optionId || option?.servicefieldoptionid,
      servicefieldoptionid: option?.servicefieldoptionid,
      value: option?.value || option?.title,
      sequence: option?.sequence ?? index + 1
    };
  });
};

export const getValueFromServiceField = (serviceField) => {
  return serviceField?.options ? serviceField.options[0].value : null;
};

export const setOptionValuesFromServiceField = (
  serviceField,
  defaultOptions,
  optionValues
) => {
  // For checkboxes (multiple options)
  serviceField.options = serviceField.options.map((option) => {
    // Find the matching fieldOption
    const fieldOption =
      defaultOptions.find(
        (opt) => opt.servicefieldoptionid === option.servicefieldoptionid
      ) || {}; // Provide a fallback to avoid undefined errors

    // Find the matching optionValue
    const matchedOption =
      optionValues.find(
        (opt) => opt.servicefieldoptionid === option.servicefieldoptionid
      ) || {};

    return {
      ...fieldOption,
      tripdetaileddayservicevalueid:
        matchedOption.tripdetaileddayservicevalueid || null,
      value: matchedOption.value ?? null // Ensure safe access
    };
  });

  return serviceField;
};

export const setOptionValueFromServiceField = (
  serviceField,
  optionValues,
  multiple = false
) => {
  const options = optionValues.map((option) => ({
    ...option,
    tripdetaileddayservicevalueid: !multiple
      ? serviceField.options[0].tripdetaileddayservicevalueid ?? null
      : option.tripdetaileddayservicevalueid ?? null,
    servicefieldoptionid: serviceField.servicefieldoptionid ?? null,
    servicefieldid: serviceField.servicefieldid ?? null,
    isvisible: serviceField.isvisible,
    value: typeof option === 'string' || !option ? option : option.value
  }));
  serviceField.options = options;

  return serviceField;
};

export const validateFormData = (fieldSettings, formData, t) => {
  let isValid = true;
  const formDataCopy = { ...formData };

  for (const fieldSetting of fieldSettings) {
    if (fieldSetting?.isrequired) {
      const serviceFieldId = fieldSetting?.servicefieldid;
      const formDataValue = formDataCopy[serviceFieldId];

      if (
        !formDataValue ||
        !formDataValue?.options ||
        !formDataValue?.options?.length
      ) {
        isValid = false;
      } else if (
        formDataValue?.options?.length === 1 &&
        !formDataValue?.options[0]?.value?.trim()
      ) {
        isValid = false;
        formDataCopy[serviceFieldId].error =
          `${fieldSetting.title} ${t('common:isRequired')}`;
      }
    }
  }

  return { isValid, newFormData: formDataCopy };
};

/**
 * Initializes the service type form data by setting default values to null.
 *
 * @param {Object} serviceType - The service type object containing service fields.
 * @returns {Object} - An object representing the initial form data where each field ID is mapped to null.
 */
export const initServiceTypeDayFormData = (serviceType) => {
  return serviceType?.servicetypefield?.reduce((formData, sTypeField) => {
    const hasMultipleOptions = ['checkbox', 'select'].includes(
      sTypeField?.servicefieldtype?.key
    );
    const hasFiles = ['image', 'file'].includes(
      sTypeField?.servicefieldtype?.key
    );

    const options = hasMultipleOptions
      ? [
          {
            tripdetaileddayservicevalueid: null,
            servicefieldoptionid: sTypeField?.servicefieldoptionid ?? null,
            value: null
          }
        ]
      : [
          {
            servicefieldoptionid: null,
            value: null,
            tripdetaileddayservicevalueid: null
          }
        ];
    formData[sTypeField.servicefieldid] = {
      servicefieldid: sTypeField?.servicefieldid ?? null,
      error: null,
      options,
      isvisible: true,
      servicefieldoptionid: sTypeField?.servicefieldoptionid ?? null,
      ...(hasFiles && { blobFiles: [] })
    };
    return formData;
  }, {});
};

export const getDefaultFieldOptions = (serviceType, servicefieldid) => {
  const field = serviceType.servicetypefield.find(
    (field) => field.servicefieldid === servicefieldid
  );

  if (!field) return [];

  return field.servicefieldoption.reduce((accumulator, option) => {
    accumulator[option.servicefieldoptionid] = option;
    return accumulator;
  }, {});
};

const getResourceUrl = (option, typeKey = 'file', data = {}) => {
  const config = {
    fileName: option?.value ?? '',
    pathReference: pathReference.DETAILED_DAY_SERVICE,
    ...data
  };

  return typeKey === 'file'
    ? getBucketFileUrl({
        ...config
      })
    : getBucketImageUrl({
        ...config,
        sizePrefix: imageSize.thumbnail.prefix
      });
};

export const setServiceTypeDayFormData = async (
  serviceType,
  fieldTypesMap,
  data = {}
) => {
  const formData = {}; // Initialize formData outside the reduce

  for (const sTypeField of serviceType.servicefields) {
    // Use a for...of loop
    const fieldType = fieldTypesMap[sTypeField.servicefieldid];

    const defaultField = serviceType.servicetypefield.find(
      (field) => field.servicefieldid === sTypeField.servicefieldid
    );

    if (!sTypeField.options || sTypeField.options.length === 0) {
      formData[sTypeField.servicefieldid] = sTypeField;
      continue; // Move to the next field
    }

    switch (fieldType.key) {
      case 'time':
      case 'date':
        formData[sTypeField.servicefieldid] = setOptionValueFromServiceField(
          sTypeField,
          sTypeField.options.map((opt) => ({
            ...opt, // Important: spread existing properties
            value: opt.value ? new Date(opt.value) : null
          }))
        );
        break;
      case 'checkbox':
        formData[sTypeField.servicefieldid] = setOptionValuesFromServiceField(
          sTypeField,
          defaultField.servicefieldoption,
          sTypeField.options
        );
        break;
      case 'image':
      case 'file':
        {
          const optionsWithFiles = await Promise.all(
            sTypeField?.options
              ?.filter((option) => !!option?.value)
              ?.map(async (option) => {
                const url = getResourceUrl(option, fieldType?.key, data);
                const file = await getFileFromUrl(
                  url,
                  option.value,
                  option?.value?.split('.')?.pop()
                );
                return {
                  ...option,
                  url: url,
                  file: file,
                  isNew: false
                };
              })
          );

          const files = sTypeField?.files?.map((file) => ({
            ...file,
            name:
              sTypeField?.options?.find(
                (option) =>
                  option?.tripdetaileddayservicevalueid ===
                  file?.tripdetaileddayservicevalueid
              )?.value ?? file?.name
          }));

          const newSTypeField = {
            ...sTypeField,
            files: files
          };
          formData[sTypeField.servicefieldid] = setOptionValueFromServiceField(
            newSTypeField,
            optionsWithFiles,
            true
          );
        }

        break;
      default:
        formData[sTypeField.servicefieldid] = setOptionValueFromServiceField(
          sTypeField,
          sTypeField.options.map((opt) => ({
            ...opt,
            value: opt.value?.toString() || ''
          }))
        );
        break;
    }
  }

  return formData;
};
