// @ts-nocheck
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { TenantSettingsPropTypes } from '../types';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './Amenities.css';

export const Amenities = ({ tenantsettings, amenities }) => {
  const { t } = useTranslation();

  const includedAmenities = useMemo(() => {
    return amenities?.filter((amenity) => amenity.include) || [];
  }, [amenities]);

  const excludedAmenities = useMemo(() => {
    return amenities?.filter((amenity) => amenity.exclude) || [];
  }, [amenities]);

  const checkAmenityDescription = (amenity) => {
    const description = amenity?.description || amenity?.tripAmenityDescription;
    return description ? `: ${description}` : '';
  };

  return (
    <Box
      className='amenities-section'
      id={`amenities-section`}
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
    >
      <Box className='amenities-section__included'>
        <h5
          className='amenities-section__title'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {t('Included')}
        </h5>
        <ul className='amenities-section__list'>
          {includedAmenities.map((amenity) => (
            <li key={amenity.amenityid}>
              <p
                style={{
                  fontFamily: tenantsettings?.bodyfont || 'inherit'
                }}
              >
                <b className='amenities-section__list-name'>{amenity.name}</b>
                {checkAmenityDescription(amenity)}
              </p>
            </li>
          ))}
        </ul>
      </Box>
      <Box className='amenities-section__excluded'>
        <h5
          className='amenities-section__title'
          style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
        >
          {t('Excluded')}
        </h5>
        <ul className='amenities-section__list'>
          {excludedAmenities.map((amenity) => (
            <li key={amenity.amenityid}>
              <p
                style={{
                  fontFamily: tenantsettings?.bodyfont || 'inherit'
                }}
              >
                <b className='amenities-section__list-name'>{amenity.name}</b>
                {checkAmenityDescription(amenity)}
              </p>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

Amenities.propTypes = {
  ...TenantSettingsPropTypes,
  amenities: PropTypes.arrayOf(
    PropTypes.shape({
      amenityid: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      included: PropTypes.bool,
      excluded: PropTypes.bool
    })
  )
};

export default Amenities;
