//@ts-nocheck
import { Box, InputLabel, FormHelperText } from '@mui/material';
import { XGCheckBox } from '@xg-cl/xg-cl';
import PropTypes from 'prop-types';

/**
 * Functional React component for rendering a checkbox group.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.id] - The ID for the checkbox group.
 * @param {Array} [props.options] - The options for the checkbox group.
 * @param {Array} [props.selectedOptions] - The selected options for the checkbox group.
 * @param {Function} [props.handleOnChange] - The function to handle the onChange event.
 * @param {string} [props.fieldId] - The field ID for the checkbox group.
 * @param {string} [props.label] - The label for the checkbox group.
 * @param {boolean} [props.isRequired] - Whether the checkbox group is required.
 * @param {string} [props.groupKey] - The group key for the checkbox group.
 * @param {string} [props.error] - The error message for the checkbox group.
 * @param {string} [props.description] - The description for the checkbox group.
 *
 * @returns {JSX.Element} React element representing the checkbox group.
 */
const CheckBoxGroup = ({
  id,
  options = [],
  selectedOptions = [],
  handleOnChange,
  fieldId,
  label,
  isRequired = false,
  groupKey,
  error,
  description,
  displayDirection = 'row',
  disabled
}) => {
  const fieldDescription = description ? ` - ${description.trim()}` : '';
  const sortedOptions = [...options].sort((a, b) => a?.sequence - b?.sequence);

  const isOptionSelected = (optionId) =>
    selectedOptions?.some((option) => option?.optionId == optionId);

  const handleCheckboxChange = (evt) => {
    const { dataset } = evt.target;
    const optionId = dataset?.optionid;

    const foundOption = options?.find((option) => option?.optionId == optionId);

    const updatedOptions = isOptionSelected(optionId)
      ? selectedOptions?.filter((option) => option?.optionId != optionId)
      : foundOption
        ? [...(selectedOptions || []), foundOption]
        : selectedOptions || [];

    handleOnChange(fieldId, optionId, updatedOptions);
  };

  return (
    <Box id={id}>
      <Box display='flex' flexDirection={displayDirection} gap='8px'>
        <InputLabel
          className='xg-input-label phone-input-label'
          htmlFor={`${groupKey}-input-${id}`}
          style={{ paddingTop: '15px' }}
          shrink={false}
        >
          {`${label ?? ''}${isRequired ? '*' : ''}${fieldDescription}`}{' '}
        </InputLabel>
        {sortedOptions.map((item, index) => (
          <XGCheckBox
            key={item?.optionId || index}
            label={item?.value}
            id={`checkbox-${index}`}
            value={item?.value}
            size='medium'
            style={{ margin: '0 12px', color: '#667085' }}
            name={`selected-${item?.value}`}
            checked={isOptionSelected(item?.optionId)}
            inputProps={{ 'data-optionid': item?.optionId }}
            onChange={handleCheckboxChange}
            disabled={disabled}
          />
        ))}
      </Box>
      {error && (
        <FormHelperText className='input-form-helper-text--error'>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

CheckBoxGroup.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array,
  selectedOptions: PropTypes.array,
  handleOnChange: PropTypes.func.isRequired,
  fieldId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  groupKey: PropTypes.string,
  error: PropTypes.string,
  description: PropTypes.string,
  displayDirection: PropTypes.string,
  disabled: PropTypes.bool
};

export default CheckBoxGroup;
