// @ts-nocheck
import globalConfig from './config/globalConfig';

if (globalConfig.isDevelop && globalConfig.reactScanIsEnabled == 'true') {
  import('react-scan').then(({ scan }) => {
    scan({
      enabled: globalConfig.reactScanIsEnabled
    });
  });
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import LDProvider from './context/LDProvider.jsx';
import './reset.css';
import './index.css';
import '@fontsource-variable/inter';
import { Provider } from 'react-redux';
import { store } from './store.js';
import './i18n';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <LDProvider>
        <App />
      </LDProvider>
    </Provider>
  </React.StrictMode>
);
