import HotelCard from './HotelCard';
import RestaurantCard from './RestaurantCard';
import ActivityCard from './ActivityCard';
import FlightCard from './FlightCard';
import TrainCard from './TrainCard';
import CarCard from './CarCard';
import TransferCard from './TransferCard';

export default {
  ActivityCard,
  CarCard,
  FlightCard,
  HotelCard,
  RestaurantCard,
  TrainCard,
  TransferCard
};
