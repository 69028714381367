// @ts-nocheck
import * as PropTypes from 'prop-types';
import {
  XGInput,
  XGDatePicker,
  XGTimePicker,
  XGTextarea,
  XGSelect,
  XGCheckBox,
  XGAttachments,
  XGImages
} from '@xg-cl/xg-cl';
import { MuiTelInput } from 'mui-tel-input';
import Box from '@mui/material/Box';
import {
  generateServiceTypeFieldId,
  generateServiceTypeFieldKey
} from '../../../../utils/serviceTypes/serviceTypes';
import { useCallback } from 'react';

const RenderField = ({ field }) => {
  const { title, servicefieldtype, servicefieldoption } = field;
  const key = generateServiceTypeFieldKey(field);
  const id = generateServiceTypeFieldId(field);

  switch (servicefieldtype.key) {
    case 'textbox':
    case 'number':
      return (
        <XGInput
          name={id}
          id={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
        />
      );
    case 'phone':
      return (
        <MuiTelInput
          name={id}
          id={id}
          key={key}
          defaultCountry='US'
          label={title}
          inputProps={{
            autoComplete: 'off',
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
        />
      );
    case 'url':
      return (
        <XGInput
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
          pattern='https?://.*'
        />
      );
    case 'date':
      return (
        <XGDatePicker
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
        />
      );
    case 'time':
      return (
        <XGTimePicker
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
        />
      );
    case 'textarea':
      return (
        <XGTextarea
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
        />
      );
    case 'select':
      return (
        <XGSelect
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
          options={servicefieldoption}
        />
      );
    case 'checkbox':
      return (
        <XGCheckBox
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          value={title}
          style={{ margin: '0px 12px', color: '#667085' }}
        />
      );
    case 'file':
      return (
        <XGAttachments
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
        />
      );
    case 'image':
      return (
        <XGImages
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={title}
          type={servicefieldtype.key}
          maxFileSize={5}
        />
      );
    default:
      return (
        <XGInput
          id={id}
          name={id}
          key={key}
          data-testid={id}
          label={title}
          placeholder={`${servicefieldtype.name}`}
        />
      );
  }
};

RenderField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  formData: PropTypes.object
};

const ServiceTypesInputRenderer = ({ id, serviceType, ...rest }) => {
  const renderFields = useCallback(() => {
    return serviceType.servicetypefield.map((field) => (
      <RenderField key={field.servicetypefieldid} field={field} />
    ));
  }, [serviceType]);

  if (!serviceType) return null;

  return (
    <Box
      id={id}
      data-testid='trip-service-types-skeleton'
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        padding: '24px',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '8px'
      }}
      {...rest}
    >
      {renderFields()}
    </Box>
  );
};

ServiceTypesInputRenderer.propTypes = {
  id: PropTypes.string,
  serviceType: PropTypes.object,
  onChange: PropTypes.func,
  formData: PropTypes.object
};

export default ServiceTypesInputRenderer;
