import globalConfig from '../config/globalConfig';

export const fileType = {
  IMAGE: 'image',
  FILE: 'file',
  VIDEO: 'video',
  AUDIO: 'audio'
};

export const pathReference = {
  TRIP: 'trip',
  DAY_ITENERARY: 'dayItenerary',
  CONTENT: 'content',
  DETAILED_DAY: 'detailedDay',
  DETAILED_DAY_SERVICE: 'detailedDayService',
  LIBRARY: 'library'
};

export const imageSize = {
  blured: { size: 200, prefix: 'BL' },
  thumbnail: { size: 400, prefix: 'TH' },
  small: { size: 600, prefix: 'SM' },
  medium: { size: 1204, prefix: 'MD' },
  large: { size: 2048, prefix: 'LG' }
};

export const imageRoute = {
  trip: {
    route: 'tenant/{tenantid}/trip/{tripid}'
  },
  dayItenerary: {
    route: 'tenant/{tenantid}/trip/{tripid}/tripdayitinerary'
  },
  content: {
    route: `tenant/{tenantid}/trip/{tripid}/tripcontent`
  },
  detailedDay: {
    route: 'tenant/{tenantid}/trip/{tripid}/tripdetailedday'
  },
  detailedDayService: {
    route: 'tenant/{tenantid}/trip/{tripid}/tripdetaileddayservice'
  },
  library: {
    route: 'tenant/{tenantid}/library'
  }
};

export const maxImageSize =
  Number(globalConfig?.maxImageSize) ?? 10 * 1024 * 1024; //Default 10MB
export const maxFileSize =
  Number(globalConfig?.maxFileSize) ?? 10 * 1024 * 1024; //Default 10MB
export const maxImages = 5;
export const maxAttachments = 1;
