// @ts-nocheck
import AuthLayout from './layouts/AuthLayout/AuthLayout';
import FinalBookingPayment from './pages/FinalBookingPayment/FinalBookingPayment';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ForgotPasswordSucess from './pages/ForgotPasswordSucess/ForgotPasswordSuccess';
import Login from './pages/Login/Login';
import SendPassword from './pages/SendPassword/SendPassword';
import SetNewPassword from './pages/SetNewPassword/SetNewPassword';
import ShellSkeleton from './components/Skeleton/ShellSkeleton/ShellSkeleton';
import Test from './components/Test/Test';
import ForgotPasswordError from './pages/ForgotPasswordError/ForgotPasswordError';
import Trip from './pages/Trip/Trip';
import Trips from './pages/Trips/Trips';
import Itineraries from './pages/Itineraries/Itineraries';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import SuccessfulPaymentMessage from './components/Payment/SuccessfulPaymentMessage/SuccessfulPaymentMessage';
import GlobalSettings from './pages/GlobalSettings/GlobalSettings';
import { createBrowserRouter, redirect, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import BookingModalPage from './pages/BookingModal/BookingModalPage';
import Library from './pages/Library/Library';
import TravelDoc from './components/Trip/DetailedItinerary/TravelDoc/TravelDoc';

const protectedRoute = () => {
  const accessToken = localStorage.getItem('accessToken');
  const userSession = localStorage.getItem('userSession');
  if (!accessToken || !userSession) {
    return redirect('/login?sessionExpired=true');
  }

  return null;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/trips' />
  },
  {
    path: '/trips',
    element: (
      <Suspense fallback={<ShellSkeleton id='trips-page' />}>
        <Trips id='trips-page' />
      </Suspense>
    ),
    loader: () => {
      const isValidSession = protectedRoute();

      if (isValidSession != null) {
        return redirect('/login?sessionExpired=true');
      }

      return {};
    }
  },
  {
    path: '/trips/:id',
    element: (
      <Suspense fallback={<ShellSkeleton id='trips-page' />}>
        <Trip id='trip-page' />
      </Suspense>
    ),
    loader: ({ params }) => {
      const { id: tripId } = params;
      const isValidSession = protectedRoute();
      if (isValidSession != null) {
        return redirect('/login?sessionExpired=true');
      }
      return { tripId };
    }
  },
  {
    path: '/itineraries/:id',
    element: <Itineraries id='output-page' />,
    loader: ({ params }) => {
      const { id: tripId } = params;

      return { tripId };
    }
  },
  {
    path: '/itineraries/:id/travel-doc',
    element: <TravelDoc />,
    loader: ({ params }) => {
      const { id: tripId } = params;

      return { tripId };
    }
  },
  {
    path: '/global-settings',
    element: (
      <Suspense fallback={<ShellSkeleton id='global-settings-skeleton' />}>
        <GlobalSettings id='global-settings-page' />
      </Suspense>
    ),
    loader: () => {
      const isValidSession = protectedRoute();

      if (isValidSession != null) {
        return redirect('/login?sessionExpired=true');
      }

      return {};
    }
  },
  {
    path: '/bookings/:id/payment',
    element: <FinalBookingPayment id='final-booking-payment' />,
    loader: ({ params }) => {
      const { id: bookingId } = params;

      return { bookingId };
    }
  },
  {
    path: '/bookings/:id/payment/success',
    element: <SuccessfulPaymentMessage id='successful-payment' />
  },
  {
    path: '/login',
    element: (
      <AuthLayout id='auth-layout'>
        <Login id='login-page' />
      </AuthLayout>
    )
  },
  {
    path: '/forgot-password',
    element: (
      <AuthLayout id='auth-layout'>
        <ForgotPassword id='forgot-password-page' />
      </AuthLayout>
    )
  },
  {
    path: '/forgot-password/success',
    element: (
      <AuthLayout id='auth-layout'>
        <ForgotPasswordSucess id='forgot-password-success-page' />
      </AuthLayout>
    )
  },
  {
    path: '/forgot-password/error',
    element: (
      <AuthLayout id='auth-layout'>
        <ForgotPasswordError id='forgot-password-error-page' />
      </AuthLayout>
    )
  },
  {
    path: '/forgot-password/send',
    element: (
      <AuthLayout id='auth-layout'>
        <SendPassword id='send-password-page' />
      </AuthLayout>
    )
  },
  {
    path: '/forgot-password/new/:resetToken',
    element: (
      <AuthLayout id='auth-layout'>
        <SetNewPassword id='set-new-password-page' />
      </AuthLayout>
    )
  },
  {
    path: '/test',
    element: <Test />
  },
  {
    path: '*',
    element: (
      <AuthLayout id='auth-layout'>
        <NotFoundPage id='not-found-page' />
      </AuthLayout>
    )
  },
  {
    path: '/shared/trip/:tripid/booking',
    element: <BookingModalPage />
  },
  {
    path: '/library',
    element: (
      <Suspense fallback={<ShellSkeleton id='library-page' />}>
        <Library id='library-page' />
      </Suspense>
    )
  }
]);
