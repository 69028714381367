// @ts-nocheck

import PassengersHeader from '../PassengersHeader/PassengersHeader';
import PassengersUpdateModal from '../PassengersUpdateModal/PassengersUpdateModal';
import PassengersSkeleton from '../PassengersSkeleton/PassengersSkeleton';
import { useTranslation } from 'react-i18next';
import { WarningCircle } from '@phosphor-icons/react';
import { useLoaderData } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { XGChip } from '@xg-cl/xg-cl';
import { useEffect, useState, useCallback } from 'react';
import { format as formatDate, utcToZonedTime } from 'date-fns-tz';
import { useSelector, useDispatch } from 'react-redux';
import { updateAllRows } from '../../../../redux/slices/passengers/passengers';
import { getTripBookingPassenger } from '../../../../services/booking/booking';
import { getTripPassengerFields } from '../../../../services/trip/trip';
import { generateKey } from '../../../../utils/common';
import './PassengersPanel.css';

const PassengersPanel = () => {
  const { t } = useTranslation(['passengers']);
  const { tripId } = useLoaderData();
  const [initialStateRows, setInicialStateRows] = useState([]);
  const [initialStateColumns, setInicialStateColumns] = useState([]);
  const [visibleFields, setVisibleFields] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectColumns, setSelectColumns] = useState([]);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [bookingPassenger, setBookingPassenger] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [passengersData, setPassengersData] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [modalPassengerFields, setModalPassengerFields] = useState([]);
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.passengers);
  const gridApiRef = useGridApiRef();

  const handleCellClickEvent = (params) => {
    const { field, id } = params;

    if (field === 'firstname') {
      const foundRow = rows.rows.find((item) => item.id == id);

      if (foundRow && passengersData[id]) {
        const filteredPassengers = passengersData[id]?.passengerfields.filter(
          (passenger) =>
            settingsData?.some(
              (tripPassenger) =>
                tripPassenger.trippassengerfieldid ===
                passenger.trippassengerfieldid
            )
        );

        const mapFilteredPassengers = filteredPassengers
          ?.map((el) => {
            const foundField = settingsData.find(
              (field) => field.trippassengerfieldid === el.trippassengerfieldid
            );
            const value = el?.options?.map((option) => option.value)?.join(',');

            if (foundField) {
              return {
                ...el,
                ...foundField,
                key: generateKey(foundField?.title),
                value,
                id
              };
            } else {
              return { ...el, key: generateKey(el?.title), value, id };
            }
          })
          ?.sort((a, b) => a.sequence - b.sequence);

        setBookingPassenger(passengersData[id]);
        setModalPassengerFields(mapFilteredPassengers);
        setOpenUpdateDialog(true);
      }
    }
  };

  const toggleUpdateDialog = () => {
    setOpenUpdateDialog(!openUpdateDialog);
  };

  const handleColumnsFilter = (selectedColumns) => {
    const columnsCopy = [...initialStateColumns];
    const rowsCopy = [...initialStateRows];

    const filteredColumns = columnsCopy.filter((column) => {
      return selectedColumns.includes(column.field);
    });

    setColumns(filteredColumns);

    const filteredRows = rowsCopy.map((passenger) => {
      return selectedColumns.reduce((contactField, field) => {
        if (passenger[field] !== undefined) {
          contactField[field] = passenger[field];
        }
        return contactField;
      }, {});
    });

    dispatch(
      updateAllRows({
        rows: filteredRows
      })
    );
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const [passengerFieldsResult, bookingPassengersResult] =
        await Promise.allSettled([
          getTripPassengerFields(tripId),
          getTripBookingPassenger(tripId)
        ]);

      // Handle results based on status (fulfilled, rejected)
      if (
        passengerFieldsResult.status === 'fulfilled' &&
        bookingPassengersResult.status === 'fulfilled'
      ) {
        setSettingsData(passengerFieldsResult.value?.data ?? []);
        setPassengersData(bookingPassengersResult.value?.data ?? []);
      } else {
        setSettingsData([]);
        setPassengersData([]);
      }
    } catch (error) {
      setSettingsData([]);
      setPassengersData([]);
    } finally {
      setIsLoading(false);
    }
  }, [tripId, setSettingsData, setPassengersData, setIsLoading]);

  const updatePassengerField = (updatedField) => {
    const {
      bookingid,
      bookingpassengerid,
      trippassengerfieldid,
      options = []
    } = updatedField;
    const newPassengerData = [...passengersData];

    const index = newPassengerData.findIndex(
      (field) =>
        field.bookingid === bookingid &&
        field.bookingpassengerid === bookingpassengerid
    );

    if (index !== -1) {
      const newFields = newPassengerData[index].passengerfields.map(
        (passengerField) => {
          if (passengerField.trippassengerfieldid === trippassengerfieldid) {
            return {
              ...passengerField,
              options: options
            };
          }
          return passengerField;
        }
      );
      newPassengerData[index] = {
        ...newPassengerData[index],
        passengerfields: newFields
      };
      setPassengersData(newPassengerData);
    }
  };

  useEffect(() => {
    const flattenPassenger = (passengersData) => {
      const { bookingid, passengerfields = [] } = passengersData;

      const fields = settingsData?.filter(
        (field) => field.isoptional || field.isrequired
      );

      const mappedFields = fields.map((field) => {
        return {
          ...field,
          field: generateKey(field?.title),
          label: field?.title
        };
      });

      const filteredVisible = mappedFields.filter((item) =>
        visibleFields.includes(item.field)
      );

      const filteredContact = filteredVisible?.reduce((customField, field) => {
        for (const passengerField of passengerfields) {
          const { trippassengerfieldid, options = [] } = passengerField;
          const { fieldtype } = field;

          const matchingOption =
            trippassengerfieldid === field.trippassengerfieldid;

          if (matchingOption) {
            customField[field?.field] = options
              .map((option) => {
                if (fieldtype?.key === 'date') {
                  return option?.value
                    ? formatDate(
                        utcToZonedTime(
                          new Date(option?.value?.split('T')[0].toString()),
                          'UTC'
                        ),
                        'dd MMM yyyy',
                        { timeZone: 'UTC' }
                      )
                    : '';
                } else {
                  return option.value;
                }
              })
              .join(', ');
            break;
          }
        }
        return customField;
      }, {});
      return { bookingid, ...filteredContact };
    };

    const generatedRows =
      !isLoading && passengersData
        ? passengersData?.map((passenger, index) => ({
            id: index,
            ...flattenPassenger(passenger)
          }))
        : [];

    dispatch(
      updateAllRows({
        rows: generatedRows
      })
    );
    setInicialStateRows(generatedRows);
  }, [dispatch, passengersData, visibleFields, isLoading, settingsData]);

  useEffect(() => {
    const generateColumns = (fields) => {
      if (!fields.length) return [];
      const bookingid = {
        field: 'bookingid',
        headerName: 'Booking ID',
        renderCell: (params) => (
          <XGChip
            id='passenger-panel-grid-chip'
            text={`#${params.value}`}
            size='small'
            type='default'
            variant='outlined'
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        ),
        width: 150
      };

      const titles = fields.map((field) => {
        return {
          field: field?.field,
          headerName: field?.title,
          width: field?.field?.indexOf('email') !== -1 ? 300 : 200,
          cellClassName:
            field?.field === 'firstname'
              ? 'passengers-panel-data-grid-firstname'
              : null,
          trippassengerfieldid: field?.trippassengerfieldid
        };
      });

      return [bookingid, ...titles];
    };

    if (settingsData?.length > 0) {
      const fields = settingsData?.filter(
        (field) => field.isoptional || field.isrequired
      );

      const mappedFields = fields.map((field) => {
        return {
          ...field,
          field: generateKey(field?.title),
          label: field?.title
        };
      });

      setSelectColumns(mappedFields);
      const generatedColumns = generateColumns(mappedFields);

      setColumns(generatedColumns);
      setInicialStateColumns(generatedColumns);

      const visibleFields = mappedFields.map((field) => field.field);

      setVisibleFields(visibleFields);
    }
  }, [settingsData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box height={rows.rows.length === 0 ? '100px' : 'auto'}>
      {isLoading ? (
        <PassengersSkeleton id='booking-passengers-skeleton' />
      ) : !isLoading &&
        passengersData &&
        (passengersData.length === 0 ||
          (settingsData && settingsData?.length === 0)) ? (
        <div className='no-passengers-found'>
          <div className='no-trips-found__search-container'>
            <WarningCircle size={32} />
          </div>
          <h2 className='h3-semibold no-passengers-found__title'>
            {t('common:noPassengersExist')}
          </h2>
        </div>
      ) : (
        !isLoading &&
        passengersData?.length &&
        settingsData?.length && (
          <>
            <PassengersHeader
              options={selectColumns}
              onUpdateColumns={handleColumnsFilter}
              rows={rows}
              columns={columns}
              gridApiRef={gridApiRef}
            />
            <DataGrid
              className='passengers-datagrid'
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  background: 'var(--gray-50)'
                }
              }}
              columns={columns}
              rows={rows?.rows}
              onCellClick={handleCellClickEvent}
              initialState={{
                columns: { columnVisibilityModel: { id: false } }
              }}
              hideFooter
              disableColumnMenu
              disableColumnFilter
              disableRowSelectionOnClick={true}
              autosizeOnMount
              localeText={{ noRowsLabel: t('common:noDataAvailable') }}
              apiRef={gridApiRef}
            />

            <PassengersUpdateModal
              open={openUpdateDialog}
              setOpen={toggleUpdateDialog}
              bookingPassenger={bookingPassenger}
              rowPassengerFields={modalPassengerFields}
              updatePassengerField={updatePassengerField}
              tripId={tripId}
            />
          </>
        )
      )}
    </Box>
  );
};

export default PassengersPanel;
