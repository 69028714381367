//@ts-nocheck
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { PlusCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { XGDropdownMenu } from '@xg-cl/xg-cl';
import './ServiceTypesTabPanel.css';
import * as PropTypes from 'prop-types';
import { getServiceTypes } from '../../../../services/servicetype/servicetype';
import ServiceTypesButtonSkeleton from '../ServiceTypesButtonSkeleton/ServiceTypesButtonSkeleton';
import ServiceTypesInputRenderer from '../ServiceTypesInputRenderer/ServiceTypesInputRenderer';
import { formatServiceTypeOptions } from '../../../../utils/serviceTypes/serviceTypes';

/**
 * Render the service types panel
 *
 * @param {string} id - The component unique identifier
 * @return {JSX.Element} The trip passenger fields panel component
 */
const ServiceTypesTabPanel = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const { t } = useTranslation(['services', 'common', 'errors']);

  const fetchServiceTypes = async () => {
    try {
      const { data = [] } = await getServiceTypes();

      if (data?.length > 0) {
        setServiceTypes(data);
      }
    } catch (error) {
      setServiceTypes([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchServiceTypes();
  }, []);

  const onServiceTypeClick = (event, serviceType) => {
    setSelectedServiceType(serviceType);
  };

  return (
    <Box
      id={id}
      className='trip__service-types-panel'
      data-testid='global-service-types-panel'
    >
      {isLoading ? (
        <ServiceTypesButtonSkeleton id='service-types-button-skeleton' />
      ) : (
        <>
          <Box className={'trip__service-types-panel__button'}>
            <XGDropdownMenu
              isButton={true}
              btnType='primary'
              btnVariant='contained'
              btnSize='large'
              btnText={
                selectedServiceType
                  ? selectedServiceType?.name
                  : t('serviceTypes')
              }
              startIcon={<PlusCircle />}
              options={formatServiceTypeOptions(
                serviceTypes,
                onServiceTypeClick
              )}
              id='service-types-dropdown-menu'
            />
          </Box>
          <Box className={'trip__service-types-panel__fields'}>
            <ServiceTypesInputRenderer serviceType={selectedServiceType} />
          </Box>
        </>
      )}
    </Box>
  );
};

ServiceTypesTabPanel.propTypes = {
  id: PropTypes.string
};

export default ServiceTypesTabPanel;
