import { Box, Skeleton } from '@mui/material';
import './TravelDocSkeleton.css';

const TravelDocSkeleton = () => {
  return (
    <Box className='skeleton'>
      {/* Header Section */}
      <Box className='skeleton__header'>
        <Skeleton
          variant='rectangular'
          width={200}
          height={60}
          className='skeleton__logo'
        />
      </Box>

      <Box className='skeleton__title-container'>
        <Skeleton variant='text' height={'100px'} className='skeleton__title' />
        <Skeleton variant='text' height={40} className='skeleton__date' />
      </Box>

      <Skeleton
        variant='text'
        width='100%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='90%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='95%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='85%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='60%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='100%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='85%'
        height={24}
        className='skeleton__description'
      />
      <Skeleton
        variant='text'
        width='67%'
        height={24}
        className='skeleton__description'
        style={{
          marginBottom: '40px'
        }}
      />

      {/* Image */}
      <Skeleton
        variant='rectangular'
        width='100%'
        height={300}
        className='skeleton__image'
      />

      {/* Day 1 Card */}
      <Box className='skeleton__day-card'>
        <Skeleton
          variant='rectangular'
          width={60}
          height={24}
          className='skeleton__day-badge'
        />
        <Skeleton
          variant='text'
          width='30%'
          height={24}
          className='skeleton__day-title'
        />
        <Skeleton
          variant='text'
          width='50%'
          height={32}
          className='skeleton__day-title'
        />
        <Skeleton
          variant='text'
          width='90%'
          height={20}
          className='skeleton__day-description'
        />
        <Skeleton
          variant='text'
          width='80%'
          height={20}
          className='skeleton__day-description'
        />
        <Skeleton
          variant='text'
          width='70%'
          height={20}
          className='skeleton__day-description'
        />
      </Box>
    </Box>
  );
};

export default TravelDocSkeleton;
