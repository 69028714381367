// @ts-nocheck
import ServiceCard from './ServiceCards/ServiceCard';
import { Box } from '@mui/material';
import * as PropTypes from 'prop-types';
import './ServicesView.css';

/**
 * ServicesView component renders a list of services from a trip itinerary.
 *
 * @param {Object[]} services - The list of services in the trip itinerary.
 * @param {boolean} isLoading - The loading status of the services.
 * @param {Object} error - The error object if there is an error.
 * @param {function} onSelectService - The callback function when a service is selected.
 * @param {number} tripid - The ID of the trip.
 * @param {number} tenantid - The ID of the tenant.
 *
 * @returns {JSX.Element} - The JSX element representing the services view.
 */
const ServicesView = ({
  services = [],
  isLoading = true,
  error = null,
  onSelectService,
  tripid,
  tenantid
}) => {
  if (isLoading || !services || error) {
    return <></>;
  }

  const servicesList = services?.map((service) => {
    const { servicetype = {}, servicefields = [] } = service;
    const serviceTypeFields = servicetype?.servicetypefield ?? [];
    const mappedServiceFields = servicefields?.map((field) => {
      const foundServiceTypeField = serviceTypeFields.find(
        (item) =>
          item?.servicefield?.servicefieldid === field?.servicefieldid ||
          item?.servicefieldid === field?.servicefieldid
      );

      const key =
        foundServiceTypeField?.key ??
        foundServiceTypeField?.servicefield?.key ??
        '';

      return { ...field, key };
    });

    return {
      ...service,
      servicefields: mappedServiceFields,
      tripdetaileddayserviceid: service?.tripdetaileddayserviceid
    };
  });

  return (
    <Box
      className='services-view'
      id='services-view'
      data-testid='services-view'
    >
      {servicesList?.map((service, index) => {
        return (
          <ServiceCard
            key={index}
            service={service}
            tripid={tripid}
            tenantid={tenantid}
            onSelectService={onSelectService}
          />
        );
      })}
    </Box>
  );
};

ServicesView.propTypes = {
  servicetype: PropTypes.object,
  servicefields: PropTypes.array,
  tripdetaileddayserviceid: PropTypes.number,
  services: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  onSelectService: PropTypes.func,
  tripid: PropTypes.number,
  tenantid: PropTypes.number
};

export default ServicesView;
