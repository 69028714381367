// @ts-nocheck
import * as PropTypes from 'prop-types';
import TenantLogo from '../../../../../TenantLogo/TenantLogo';
import './Branding.css';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TenantSettingsPropTypes, TripDetailedDayPropTypes } from '../../types';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { formatDateWithAddedDays } from '../../../../../../utils/dateUtility';

/**
 * Functional React component for rendering a custom branding header.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties.
 * @param {string} [props.id] - The ID for the branding element.
 * @param {Object} [props.tenant] - The tenant data object.
 * @param {string} [props.tenant.tenantlogo] - The tenant logo
 * @param {string} [props.tenant.tenantphone] - The tenant phone
 * @param {string} [props.tenant.tenantname] - The tenant name
 * @param {string} [props.tenant.tenanturl] - The tenant website url
 * @param {Object} [props.rest] - Additional props to be spread onto the parent element.
 *
 * @returns {JSX.Element} React element representing the branding component.
 */

const Branding = ({ tenant, trip, tenantSettings, ...rest }) => {
  const { i18n } = useTranslation(['trip', 'common']);
  const locale = i18n.language;

  if (!trip || !tenantSettings) return null;

  const dateRange = formatDateWithAddedDays(
    trip.startdate,
    trip.duration,
    locale
  );
  return (
    <Box className='branding-container' {...rest}>
      <TenantLogo
        tenantLogo={tenant?.tenantlogo}
        tenantName={tenant?.tenantname}
      />
      <Box className='header__date'>
        <CalendarTodayOutlined
          className='header__date-icon'
          style={{ width: '10px' }}
        />
        <p
          className='header__date-text'
          style={{ fontFamily: tenantSettings?.headingfont }}
        >
          {dateRange}
        </p>
      </Box>
    </Box>
  );
};

/**
 * Prop type definitions for Branding.
 */
Branding.propTypes = {
  id: PropTypes.string.isRequired,
  ...TripDetailedDayPropTypes,
  tenantSettings: TenantSettingsPropTypes,
  tenant: PropTypes.shape({
    tenantlogo: PropTypes.string,
    tenantphone: PropTypes.string,
    tenantname: PropTypes.string,
    tenanturl: PropTypes.string
  })
};

export default Branding;
