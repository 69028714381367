// @ts-nocheck
import { Box } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

const AttachmentNotice = ({ attachments }) => {
  if (!attachments || attachments.length < 1) return null;

  return (
    <Box className='service-section__attachments'>
      <Info />
      <p>
        Attachment can be found in a separate document provided by your
        operator.
      </p>
    </Box>
  );
};

AttachmentNotice.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.string)
};
export default AttachmentNotice;
