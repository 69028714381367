// @ts-nocheck
import ServiceCardImages from '../ServiceCardImages/ServiceCardImages';
import ServiceCardAttachments from '../ServiceCardAttachments/ServiceCardAttachments';
import Editor from '../../../../Editor/Editor';
import { Box } from '@mui/material';
import { CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/dateUtility';
import * as PropTypes from 'prop-types';

/**
 * A service card component for hotel accommodations.
 *
 * @param {Object} servicefields - the fields of the service including hotel details
 * @param {string} tripdetaileddayserviceid - the id of the tripdetaileddayservice
 * @returns {JSX.Element} - The JSX element representing a hotel service card
 */

const HotelCard = ({ servicefields, tripdetaileddayserviceid }) => {
  const { t } = useTranslation(['services']);

  return (
    <Box id={`service-card-hotel-${tripdetaileddayserviceid}`}>
      <Box className='service-view-card__content'>
        <Box className='service-view-card__content-row'>
          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('hotelName')}:</span> {servicefields.HOTELNAME || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('contactTel')}:</span> {servicefields.PHONENUMBER || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('address')}:</span> {servicefields.ADDRESS || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('roomType')}:</span> {servicefields.ROOMTYPE || ''}
            </p>
            <p className='service-view-card__label-value'>
              <a href={servicefields.LINK || ''}>{t('website')}</a>
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('checkInDate')}:</span>{' '}
              {formatDate(servicefields.CHECKINDATE, 'YYYY-MM-DD')}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('checkOutDate')}:</span>{' '}
              {formatDate(servicefields.CHECKOUTDATE, 'YYYY-MM-DD')}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('numberOfNights')}:</span>{' '}
              {servicefields.NUMBEROFNIGHTS || ''}
            </p>
          </Box>
        </Box>

        <Box>
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-editor-hotel-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-view-card__description'
            />
          )}

          <Box className='service-view-card__confirmation'>
            <p>
              <span>{t('confirmation')}:</span>{' '}
              {servicefields.CONFIRMATIONINFORMATION || ''}
            </p>
          </Box>
        </Box>

        {servicefields?.MEALSINCLUDED?.length > 0 && (
          <Box className='service-view-card__include-values'>
            {servicefields?.MEALSINCLUDED?.map((meal, index) => (
              <Box
                className='service-view-card__include-values__item'
                key={`meals-included-${meal.toLowerCase()}-${index}`}
              >
                <CheckCircle />
                <p>{meal}</p>
              </Box>
            ))}
          </Box>
        )}

        {(servicefields.UPLOADIMAGES || servicefields.UPLOADATTACHMENTS) && (
          <Box className='service-view-card__files-section'>
            {servicefields?.UPLOADIMAGES && (
              <ServiceCardImages images={servicefields?.UPLOADIMAGES} />
            )}
            {servicefields?.UPLOADATTACHMENTS && (
              <ServiceCardAttachments
                attachments={servicefields?.UPLOADATTACHMENTS}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

HotelCard.propTypes = {
  servicefields: PropTypes.object,
  servicetype: PropTypes.object,
  tripdetaileddayserviceid: PropTypes.number
};

export default HotelCard;
