// @ts-nocheck
const hostname = window.location.hostname;

// Because originally the absolute path was saved in the db,
// this enhancement was implemented to work with the old images,
// the new images will have to use another utility.

export const getOldCoverImageUrl = (imageUrl = null) => {
  if (imageUrl) {
    if (hostname == 'dev.softrip.com') {
      return imageUrl;
    } else {
      return `/images/${imageUrl.split('/').pop()}`;
    }
  }
  return null;
};

// Here you can create the utility to generate the path to access
// the images with the new file upload service.
