// @ts-nocheck
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Editor from '../../../../Editor/Editor';
import './AdditionalInformation.css';
import { TenantSettingsPropTypes } from '../types';
import { getOldCoverImageUrl } from '../../../../../utils/getCoverImageUrl';

const AdditionalInformation = ({ content, tenantsettings }) => {
  const { t } = useTranslation();

  if (!content) return null;
  return (
    <div
      className='additional-information'
      fontFamily={tenantsettings?.bodyfont || 'inherit'}
    >
      <h2
        className='additional-information__title'
        style={{ fontFamily: tenantsettings?.headingfont || 'inherit' }}
      >
        {t('Additional Information')}
      </h2>
      {content.map(
        ({ tripcontentid, title, description, tripcontentimage }) => {
          const hasImage = tripcontentimage.length > 0;
          const imageUrl = hasImage ? tripcontentimage[0].imageurl : null;

          return (
            <div
              key={tripcontentid}
              className={`additional-information__row ${hasImage ? 'with-image' : ''}`}
            >
              <div className='additional-information__column'>
                <h3
                  style={{
                    fontFamily: tenantsettings?.headingfont || 'inherit'
                  }}
                  className='additional-information__title-column'
                >
                  {title}
                </h3>
              </div>

              <div
                className={`additional-information__column additional-information__description ${hasImage ? '' : 'full-width'}`}
              >
                <Editor
                  id={`service-section-restaurant-${tripcontentid}-description`}
                  content={description}
                  editable={false}
                  isPreviewing={true}
                  className='additional-information__description__editor'
                  style={{
                    fontFamily: tenantsettings?.bodyfont || 'inherit'
                  }}
                />
              </div>

              {hasImage && (
                <div className='additional-information__column additional-information__image'>
                  <img src={getOldCoverImageUrl(imageUrl)} alt={title} />
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

AdditionalInformation.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      tripcontentid: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tripcontentimage: PropTypes.arrayOf(
        PropTypes.shape({
          tripcontentimageid: PropTypes.number.isRequired,
          seq: PropTypes.number.isRequired,
          imageurl: PropTypes.string.isRequired
        })
      )
    })
  ),
  tenantsettings: TenantSettingsPropTypes,
  tripid: PropTypes.number,
  tenantid: PropTypes.number
};

export default AdditionalInformation;
