// @ts-nocheck
import AIGenerateButton from '../../AIGenerateButton/AIGenerateButton';
import Editor from '../../Editor/Editor';
import InlineEditor from '../../InlineEditor/InlineEditor';
import inputRules from '../../../constants/inputRules';
import ImagePicker from '../../Upload/ImagePicker/ImagePicker';
import { fileType, pathReference } from '../../../constants/files';
import { Box } from '@mui/material';
import { add, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { XGChip } from '@xg-cl/xg-cl';
import { parseISODateWithoutOffset } from '../../../utils/dateUtility';
import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { getLastListItem } from '../../../utils/common';
import { getOldCoverImageUrl } from '../../../utils/getCoverImageUrl';
import * as PropTypes from 'prop-types';
import './DetailedItineraryDay.css';
import '../../Draggable/Draggable.css';
const MemoizedImagePicker = memo(ImagePicker);

/**
 * Functional React component for rendering a custom trip detailed day content.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {String} [props.id] - The ID for the trip detailed day component.
 * @param {Object} [props.data] - Object containing the data to build the trip detailed day content.
 * @param {Func} [props.updateDay] - The method to be used for updating days. Default will be updateTripDay
 * @param {...any} props.rest - Additional props to be spread on the element.
 *
 * @returns {JSX.Element} React element representing the trip detailed day.
 */

const DetailedItineraryDay = ({ id, data, updateDay, ...rest }) => {
  const { t } = useTranslation(['trip', 'common', 'library']);
  const {
    tripid,
    description,
    note,
    title,
    daynumber,
    startdate,
    tripdetaileddayimage,
    tripdetaileddayid
  } = data || {};

  //Status definitions
  const [isEditorPreviewing, setIsEditorPreviewing] = useState(true);
  const [isNoteEditorPreviewing, setIsNoteEditorPreviewing] = useState(true);
  const [isEditorDisabled, setIsEditorDisabled] = useState(false);
  const [tripDayDescription, setTripDayDescription] = useState(description);
  const [markdown, setMarkdown] = useState(description);
  const [internalNote, setInternalNote] = useState(note);
  const [internalNoteMarkdown, setInternalNoteMarkdown] = useState(note);

  const incomingDate = startdate && parseISODateWithoutOffset(startdate);
  const date =
    startdate && add(new Date(incomingDate), { days: daynumber - 1 });
  const dayOfWeekName = date
    ? date
        .toLocaleDateString('en-US', {
          weekday: 'long'
        })
        .toLowerCase()
    : '';
  const serviceDate =
    date &&
    `${t(dayOfWeekName, { ns: 'common' })} · ${format(
      new Date(date),
      `${t('dateFormat', { ns: 'common' })}`
    )}`;

  const dayCoverImage = useMemo(() => {
    const lastImage = getLastListItem(tripdetaileddayimage ?? []);
    return lastImage?.imageurl
      ? getOldCoverImageUrl(lastImage?.imageurl)
      : null;
  }, [tripdetaileddayimage]);

  //Cancels editor
  const onCancelEditor = () => {
    setMarkdown(description);
    setTripDayDescription(description);
  };

  //Cancels note editor
  const onCancelNoteEditor = () => {
    setInternalNoteMarkdown(note);
    setInternalNote(note);
  };

  //Saves editor content
  const onSaveEditor = async () => {
    updateDay(tripdetaileddayid, {
      description: `${markdown}`
    });
  };

  //Saves note content
  const onSaveNote = async () => {
    updateDay(tripdetaileddayid, {
      note: `${internalNoteMarkdown}`
    });
  };

  //Updates trip day title
  const updateTripDayTitle = async (newTripDayTitle) => {
    updateDay(tripdetaileddayid, {
      title: newTripDayTitle?.trim()
    });
  };

  //Updates trip day cover image
  const updateDayCoverImage = useCallback(
    async (newImageUrl) => {
      const result = await updateDay(
        tripdetaileddayid,
        {
          image: newImageUrl
        },
        true
      );

      if (!result) {
        return { data: false };
      }

      return { data: true };
    },
    [tripdetaileddayid, updateDay]
  );

  const onPreviewDescription = (value = false) => {
    if (!value) {
      setIsEditorPreviewing(false);
      setIsNoteEditorPreviewing(true);
    } else {
      setIsEditorPreviewing(true);
    }
  };

  const onPreviewNote = (value = false) => {
    if (!value) {
      setIsEditorPreviewing(true);
      setIsNoteEditorPreviewing(false);
    } else {
      setIsNoteEditorPreviewing(true);
    }
  };

  useEffect(() => {
    if (tripdetaileddayid) {
      setTripDayDescription(description || '');
      setMarkdown(description || '');
      setIsEditorPreviewing(true);
      setIsNoteEditorPreviewing(true);
      setInternalNoteMarkdown(note || '');
      setInternalNote(note || '');
    }
  }, [tripdetaileddayid, description, note]);

  useEffect(() => {
    setInternalNote(note ?? '');
  }, [note]);

  return (
    <>
      <Box
        id={id}
        className={`detailed-itinerary-day__wrapper `}
        data-testid='detailed-itinerary-day-component'
        {...rest}
      >
        <Box
          id='detailed-itinerary-day-header'
          className={`detailed-itinerary-day__header droppable-padding `}
          data-testid='detailed-itinerary-day-header'
        >
          <div
            style={{
              width: '100%'
            }}
          >
            <MemoizedImagePicker
              callback={updateDayCoverImage}
              id={`image-picker-component`}
              url={dayCoverImage}
              tripId={tripid}
              pathReference={pathReference.DETAILED_DAY}
              fileType={fileType.IMAGE}
              useVersion={true}
            />
          </div>

          <Box className='detailed-itinerary-day__header-container'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='text-md-semibold service-content-title'>
                {`${t('day')} #${daynumber}${
                  title && title !== '' ? ` - ` : ''
                }`}
              </span>
              <InlineEditor
                action={updateTripDayTitle}
                className='text-md-semibold service-content-title'
                id='tripname-inline-editor'
                initialValue={title}
                isValueRequired={false}
                maxTextLength={inputRules.maxTitleLength}
                placeholder={`${t('enterTitle')}`}
                style={{ maxWidth: '750px' }}
              />

              {serviceDate && (
                <XGChip
                  id={`${id}-date-chip`}
                  data-testid='resevation-day-date-chip'
                  text={serviceDate}
                  type={'default'}
                  variant='outlined'
                  size='small'
                />
              )}
            </div>
          </Box>

          <div className='detailed-itinerary-day__editor-description'>
            {tripDayDescription && tripDayDescription !== '' && (
              <p className='MuiTypography-root MuiTypography-body2'>
                <span className='w-50'>{t('trip:description')} </span>
              </p>
            )}

            <Editor
              editable={true}
              isPreviewing={isEditorPreviewing}
              content={tripDayDescription}
              id={`detailed-day-editor-description-${tripdetaileddayid}`}
              setDescription={() => {}}
              setMarkdown={setMarkdown}
              setIsPreviewing={onPreviewDescription}
              showActions={true}
              onCancelAction={onCancelEditor}
              onSaveAction={onSaveEditor}
              placeholder={`${t('enterDescription')}`}
              generatePrompt={
                !isEditorPreviewing ? (
                  <div>
                    <AIGenerateButton
                      id='ai-generate-trip-description-button'
                      setMarkdown={setMarkdown}
                      setTripDescription={setTripDayDescription}
                      editorContent={markdown}
                      setIsEditorDisabled={setIsEditorDisabled}
                      disableGenerate={true}
                    />
                  </div>
                ) : null
              }
              isEditorDisabled={isEditorDisabled}
            />
          </div>

          <div
            className='detailed-day-editor-description__container'
            style={{ marginTop: '32px' }}
          >
            {internalNote && internalNote !== '' && (
              <p className='MuiTypography-root MuiTypography-body2'>
                <span className='w-50'>{t('trip:internalNotes')} </span>
              </p>
            )}

            <Editor
              editable={true}
              isPreviewing={isNoteEditorPreviewing}
              content={internalNote}
              id={`detailed-day-editor-note-${tripdetaileddayid}`}
              setDescription={() => {}}
              setMarkdown={setInternalNoteMarkdown}
              setIsPreviewing={onPreviewNote}
              showActions={true}
              onCancelAction={onCancelNoteEditor}
              onSaveAction={onSaveNote}
              placeholder={`${t('enterInternalNotes')}`}
              generatePrompt={null}
              isEditorDisabled={false}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

DetailedItineraryDay.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  onAction: PropTypes.func,
  editingServiceId: PropTypes.number,
  updateDay: PropTypes.func
};

export default DetailedItineraryDay;
