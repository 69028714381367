// @ts-nocheck
import { Box } from '@mui/material';
import { useLoaderData } from 'react-router';
import { useEffect, useState, useRef } from 'react';
import Header from './Header/Header';
import TravelDocSkeleton from './TravelDocSkeleton/TravelDocSkeleton';
import { getTravelDoc } from '../../../../services/trip/trip';
import DaySection from './DaySection/DaySection';
import './TravelDoc.css';
import ServiceSection from './ServiceSection/ServiceSection';
import AdditionalInformation from './AdditionalInformation/AdditionalInformation';
import Amenities from './Amenities/Amenities';

export const TRAVEL_DOC_ID = 'travel-doc';

const TravelDoc = () => {
  const { tripId } = useLoaderData();
  const [tripData, setTripData] = useState(null);
  const [isTripLoading, setIsTripLoading] = useState(true);
  const [tripHasError, setTripHasError] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return; // Prevent multiple fetches

    hasFetched.current = true;

    const fetchTrip = async () => {
      try {
        const { data } = await getTravelDoc(tripId);
        if (data && Object.keys(data).length > 0) {
          setTripData(data);
          setTripHasError(false);
        } else {
          setTripHasError(true);
        }
      } catch (error) {
        setTripHasError(true);
      } finally {
        setIsTripLoading(false);
      }
    };

    fetchTrip();
  }, [tripId]);

  if (isTripLoading) {
    return <TravelDocSkeleton />;
  }

  if (!tripData || tripHasError) {
    return null;
  }

  return (
    <Box className='travel-doc' id={TRAVEL_DOC_ID}>
      <Header
        id={`${TRAVEL_DOC_ID}-header`}
        tenant={tripData.tenant}
        tenantSettings={tripData.tenantSettings}
        trip={tripData.trip}
      />
      {tripData.tripdetaileddays.map((tripDetailedDay) => (
        <>
          <DaySection
            {...tripDetailedDay}
            tenantSettings={tripData.tenantSettings}
            key={tripDetailedDay.tripdayid}
            date={tripData.trip.startdate}
          >
            <div className='services-section'>
              {tripDetailedDay.tripdetaileddayservice.map((service) => (
                <ServiceSection
                  {...service}
                  tripid={tripData.trip.tripid}
                  tenantid={tripData?.tenant?.tenantid}
                  tenantsettings={tripData.tenantSettings}
                  key={tripDetailedDay.tripdetaileddayserviceid}
                />
              ))}
            </div>
          </DaySection>
        </>
      ))}
      <AdditionalInformation
        content={tripData.trip.content}
        tenantsettings={tripData.tenantSettings}
      />
      <Amenities
        tenantsettings={tripData.tenantSettings}
        amenities={tripData.amenities}
      />
    </Box>
  );
};

TravelDoc.propTypes = {};

export default TravelDoc;
