// @ts-nocheck
import Editor from '../../../../Editor/Editor';
import ServiceCardImages from '../ServiceCardImages/ServiceCardImages';
import ServiceCardAttachments from '../ServiceCardAttachments/ServiceCardAttachments';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../../../utils/dateUtility';
import * as PropTypes from 'prop-types';

/**
 * A service card component for train services.
 *
 * @param {Object} servicefields - The fields of the service, including train details.
 * @param {string} tripdetaileddayserviceid - The ID of the trip detailed day service.
 * @returns {JSX.Element} - The JSX element representing a train service card.
 */
const TrainCard = ({ servicefields, tripdetaileddayserviceid }) => {
  const { t } = useTranslation(['services']);

  return (
    <Box id={`service-card-train-${tripdetaileddayserviceid}`}>
      <Box className='service-view-card__content'>
        <Box className='service-view-card__content-row'>
          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('company')}:</span> {servicefields.TRAINCOMPANY || ''}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('trainNumber')}:</span> {servicefields.TRAINNUMBER || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('departureStation')}:</span>{' '}
              {servicefields.DEPARTUREADDRESS}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('arrivalStation')}:</span>{' '}
              {servicefields.ARRIVALADDRESS || ''}
            </p>
          </Box>

          <Box className='service-view-card__content-column'>
            <p className='service-view-card__label-value'>
              <span>{t('departureDateTime')}:</span>{' '}
              {formatDateTime(
                servicefields.DEPARTUREDATE,
                servicefields.DEPARTURETIME
              )}
            </p>
            <p className='service-view-card__label-value'>
              <span>{t('arrivalDateTime')}:</span>{' '}
              {formatDateTime(
                servicefields.ARRIVALDATE,
                servicefields.ARRIVALTIME
              )}
            </p>
          </Box>
        </Box>

        <Box>
          {servicefields?.DESCRIPTION && (
            <Editor
              id={`service-editor-train-${tripdetaileddayserviceid}-description`}
              content={servicefields?.DESCRIPTION}
              editable={false}
              isPreviewing={true}
              className='service-view-card__description'
            />
          )}

          <Box className='service-view-card__confirmation'>
            <p>
              <span>{t('confirmation')}:</span>{' '}
              {servicefields.CONFIRMATIONINFORMATION || ''}
            </p>
          </Box>
        </Box>

        {(servicefields.UPLOADIMAGES || servicefields.UPLOADATTACHMENTS) && (
          <Box className='service-view-card__files-section'>
            {servicefields?.UPLOADIMAGES && (
              <ServiceCardImages images={servicefields?.UPLOADIMAGES} />
            )}
            {servicefields?.UPLOADATTACHMENTS && (
              <ServiceCardAttachments
                attachments={servicefields?.UPLOADATTACHMENTS}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

TrainCard.propTypes = {
  servicefields: PropTypes.object,
  servicetype: PropTypes.object,
  tripdetaileddayserviceid: PropTypes.number
};

export default TrainCard;
